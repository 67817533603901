
    <label *ngIf="!to.label" [for]="elementId" class="wcag-visuallyhidden">
      <ng-container *ngIf="to.placeholder; else EMPTY_LABEL">{{ to.placeholder }}</ng-container>
      <ng-template #EMPTY_LABEL>Input field</ng-template>
    </label>
    <input
      *ngIf="to.type !== 'number' && to.type !== 'formatted-number' && to.type !== 'integer-number'; else numberTmp"
      #inputEl
      [class.is-invalid]="showError"
      [disable-with-validation]="disabledField"
      [disabled]="isFormDisabled"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [id]="elementId"
      [tabindex]="tabIndex"
      [type]="type"
      (change)="onChange($event)"
      class="form-control"
      [attr.aria-labelledby]="elementId"
    />
    <ng-template #numberTmp>
      <ng-container *ngIf="to.type == 'formatted-number'">
        <input
          #inputEl
          [allowNegativeNumbers]="to.allowNegativeNumbers"
          [class.is-invalid]="showError"
          [disable-with-validation]="disabledField"
          [disabled]="isFormDisabled"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [maxlength]="!to.skipLengthValidation ? 19 : 100"
          [id]="elementId"
          [mask]="formattedNumberMask"
          [thousandSeparator]="commaSeparator"
          [tabindex]="tabIndex"
          (change)="onChange($event)"
          class="form-control"
          type="text"
        />
      </ng-container>

      <ng-container *ngIf="to.type == 'integer-number'">
        <input
          #inputEl
          step="1"
          [class.hide-arrows]="hideArrows"
          [class.is-invalid]="showError"
          [disable-with-validation]="disabledField"
          [disabled]="isFormDisabled"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [id]="elementId"
          [tabindex]="tabIndex"
          (change)="onChange($event)"
          (keypress)="forceInteger($event)"
          (paste)="prevent($event)"
          (wheel)="handleWheel($event, to.min, to.max)"
          class="form-control"
          type="number"
        />
      </ng-container>

      <ng-container *ngIf="to.type == 'number'">
        <input
          #inputEl
          [class.hide-arrows]="hideArrows"
          [class.is-invalid]="showError"
          [disable-with-validation]="disabledField"
          [disabled]="isFormDisabled"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [id]="elementId"
          [tabindex]="tabIndex"
          (change)="onChange($event)"
          (wheel)="prevent($event)"
          class="form-control"
          type="number"
        />
      </ng-container>
    </ng-template>
  