import { Location } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { StoreService } from '@core/store/store.service';
import { MAO_NEED_MORE_INFO_CONFIG } from '@module/account-management/account-management.const';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { BaseAccountActionsService } from '@module/account-management/services/base-account-actions.service';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { UserManagementService } from '@module/users-management/services/user-management.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectTransferService } from '@shared/components/project-builder/project-transfer-request/project-transfer.service';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { ITableViewConfig } from '@shared/models/table-view.model';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { NavigationService } from '@shared/services/navigation.service';
import { PreviousLocationService } from '@shared/services/previous-location.service';
import { ProjectService } from '@shared/services/project.service';
import { IProjectApplication, IProjectApplicationContent } from '@shared/services/project.service.types';
import { combineLatest, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

export const ACC_APPROVAL = 'ACCOUNT_APPROVAL';
@Component({
    selector: 'app-account-approval',
    templateUrl: './account-approval.component.html',
    styleUrls: ['./account-approval.component.scss'],
})
export class AccountApprovalComponent implements OnInit, OnDestroy {
    documentUploadConfig: IApp.IDocumentUploadConfig = {
        documentTypeValue: 12,
        documentTypeCode: 'AUTHORIZATION_DOCUMENT',
        documentType: true,
        entityName: 'ACCOUNT',
        comment: true,
        disablePaginator: false,
        guidanceTextEntity:'HIDDEN',
    };

    userDocumentUploadConfig: IApp.IDocumentUploadConfig = {
        documentTypeValue: 2,
        documentTypeCode: 'KYC_DOCUMENT',
        documentType: true,
        entityName: 'USER',
        comment: true,
        disablePaginator: false,
        guidanceTextEntity:'HIDDEN',
    };

    replaceUserDocumentUploadConfig: IApp.IDocumentUploadConfig = {
        documentTypeValue: 12,
        documentTypeCode: 'AUTHORIZATION_DOCUMENT',
        documentType: true,
        entityName: 'USER',
        comment: true,
        disablePaginator: false,
        guidanceTextEntity:'HIDDEN',
    };
    loaded = false;
    id: any;
    replace = false;

    pendingAccountDocuments: IApp.PendingAccountDocuments;
    account: any = {};
    payload: any = {};
    userName: string;
    projectList: IProjectApplicationContent[] = [];
    agreementRequired: boolean;
    agreementSkipAllowed = false;
    agreementComplete = true;
    isOffset: boolean;

    enableSendConsent = false;
    replaceUserContent: any = null;

    public authorizedUserName = '';
    private newAOUserId;

    private _destroy$ = new Subject<any>();
    documentsPage: IApp.IDocumentsPage;
    constructor(
        private store: StoreService,
        private baseAccountActionsService: BaseAccountActionsService,
        private accountManagementService: AccountManagementService,
        private activatedRoute: ActivatedRoute,
        private cfrRegistrationService: CfrRegistrationService,
        private _location: Location,
        private modalService: ModalService,
        private translate: TranslateService,
        private userService: UserManagementService,
        private previousLocationService: PreviousLocationService,
        private router: Router,
        private helperService: HelperService,
        private navigation: NavigationService,
        private projectTransferService: ProjectTransferService,
        private projectService: ProjectService
    ) { }

    ngOnInit() {
        this.isOffset = this.store.user.isOffset();
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.documentUploadConfig.id = params.id;
                if (params.userId) {
                   this.documentUploadConfig.id  = -1;
                   this.userDocumentUploadConfig.id = params.userId;
                   this.replaceUserDocumentUploadConfig.id = params.userId;
                   this.replace = true;
                   this.newAOUserId = params.userId;
                }
            } else {
                this.navigation.back();
            }
        });

        this.pendingAccountDocuments = {};
        this.activatedRoute.params.subscribe(params => {
            this.id = params.id;
            this.load();

        });
        if (!this.previousLocationService.locations[ACC_APPROVAL]) {
          this.previousLocationService.locations[ACC_APPROVAL] = this.previousLocationService.previousLocation;
        }

        this.subscribeProjectUpdates()
    }

    load() {

        const payload = {
            accountId: this.id,
            discriminatorType: 'REGISTRATION_ACCOUNT',
          };

        if (this.isOffset) {
            this.accountManagementService.getAccountDetails(this.id).subscribe(data  => {
                this.processData(data, null, null);
            });
          } else {
            combineLatest([
                this.accountManagementService.getAccountDetails(this.id),
                this.cfrRegistrationService.getRegistrationDetails(payload),
                this.cfrRegistrationService.checkAgreementApproved(payload.accountId),
            ]).subscribe(([data, regResponse, agreementResponse]) => {
                this.processData(data, regResponse, agreementResponse);
            });
        }

    }

    processData(data, regResponse, agreementResponse) {
        this.account = data;
        this.documentUploadConfig.entityDetail = data.legalName;
        this.replaceUserContent = regResponse;

        this.authorizedUserName = data.authorizedUserName;

        if (!this.userDocumentUploadConfig.id) {
            this.userDocumentUploadConfig.id = data.authorizedUserId;
            this.userDocumentUploadConfig.entityDetail = data.authorizedUserName;
        }

        if (this.replace) {
            this.userName = regResponse.authorizedUserName;
            this.authorizedUserName = regResponse.authorizedUserName;
            this.payload = {
                email: regResponse.authorizedUserEmail,
                userId: this.newAOUserId
            };
            this.userService.getAll(null, {freeText: regResponse.authorizedUserEmail}).subscribe(user => {
                if (user.content) {                
                this.userDocumentUploadConfig.id = user.content[0].id;
                this.replaceUserDocumentUploadConfig.id = user.content[0].id;
                }
                this.loaded = true;
            });

        } else {
            if (!this.showApproval()) {
              this.navigation.back();
            }
            this.loaded = true;
        }

        try {
            regResponse.accountSubTypeDtoList.forEach(element => {
              element.activities.forEach(activity => {
                activity.facilities.forEach(facility => {
                  if (facility.agreementRequired) {
                    this.agreementRequired = true;
                    this.agreementComplete = agreementResponse.length > 0;
                  } else {
                    this.agreementSkipAllowed = true;
                  }
                });
              });
            });
          } catch (err) {

          }
    }

    updateDocumentDetails(event: any) {
      this.load();
    }

    returnPreviousLocation(event) {
        event && event.preventDefault();
        if (this.previousLocationService.locations[ACC_APPROVAL]) {
          const path = this.previousLocationService.locations[ACC_APPROVAL];
          this.previousLocationService.locations[ACC_APPROVAL] = null;
          this.router.navigate(this.helperService.getTranslatedPath(path));
        } else {
          this.navigation.back();
        }
    }

    approveReplaceUser() {

        const legalName = this.account.legalName;
        this.modalService
            .open(
                ServiceMessageComponent,
                {
                    messages: null,
                    message: 'confirmationMessageMaoReplace',
                    metaDataList: [legalName],
                    type: SereviceMessageType.WARNING,
                },
                true,
                DEFAULT_DIALOG_CONFIG,
            )
            .afterClosed()
            .subscribe((result?: any) => {
                if (result) {

                    this.cfrRegistrationService.approveReplaceMAO(this.payload).subscribe(response => {
                        this.returnPreviousLocation(null);
                    });
                }
            });
    }

    approve(message: string) {
        if (this.agreementRequired && !this.agreementSkipAllowed && !this.agreementComplete) {
            this.showError('agreementMustBeApproved');
        } else {
        const legalName = this.account.legalName;
        this.modalService
            .open(
                ServiceMessageComponent,
                {
                    messages: null,
                    message: message,
                    metaDataList: [this.translate.instant('COMMON.actionsLabel.approve'), legalName],
                    type: SereviceMessageType.WARNING,
                },
                true,
                DEFAULT_DIALOG_CONFIG,
            )
            .afterClosed()
            .subscribe((result?: any) => {
                if (result) {
                    if (this.account.status === 'PENDING_REVIEW_ADMIN2') {
                        this.baseAccountActionsService.approve2Account(this.account).pipe(
                            takeUntil(this._destroy$),
                        ).subscribe(() => {
                            this.returnPreviousLocation(null);
                        });
                    } else {
                        if (message === 'confirmationMessageAccountKycDocproject') {
                          this.account.doActionName = 'ACCOUNT_REQUEST_SENT_TO_CURRENT_PROPONENT';
                        }
                        this.baseAccountActionsService.approveAccount(this.account).pipe(
                            takeUntil(this._destroy$),
                        ).subscribe(() => {
                            this.returnPreviousLocation(null);
                        });
                    }

                }
            });
        }
    }

    needMoreInfo(){
      const defaultNeedMoreInfoValue = {
        id: this.replaceUserContent.id,
        accountId: this.replaceUserContent.accountId,
        email: this.replaceUserContent && this.replaceUserContent.authorizedUserEmail
      }

      this.modalService.open(TableNewRowComponent, {
        form: MAO_NEED_MORE_INFO_CONFIG,
        defaultValues: defaultNeedMoreInfoValue,
        actionType: TABLE_NEW_ROW_ACTION.EDIT,
        saveBtnLabel: 'COMMON.actionsLabel.SUBMIT',
        editTitle: 'comment',
      } as TableNewRow).afterClosed().subscribe(res=>{
        this.returnPreviousLocation(null);
      })
    }

    reject(){
      const message ='replaceAORejectionConfirmationMessage';
      this.modalService
        .open(
          ServiceMessageComponent,
          {
            message: message,
            type: SereviceMessageType.WARNING
          },
          true,
          DEFAULT_DIALOG_CONFIG
        )
        .afterClosed()
        .subscribe((result?: any) => {
          if (result) {
            const payload = {
              email:  this.replaceUserContent && this.replaceUserContent.authorizedUserEmail,
              accountId: this.replaceUserContent.accountId,
            }
            this.cfrRegistrationService.rejectReplaceMAO(payload).subscribe( response => {
              this.returnPreviousLocation(null);
            })
          }
        });
    }

    fetchProjectData(projects: IProjectApplication) {
        this.projectList = projects.content;
    }

    sendToProponentForConsent() {
      const proponentPayload = {
        userId: this.account.authorizedUserId,
        id: this.projectList[0].id,
        type: this.projectList[0].type,
      };

      this.modalService
        .open(
          ServiceMessageComponent,
          {
            messages: null,
            message: 'confirmationMessageSendProponentToConsent',
            type: SereviceMessageType.WARNING,
          },
          true,
          DEFAULT_DIALOG_CONFIG,
        )
        .afterClosed()
        .subscribe((result?: any) => {
          if (result) {
            this.projectTransferService.sendConsent(proponentPayload).subscribe(response => {
              this.returnPreviousLocation(null);
            });
          }
        });
    }

    public get isVBAccount(): boolean {
      return this.account.accountSubTypeCodes &&
             this.account.accountSubTypeCodes.length === 1 &&
             this.account.accountSubTypeCodes[0] === IApp.NSAccountSubType.StatusEnum.VB;
    }

    public get isCFR(): boolean {
      return this.store.user.isCFR();
    }

    private showError(message) {
        const messages: IDisplayMessages = {
          messages: [{message}],
          type: SereviceMessageType.ERROR,
        };
        this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
          .afterClosed()
          .subscribe();
      }

  private showApproval() {
    return this.account.entityActionList
        .findIndex(t => (t.workflowAction === 'APPROVE' || t.workflowAction === 'APPROVE2')) > -1 ||
      this.account.transitionType === 'ACCOUNT_APPROVED';
  }

  private subscribeProjectUpdates() {
    this.projectService.onProjectUpdate$.pipe(
      takeUntil(this._destroy$),
      filter(result => !!result),
      switchMap(() => this.accountManagementService.getAccountDetails(this.id))
      ).subscribe(result => {
        this.enableSendConsent = result.enableSendConsent;
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
