<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1500px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  (onShow)="scrollToTop()"
  [header] = "headerText | translate">
  <h3 class="m-0 mt-4 pb-2 font-weight-bold "> {{'CREDIT_ADJUSTMENT_REPORT.creditAdjustmentsUnderSection.viewDetailsHeading' | translate}} </h3>

  <form [formGroup]="form">
    <formly-form [form]="form" [model]="model"  [fields]="fields"></formly-form>
</form>


  <div class="table-area table1">
    <app-table-view
      [data]="tableData"
      [config]="tableConfig"
      (paginationChanged)="onPaginationChanged()">
    </app-table-view>
  </div>


  <div class="d-flex justify-content-end mt-4">
    <button type="button" disableOnUpload class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
    <button type="button" disableOnUpload class="btn btn-primary" (click)="save()">
      {{'CREDIT_CREATION_REPORT.reportStep.save' | translate}}
    </button>
  </div>
  </p-dialog>

  <ng-template #CheckboxColTemplate let-data>
    <input
    #inputEl
    [(ngModel)]="data.overrideComplianceCredits"
    class="checkbox-align"
    type="checkbox"
    [disabled]="service.disabled"
    [id]="data.id + 'overrideComplianceCredits'"
    [attr.aria-labelledby]="data.id + 'overrideComplianceCredits'"
  />
</ng-template>

<ng-template #inputColTemplate let-data>
  <input
  *ngIf="data.overrideComplianceCredits"
  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]="'separator.5'"
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.complianceCreditsCreated"
  [disabled]="isDisabled || data.disabled || service.disabled"
  class="form-control"
  type="text"
  [id]="data.id + 'complianceCreditsCreated'"
  [attr.aria-labelledby]="data.id + 'complianceCreditsCreated'"
/>

<div class="invalid-feedback d-block"
     *ngIf="!data.overrideComplianceCredits">
  <span class="table-action-text">N/A</span>
</div>
</ng-template>

<ng-template #allocateVolColTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.complianceCreditQuantity"
  [disabled]="isDisabled || data.disabled || service.disabled"
  [class.is-invalid]="!isDisabled && !data.disabled && data._quantityAvailableRaw < 0"
  class="form-control"
  (change)="onVolumneAllocation(data)"
  type="text"
  [id]="data.id + 'complianceCreditsCreated'"
  [attr.aria-labelledby]="data.id + 'complianceCreditsCreated'"
/>

<div
  class="invalid-feedback d-block"
  *ngIf="data._quantityAvailableRaw < 0">
  <span>{{ 'COMMON.messageSection.compRepQuantityExceeded' | translate }}</span>
</div>

</ng-template>
