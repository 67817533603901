import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { IAccountData, IFacility, IFacilityRequest } from '@module/registration/registration.model';
import { RegistrationService } from '@module/registration/registration.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RESPOND_TOADMIN_COMMENTS } from '@shared/components/stepper/stepper.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { UserService } from '@shared/services/user.service';
import { STATUS_CALCULATE, STATUS_COMPLETED, STATUS_DRAFT, STATUS_ICON , STATUS_WARNING} from '@shared/shared.const';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-org-reg-progress',
  templateUrl: './org-reg-progress.component.html',
  styleUrls: ['./org-reg-progress.component.scss'],
})
export class OrgRegProgressComponent implements OnInit, OnDestroy {

  data: any = {
  };
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  public discussions: IApp.IRegistrationDiscussion[];

  statusIcon = STATUS_ICON;
  entityActionList: any[];
  facilities: IFacility[] = [];
  facilityNames: IApp.ILookup[];
  files: IApp.IDocument[] = [];
  accountData = new IAccountData();
  programAdminEmail: string;
  pendingDocumentMetaData: IApp.IPendingDocumentMetaData;
  statusObject: any;
  loadedData: any;

  isNavbarCollapsed = true;
  menuItems: any[] = [];
  menuIndex = 0;

  actionMap: IApp.IActionMap = {
    PENDING_KYC: {
      message: 'orgRegProgressMsg',
      editable: true,
      status: 'warning',
      rrStatus: STATUS_CALCULATE,
      caStatus: STATUS_CALCULATE,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_REVIEW_ADMIN: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_CALCULATE,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_REVIEW_ADMIN2: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_CALCULATE,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_KYC_NEED_MORE_INFO: {
      message: 'orgRegProgressMsgNMI',
      editable: true,
      status: 'warning',
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_CALCULATE,
      duStatus: STATUS_CALCULATE,
    },
    USER_PENDING_REVIEW_ADMIN: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
    },
    USER_PENDING_REVIEW_ADMIN2: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
    },
    USER_PENDING_KYC: {
      message: 'orgRegProgressMsgPendingKyc',
      editable: true,
      status: STATUS_DRAFT,
    },
    USER_PENDING_KYC_NEED_MORE_INFO: {
      message: 'orgRegProgressMsgNMI',
      editable: true,
      status: 'warning',
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_CALCULATE,
      duStatus: STATUS_CALCULATE,
    },
  };

  enableSubmit: boolean;
  private _destroy$ = new Subject<any>();
  actionDesc: string;
  status: string;
  showComments: boolean;
  subType: string;
  subTypeActivity = '';
  errors = [];
  docAllowedFileTypes: any;
  editable = true;
  pendingFacility: any;
  loaded = false;
  userLoaded = false;
  accountLoaded = false;
  documentUploadConfig: IApp.IDocumentUploadConfig;
  docPresent: boolean[] = [];
  docMissing: boolean;
  UserDocMissing: boolean;
  registrationComplete: boolean;
  registrationId: number;
  userData: IApp.ISaveUser;
  agreementRequired: boolean;
  agreementSkipAllowed: boolean;
  agreementComplete = true;
  missingDocumentList: any;

  public get getSubTypeActivity(): string {
    return this.subType + this.subTypeActivity;
  }

  public get statusIconIcon() {
    return this.statusIcon[this.actionMap[this.status].status].icon;
  }

  public get statusIconStyle() {
    return this.statusIcon[this.actionMap[this.status].status].style;
  }

  constructor(
    public modalService: ModalService,
    public uploadDocumentService: UploadDocumentService,
    private service: CfrRegistrationService,
    public registrationService: RegistrationService,
    public userService: UserService,
    public lookupService: LookupService,
    public storeService: StoreService,
    public translate: TranslateService,
    public router: Router,
    public helperService: HelperService,
    private formFactoryService: FormFactoryService,
    private authService: AuthService,

  ) {
  }

  ngOnInit() {
    this.storeService.gerProgramAdminEmail().subscribe(
      prop => {
        this.programAdminEmail = prop.value;
        this.getFacilityNames();
      },
    );
  }

  processPageData(value) {
    this.loadedData = value;
    this._getDiscussions();   

    if (this.facilities.length >= 1) {
      this.status = this.facilities[this.menuIndex].status;

      if (this.status === 'PENDING_KYC_NEED_MORE_INFO') {
        this.fields = this.formFactoryService.configureForm(RESPOND_TOADMIN_COMMENTS);
        this.showComments = true;
      }
      if (this.facilities[this.menuIndex].accountSubTypes === IApp.NSAccountSubType.StatusEnum.VB) {
        this.subType = IApp.NSAccountSubType.StatusEnum.VB;
      } else if (this.facilities[this.menuIndex].accountSubTypes === IApp.NSAccountSubType.StatusEnum.CIC) {
        this.subType = IApp.NSAccountSubType.StatusEnum.CIC;
      } else {
        this.subType = IApp.NSAccountSubType.StatusEnum.PS;
      }

      try {
        const association = value.associationsList.find(al => al.accountId === this.facilities[this.menuIndex].id);
        this.registrationId = association && association.accountDto.registrationId;
      } catch (err) {
        this.registrationId = null;
      }

      this.pendingFacility = value;
      this.documentUploadConfig = this.service.getDocumentUploadConfig(this.subType, this.facilities[this.menuIndex].id);

      const config1 = this.documentUploadConfig.documentUploadConfigs[0];
      const config2 = this.documentUploadConfig.documentUploadConfigs[1] ? this.documentUploadConfig.documentUploadConfigs[1] :
        { id: -1, entityName: 'ACCOUNT', documentTypeCode: null };
      const payload = {
        id: this.registrationId,
        accountId: this.facilities[this.menuIndex].id,
        discriminatorType: 'REGISTRATION_ACCOUNT',
      };
      forkJoin([
        this.uploadDocumentService.getDocumentsByEntityIdAndType(config1.id, config1.entityName, config1.documentTypeCode),
        this.uploadDocumentService.getDocumentsByEntityIdAndType(config2.id, config2.entityName, config2.documentTypeCode),
        this.service.getRegistrationDetails(payload),
        this.service.checkAgreementSubmitted(payload.accountId),
      ]).subscribe(([docResponse1, docResponse2, regResponse, agreementResponse]) => {
        const identityDocRequired = this.service.checkIfIdentityDocRequired(regResponse.accountSubTypeDtoList);
        const doc1 = docResponse1.totalElements > 0;
        const doc2 = this.documentUploadConfig.documentUploadConfigs[1] ? docResponse2.totalElements > 0 : true;

        if (identityDocRequired) {
          this.docMissing = !(doc1 && doc2);
          this.UserDocMissing = (doc1 || doc2);
        } else {
          this.docMissing = !doc1;
          this.UserDocMissing = false;
        }

        this.files = docResponse1.content;
        this.registrationComplete = regResponse.registrationAccountStatus &&
          regResponse.registrationAccountStatus !== 'IN_PROGRESS' &&
          regResponse.registrationAccountStatus !== 'SAVED';
        if (this.subType === IApp.NSAccountSubType.StatusEnum.VB) {
          try {
            this.subTypeActivity = regResponse.accountSubTypeDtoList[0].activities[0].code;
          } catch (err) {
            this.subTypeActivity = 'VFBVLB';
          }
        }

        try {
          regResponse.accountSubTypeDtoList.forEach(element => {
            element.activities.forEach(activity => {
              activity.facilities.forEach(facility => {
                if (facility.agreementRequired) {
                  this.agreementRequired = true;
                  this.agreementComplete = agreementResponse.length > 0;
                } else {
                  this.agreementSkipAllowed = true;
                }
              });
            });
          });
        } catch (err) {
        }

        this.getActionMessage();
        this.loaded = true;
        this.accountLoaded = true;

      });

    } else if (value.status === 'ACTIVE') {
      this.authService.changeProgram(value.programId).subscribe(result => {
        window.location.href = '/';
      });
    } else {

      this.status = `USER_${value.status}`;      
      let uploadDocumentText = 'uploadDocumentTextUser';
      if (value.associationsList[0].roleId === 1) {
        this.documentUploadConfig =  this.service.getDocumentUploadConfig('MAO_USER', this.service.accountId);
        uploadDocumentText = 'uploadDocumentTextPS';
        this.subType = 'MAOUser';
      } else {
        this.documentUploadConfig = this.service.getDocumentUploadConfig('USER', this.service.accountId);
        this.documentUploadConfig.id = this.storeService.user.id;
        this.subType = 'User';
      }

      if ( this.loadedData && this.loadedData.accountSubTypeCodes) {
        const identityDocRequired = this.loadedData.accountSubTypeCodes.some(code => [
          IApp.NSAccountSubType.StatusEnum.RC,
          IApp.NSAccountSubType.StatusEnum.PS,
          IApp.NSAccountSubType.StatusEnum.VB,
        ].includes(code));
        if (!identityDocRequired) {
         this.documentUploadConfig = this.service.getDocumentUploadConfig('USER', this.service.accountId);
        }
      }

      this.getActionMessage();
      this.userLoaded = true;
      this.pendingDocumentMetaData = {
        sizeProperty: 'max.file.size.allowed.registration.report',
        fileExtension: 'file.extension.allowed.registration.report',
        prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
        title: 'titleUser',
        dataLabel: 'userEmailAddress',
        data: value.email,
        status: (this.status === 'USER_PENDING_KYC_NEED_MORE_INFO') ? null : this.statusObject && this.statusObject.status,
        uploadDocumentText,
        identificationDocuments: (this.status === 'USER_PENDING_KYC_NEED_MORE_INFO') ? null : this.actionDesc,
        documentUploadConfig: this.documentUploadConfig,
        disabled: !this.editable,
      };
      this.userData = {
        id: value.id,
        email: value.email,
      };
      this.nmiScreenForMaoUser();

      this.loaded = true;
    }
  }

  nmiScreenForMaoUser() {
    if(this.status === 'USER_PENDING_KYC_NEED_MORE_INFO'){
      this.showComments = true;
      this.fields = this.formFactoryService.configureForm(RESPOND_TOADMIN_COMMENTS);
    }
  }

  getFacilityNames() {
    this.lookupService.getPendingFacilityNames().pipe(
      takeUntil(this._destroy$),
    ).subscribe(value => {
      this.loadedData = value;
      this.facilityNames = value.userAccountList.map(v => ({
        name: v.legalName,
      }));

      this.facilities = value.userAccountList;
      const pendingAccounts = value.userAccountList.length > 0 ;

      this.menuItems = [];
      this.facilities.forEach( (i, index) => {
        const item = pendingAccounts ? i : i['accountDto'];
        this.menuItems.push({
          name: item.legalName ? item.legalName : item.acc,
          id: index,
          selected: false,
          accountId: item.id,
        });
      });
      if (this.menuItems && this.menuItems.length > 0) {
        this.menuItems[0].selected = true;
      }

      this.menuIndex = 0;
      this.processPageData(value);

      this.translate.onLangChange
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.getActionMessage());
    });
  }

  getActionMessage() {
    this.statusObject = this.actionMap[`${this.status}`];
    if (this.statusObject) {
      this.editable = this.statusObject.editable;
      const message = this.translate.instant(`REGISTRATION_PAGE.cfrPendingRegistration.${this.statusObject.message}${this.subType}`);
      this.actionDesc = message.replaceAll(`{{paEmail}}`, this.programAdminEmail);
      if (this.pendingDocumentMetaData) {
        this.pendingDocumentMetaData.identificationDocuments = this.actionDesc;
      }
      this.setEntityActionList(this.statusObject);
    }
  }

  setEntityActionList(statusObject) {
    const duStatus = this.calculateDuStatus(statusObject);
    const rrStatus = this.calculateRrStatus(statusObject);
    this.entityActionList = [];
    this.entityActionList.push({
      workflowAction: 'CREATE_REGISTRATION_REPORT',
      status: rrStatus,
      message: rrStatus === STATUS_COMPLETED ? 'VIEW_REGISTRATION_REPORT' : 'CREATE_REGISTRATION_REPORT',
      code: 'RR',
    });

    const caStatus = this.calculateCaStatus(statusObject);
    if (this.agreementRequired) {
      this.entityActionList.push({
        workflowAction: 'CREATE_AGREEMENT',
        status: caStatus,
        message: caStatus === STATUS_COMPLETED ? 'VIEW_AGREEMENT' : 'createAgreement',
        code: 'CA',
      });
    }

    this.entityActionList.push({
      workflowAction: this.subType === IApp.NSAccountSubType.StatusEnum.VB ? 'UPLOAD_IDENTITY_DOCUMENTS' :
        'UPLOAD_AUTHORIZATION_AND_IDENTITY_DOCUMENTS',
      status: duStatus,
      message: duStatus === STATUS_COMPLETED ? 'viewuploadDocs' : 'uploadDocs',
      code: 'DU',
    });
  }

  calculateDuStatus(statusObject) {
    if (statusObject.duStatus === STATUS_CALCULATE) {
      return this.docMissing ?
        (this.UserDocMissing ? STATUS_WARNING : STATUS_DRAFT) :
        STATUS_COMPLETED;
    }
    return statusObject.duStatus;
  }

  calculateRrStatus(statusObject) {
    if (statusObject.rrStatus === STATUS_CALCULATE) {
      return this.registrationComplete ? STATUS_COMPLETED : STATUS_DRAFT;
    }
    return statusObject.rrStatus;
  }

  /**
   * create agreement status
   * @param statusObject
   * @returns
   */
  calculateCaStatus(statusObject) {
    if (statusObject.caStatus === STATUS_CALCULATE) {
      return this.agreementComplete ? STATUS_COMPLETED : (this.agreementSkipAllowed ? STATUS_WARNING : STATUS_DRAFT)  ;
    }
    return statusObject.caStatus;
  }

  cfrRegistration(code) {
    if (code === 'CA') {
      this.router.navigate(this.helperService.getTranslatedPath(`/agreement-credits/list/${this.facilities[this.menuIndex].id}`));
    } else {
      this.storeService.setHistoryUrl();
      let uri = `/cfr-registration/cfr/${code}${this.subType}/${this.facilities[this.menuIndex].id}`;
      if (this.registrationId) {
        uri = `${uri}/${this.registrationId}`;
      }
      this.router.navigate(this.helperService.getTranslatedPath(uri));
    }
  }

  documentUpload(event) {
    this.router.navigate(this.helperService.getTranslatedPath('/cfr-registration/cfr/DU'));
  }

  submitRegistrationReport() {
    this.formFactoryService.markFormGroupDirty(this.form);

    if (!this.showComments || this.form.valid) {

      const facilityIds = this.facilities.map(el => el.id);
      const facilityRequest: IFacilityRequest = {
        docIds: this.files,
        accountIds: facilityIds,
        userComment: this.data.userComment,
      };
      this.registrationService.submitKyc(facilityRequest).subscribe(
        result => {
          this.editable = false;
          this.showComments = false;
          this.getFacilityNames();
          this._getDiscussions();
        },
        (error: HttpErrorResponse) => {
          this.errors = this.registrationService.handleErrors(error.error);
        },
      );
    }
  }

  submitKycFiles() {
    if(this.status === 'USER_PENDING_KYC_NEED_MORE_INFO'){
      this.formFactoryService.markFormGroupDirty(this.form);
      if(this.showComments && !this.form.valid){
        return;
      }
      this.userData['comment'] = this.form.value['userComment']
    }
    this.userData.aoReplacement = this.userLoaded;
    if ( this.missingDocumentList.length) {
      this.checkForRequiredDocs(this.missingDocumentList);
    } else {
      this.userService.doAction('/doKycSubmit', this.userData).subscribe(
        result => {
          this.editable = false;
          this.showComments = false;
          this.discussions = null;
          this.getFacilityNames();
        },
        (error: HttpErrorResponse) => {
          this.errors = this.registrationService.handleErrors(error.error);
        },
      );
    }
  }

  updateDocumentDetails(docs) {
    const requiredDocs = this.documentUploadConfig.documentUploadConfigs.map(t => t.documentTypeCode);
    const uploadedDocs = docs.map(t => t.documentType.code);
    this.missingDocumentList = requiredDocs.filter(doc => !uploadedDocs.includes(doc));
  }

  private checkForRequiredDocs(missingDocs: any) {
    let charCode = 97;
    const missingDocMsg = missingDocs.reduce((acc, doc, index) => {
      ++charCode;
      return (index < missingDocs.length - 1) ?
        acc + `${this.translate.instant(`COMMON.documentList.${doc}`)}<br>${String.fromCharCode(charCode)}. ` :
        acc + `${this.translate.instant(`COMMON.documentList.${doc}`)}`;
    }, '');
    const metaData = [`${String.fromCharCode(97)}. ${missingDocMsg}`];
    this.modalService.open(ServiceMessageComponent,
      {
        messages: [{
          message: 'mandatoryInformationRequiredForUploadDocs',
          metaData,
        }],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    );
  }

  updatePageWith(id) {
    this.menuIndex = id;
    this.processPageData(this.loadedData);
  }

  setTabActive(id) {
    this.menuItems.forEach(item => item.selected = item.id === id);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getDiscussions() {
    if (this.facilities && this.facilities[this.menuIndex]) {
      this.service.getPreviousDiscussions(this.facilities[this.menuIndex].id)
        .subscribe(data => this.discussions = data);
    } else if(`USER_${this.loadedData.status}` === 'USER_PENDING_KYC_NEED_MORE_INFO'){
      this._getMaoDiscussions();    
    }
  }

  private _getMaoDiscussions(){
    if(this.loadedData.accountId && this.loadedData.id){
      this.service.getMaoPreviousDiscussions(this.loadedData.accountId, this.loadedData.id).subscribe(data => this.discussions = data);
    }
  }
}
