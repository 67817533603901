import { IRawForm, IRawFormBase } from '@core/models/raw-form.types';
import { ACCOUNT_SUMMARY_PUBLIC_FIELD } from '@module/account-management/account-management.const';

export const KYC_DOWNLOAD_TEMPLATE_URI = '/account-service/document/find/KYC_TEMPLATE';
export const obPsRegistrationPrefix = 'OBPS_REGISTRATION';
export const COMPONENT_ID = 'REGISTRATION';

export enum REGISTRATION_TYPE {
    INITIAL = 'initial',
    NFMP = 'nfmp',
    OBPS = 'obps',
    CFS = 'cfs',
    PUBLIC_BODY_GOVERNMENT = 'publicBodyGovernment',
    OFFSET = 'offset',
    CM = 'cm',
}

export enum SOURCE_DATA_TYPE {
    LOCAL,
    BUSSINES_STRUCTURE,
    COUNTRY,
    PROVINCE,
    ALL_PROVINCE,
    AREA_OF_EXPERTISE,
    AREA_OF_EXPERTISE_BY_ID,
    PROFESSIONAL_QUALIFICATIONS,
    ACCREDITATION_BODY,
    ORGANIZATION,
    ORGANIZATION_WITHOUT_AGREEMENT,
    ADDRESS,
    FUELS,
    SUB_ORGANIZATION,
    APPLICATION_SUBMISSION_TYPE,
    ACCOUNT_NAMES,
    DELIVERY_MEDIUM,
    QUANTIFICATION_METHOD,
    FEED_STOCK,
    STAGE_OF_PRODUCTION,
    THERMAL_ELECTRICAL_COMPRESSION,
    STATIC_DATA,
    CIMETHOD,
    REGULATORY_REGIME,
    VERIFICATION_BODY,
    APPLICATION_SUBMISSION_ACTIVITY_TYPE,
    COMPLIANCE_PERIOD,
    CFR_COMPLAINCE_PERIOD,
    ELECTRIC_ENERGEY_SOURCE,
    ACTIVITY_SUBJECT_TO_APPLICATION,
    PREVIOUS_OPA,
    PREVIOUS_OPA_ACCOUNT,
    LOOKUP_TYPE_OF_ENERGY,
    OUTSIDE_CA_PROVINCE,
    PROVINCIAL_GRID,
    ACCOUNT_TYPE,
    LANGUAGE_OF_CORRESPONDANCE,
    GREEN_HOUSE_GAS,
    OFFSET_PROTOCOLS,
    PROVINCE_BY_COUNTRY,
    PROJECT_TYPE,
    SUB_ACCOUNT_REASON,
    MASTER_ACCOUNT,
    SEARCH_PROJECT_BY_ACCOUNT,
    PROJECT_CANCELLATION_REASON,
    SUBMISSION_TYPES_LIST,
    SUBMISSION_REPORT_TYPE_LIST,
    ACTIVE_ACCOUNT_NAMES,
    ACTIVITY_COUNTRY,
    ERP_PROJECT_TYPE,
    ERP_QUANTIFICATION_METHODS,
    ERP_APPROVAL_TEST_METHOD,
    QM_STATUS_LIST,
    QM_FUEL_TYPES,
    CCR_TYPE,
    CCR_ACTIVITY_TYPE,
    CCR_COMPLIANCE_YEARS,
    CCR_QUARTERS,
    CARBON_INTENSITY_IDS,
    AGREEMENT_TRANSFER_CREDIT_FUELS,
    CI_APPLICATION_TYPE,
    SECTION_FOR_CREDIT_CREATION,
    ENERGY_EFFICIENCY_FOR_ANNUAL_STEP,
    NOE_NATURE_OF_ERRORS,
    NOE_TYPE_OF_ACCOUNTS_FOR_NOTICE,
    AREA_OF_EXPERTISE_VB,
    COMPLIANCE_REPORT_COMPLIANCE_YEARS,
    CAR_COMPLIANCE_YEARS,
    CREDIT_BALANCE_REPORT_COMPLIANCE_YEARS,
    CREDIT_REVENUE_REPORT_COMPLIANCE_YEARS,
    CCM_COMPLAINCE_PERIOD,
    COMPLIMENTARY_COMPLIANCE_REPORT_COMPLIANCE_YEARS
}

export const ACCOUNT_TYPE_IDS = {
  CM: 3,
  OFFSET: 6,
};

export const BUSINESS_STRUCTURE_IDS_BY_PROGRAM = {
  CM: 3,
  OFFSET: 13,
}

export enum REGISTRATION_FIELDS {
    ALL = 'ALL',
    legalName = 'legalName',

    individualName= 'individualName',

    organizationBusEmail = 'orgBusinessEmail',
    organizationBusPhone = 'orgBusinessPhone',

    facilityId= 'facilityId',
    businessStructure = 'businessStructure.id',
    registrationNumber = 'registrationNumber',
    certificateNumber = 'certificateNumber',
    jurisdiction = 'jurisdiction',
    registrationType = 'registrationType',
    naics = 'naics',
    address = 'address',
    streetAddress = 'streetAddress',
    municipality = 'municipality',
    province = 'province',
    country = 'country',
    postalCode = 'postalCode',
    latitude = 'latitude',
    longitude = 'longitude',
    name = 'name',
    title = 'title',
    telephone = 'telephone',
    email = 'email',
    businessTelephone = 'businessTelephone',
    organizationId = 'organizationId',
    responsibleName = 'name',
    businessNumber = 'businessNumber',
    persons = 'persons',
    sapCustomerNumber = 'sapCustomerNumber',
    sameAsOrganizationAddress = 'sameAsOrganizationAddress',
    faxNumber= 'faxNumber',
    publicView = 'displayAccountInfoPublicView',

    facilityName= 'facilityName',
    agreementRequired= 'agreementRequired',
    englishTradeName= 'englishTradeName',
    frenchTradeName= 'frenchTradeName',
}

export enum ADDRESS_FIELDS {
    sameAsOrganizationAddress = 'sameAsOrganizationAddress',
    sameAsPostalAddress= 'sameAsPostalAddress',
    streetNumber = 'streetNumber',
    streetName = 'streetName',
    city= 'city',
    province= 'province',
    poBox= 'poBox',
    postalCode= 'postalCode',
    country= 'country',
    latitude= 'latitude',
    longitude= 'longitude',

}

export enum REGISTRATION_FIELDS_LABELS {
    organizationId = 'REGISTRATION_PAGE.cfsRegistrationForm.organizationID',
    title = 'REGISTRATION_PAGE.cfsRegistrationForm.title',
    name = 'REGISTRATION_PAGE.cfsRegistrationForm.name',
    telNum= 'REGISTRATION_PAGE.cfsRegistrationForm.telNum',
    faxNumber= 'REGISTRATION_PAGE.cfsRegistrationForm.faxNumber',
    email= 'REGISTRATION_PAGE.cfsRegistrationForm.email',
    responsibleName= 'REGISTRATION_PAGE.cfsRegistrationForm.responsibleName',
    legalEntityNameLabel = 'REGISTRATION_PAGE.cfsRegistrationForm.legalEntityName',
    frenchTradeName= 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
    englishTradeName= 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
}

export const INITIAL_REGISTRATION_ENTITY_SECTION = {

    groupFields: false,
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.legalEntityNameLabel',
            disabled: false,
            required: true,
            id: 'legalname',
        },

        {
            type: 'dict-select',
            key: 'businessStructure.id',
            source: SOURCE_DATA_TYPE.BUSSINES_STRUCTURE,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.businessStructure',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.businessStructureList',
            id: 'businessStructure',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.organizationBusPhone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationBusPhone',
            disabled: false,
            required: true,
            id: REGISTRATION_FIELDS.organizationBusPhone,
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.organizationBusEmail,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationBusEmail',
            disabled: false,
            required: true,
            id: REGISTRATION_FIELDS.organizationBusEmail,
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationNumberLabel',
            disabled: false,
            required: true,
            id: 'registrationNumber',
        },
    ],
};

export const INITIAL_REGISTRATION_ADDRESS_SECTION = {

    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'organizationAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,
            id: 'streetAddress',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,
            id: 'municipality',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.province,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            filter: { field: 'country', prop: 'code' },
            id: 'province',
        },

        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabled: false,
            required: true,
            defaultValue: 'CA',
            translatePrefix: 'COMMON.countryList',
            sort: false,
            id: 'country',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
            id: 'postalCode',
        },
    ],
};

export const INITIAL_REGISTRATION_AUTHORIZED_OFFICIAL_SECTION = {

    groupFields: true,
    key: 'authorizedOfficial',
    label: 'authorizedAccRep',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.name,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.name',
            disabled: false,
            required: true,
            id: 'name',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.title,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.title',
            disabled: false,
            required: true,
            id: 'title',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.email,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.businessEmail',
            disabled: false,
            required: true,
            id: 'businessEmail',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.businessTelephone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.businessTelephoneExt',
            disabled: false,
            required: true,
            id: 'businessTelephoneExt',
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.telephone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.mobilePhone',
            disabled: false,
            id: 'telephone',
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },
    ],
};

export const INITIAL_REGISTRATION_ENTITY_SECTION_INDIVIDUAL = {

    groupFields: false,
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.nameOfIndividual',
            disabled: false,
            required: true,
            id: 'legalName',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationNumberLabel',
            disabled: false,
            required: true,
            id: 'registrationNumber',
        },

    ],
};

export const INITIAL_REGISTRATION_ADDRESS_SECTION_INDIVIDUAL = {

    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'individualAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,
            id: 'streetAddress',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,
            id: 'municipality',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.province,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            filter: { field: 'country', prop: 'code' },
            id: 'province',
        },

        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabled: false,
            required: true,
            defaultValue: 'CA',
            translatePrefix: 'COMMON.countryList',
            sort: false,
            id: 'country',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
            id: 'postalCode',
        },
    ],
};

export const INITIAL_REGISTRATION_AUTHORIZED_OFFICIAL_SECTION_INDIVIDUAL = {

    groupFields: true,
    key: 'authorizedOfficial',
    label: 'individualContact',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.email,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.email',
            disabled: false,
            required: true,
            id: 'businessEmail',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.businessTelephone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.telephoneExt',
            disabled: false,
            required: true,
            id: 'businessTelephoneExt',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.telephone,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.mobilePhone',
            disabled: false,
            id: 'telephone',
            placeholder: 'COMMON.messageSection.phonePlaceholder',
        },
    ],
};

export const NFMP_REGISTRATION_ENTITY_SECTION_ORG = {

    groupFields: false,
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.legalEntityNameLabel',
            disabled: false,
            required: true,
            id: 'legalName2',
        },
        {
            type: 'dict-select',
            key: 'businessStructure.id',
            source: SOURCE_DATA_TYPE.BUSSINES_STRUCTURE,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.businessStructure',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.businessStructureList',
            id: 'businessStructure2',
        },
      {
        type: 'input',
        key: REGISTRATION_FIELDS.organizationBusPhone,
        label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationBusPhone',
        disabled: false,
        required: true,
        id: 'businessTelephoneExt',
        placeholder: 'COMMON.messageSection.phonePlaceholder',
      },
      {
        type: 'input',
        key: REGISTRATION_FIELDS.organizationBusEmail,
        label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationBusEmail',
        disabled: false,
        required: true,
        id: 'businessEmail',
      },
      {
        type: 'input',
        key: REGISTRATION_FIELDS.registrationNumber,
        label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationNumberLabel',
        disabled: false,
        required: true,
        id: 'registrationNumber2',
      },
    ],
};

export const NFMP_REGISTRATION_ADDRESS_SECTION_ORG = {

    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'facilityAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,

        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,

        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.province,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.jurisdictionsList.',

        },

        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabledExpression: 'model.programType && model.programType !== "nfmp"',
            required: true,
            sort: false,
            translatePrefix: 'COMMON.countryList',

        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
        },
    ],
};

export const NFMP_REGISTRATION_ENTITY_SECTION_IND = {

    groupFields: false,
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.nameOfIndividual',
            disabled: false,
            required: true,
            id: 'legalName2',
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationNumberLabel',
            disabled: false,
            required: true,
            id: 'registrationNumber2',
        },
    ],
};

export const NFMP_REGISTRATION_ADDRESS_SECTION_IND = {

    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'individualAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [

        {
            type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,

        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,

        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.province,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.jurisdictionsList.',

        },

        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabled: true,
            required: true,
            sort: false,
            translatePrefix: 'COMMON.countryList',

        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
        },
    ],
};

/*OBPS REGISTRATION FORM*/
export const OBPS_REGISTRATION_ENTITY_SECTION = {

    groupFields: false,
    fieldGroup: [
        {
            type: 'input',
            key: REGISTRATION_FIELDS.legalName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.facilityName',
            disabled: false,
            required: true,
            id: COMPONENT_ID + '_legalName',
        },
        {
            type: 'input',
            key: REGISTRATION_FIELDS.facilityId,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.facilityId',
            disabled: false,
            required: true,
            id: obPsRegistrationPrefix.concat('facilityId'),
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.certificateNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.certificateNumber',
            disabled: false,
            required: true,
            id: obPsRegistrationPrefix.concat('certificateNumber'),
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.jurisdiction,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.jurisdiction',
            disabled: false,
            required: true,
            id: obPsRegistrationPrefix.concat('jurisdiction'),
       },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.registrationNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.registrationType',
            disabled: false,
            required: true,
            id: obPsRegistrationPrefix.concat('registrationType'),
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.naics,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.naics',
            disabled: false,
            required: true,
            id: obPsRegistrationPrefix.concat('naics'),
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.sapCustomerNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.sapCustomerNumber',
            disabled: false,
            required: true,
            id: obPsRegistrationPrefix.concat('sapCustomerNumber'),
        },
        ACCOUNT_SUMMARY_PUBLIC_FIELD,
    ],
};

export const OBPS_REGISTRATION_ADDRESS_SECTION = {

    groupFields: true,
    key: REGISTRATION_FIELDS.address,
    label: 'facilityAddress',
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [

        {
        type: 'input',
            key: REGISTRATION_FIELDS.streetAddress,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.streetAddress',
            disabled: false,
            required: true,
            id: obPsRegistrationPrefix.concat('streetAddress'),
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.municipality,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.municipality',
            disabled: false,
            required: true,
            id: obPsRegistrationPrefix.concat('municipality'),
        },

        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.province,
            source: SOURCE_DATA_TYPE.PROVINCE,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.province',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.jurisdictionsList',
            filter: { field: 'country', prop: 'code' },
            id: obPsRegistrationPrefix.concat('province'),
        },

        {
            type: 'dict-select',
            key: REGISTRATION_FIELDS.country,
            source: SOURCE_DATA_TYPE.COUNTRY,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.country',
            disabled: true,
            required: true,
            sort: false,
            translatePrefix: 'COMMON.countryList',
            id: obPsRegistrationPrefix.concat('country'),
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.postalCode,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
            disabled: false,
            requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
            id: obPsRegistrationPrefix.concat('postalCode'),
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.latitude,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.latitude',
            max: 90,
            min: -90,
            disabled: false,
            required: false,
            id: obPsRegistrationPrefix.concat('latitude'),
        },

        {
            type: 'input',
            key: REGISTRATION_FIELDS.longitude,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.longitude',
            disabled: false,
            max: 180,
            min: -180,
            required: false,
            id: obPsRegistrationPrefix.concat('longitude'),
        },
    ],
};

export const OBPS_REGISTRATION_RESPONSABLE_PERSON_SECTION = {

    groupFields: true,
    nestedPropertyKey: true,
    label: 'responsiblePerson',
    key: REGISTRATION_FIELDS.persons,
    translatePrefix: 'REGISTRATION_PAGE.nfmpAccountForm',
    fieldGroup: [

        {
            type: 'input',
            parentKey: REGISTRATION_FIELDS.persons,
            key: REGISTRATION_FIELDS.organizationId,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.organizationID',
            disabled: false,
            nestedKey: null,
            required: true,
        },

        {
            type: 'input',
            parentKey: REGISTRATION_FIELDS.persons,
            key: REGISTRATION_FIELDS.responsibleName,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.name',
            disabled: false,
            nestedKey: null,
            required: true,
        },

        {
            type: 'input',
            parentKey: REGISTRATION_FIELDS.persons,
            key: REGISTRATION_FIELDS.businessNumber,
            label: 'REGISTRATION_PAGE.nfmpAccountForm.craBusinessNumber',
            disabled: false,
            nestedKey: null,
            required: true,
        },
    ],
};

export const PROVINCE: IRawForm = {
        type: 'dict-select',
        key: ADDRESS_FIELDS.province,
        source: SOURCE_DATA_TYPE.ALL_PROVINCE,
        translatePrefix: 'COMMON.jurisdictionsList',
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.provState',
        disabled: false,
        required: true,
        filter: { field: 'country', prop: 'code' },
        id: `province`,
};

export const DELIVERY_MEDIUM = {
    type: 'dict-select',
    key: 'deliveryMedium',
    source: SOURCE_DATA_TYPE.DELIVERY_MEDIUM,
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.receiveVerificationCode',
    disabled: false,
    required: true,
    translatePrefix: 'COMMON.deliveryType',
    id: 'deliveryMedium',
};

export const MOBILE: IRawForm = {
    type: 'input',
    key: 'telephone',
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.mobile',
    disabled: false,
    required: true,
    id: 'phoneNumber',
    placeholder: 'COMMON.messageSection.phonePlaceholder',
};

export const BUSINESS_TELEPHONE: IRawForm = {
    type: 'input',
    key: 'businessTelephone',
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.telNum',
    disabled: false,
    required: true,
    id: 'businessTelephone',
    placeholder: 'COMMON.messageSection.phonePlaceholder',
};

export const getOrganizationEmail = (options = {}) => {
  const field: IRawForm = {
    type: 'input',
    key: REGISTRATION_FIELDS.organizationBusEmail,
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.organizationEmail',
    disabled: false,
    required: true,
    id: REGISTRATION_FIELDS.organizationBusEmail,
  };

  return { ...field, ...options };
};

export const getOrganizationPhone = (options = {}) => {
  const field: IRawForm = {
    type: 'input',
    key: REGISTRATION_FIELDS.organizationBusPhone,
    label: 'REGISTRATION_PAGE.cfsRegistrationForm.organizationPhone',
    disabled: false,
    required: true,
    id: REGISTRATION_FIELDS.organizationBusPhone,
    placeholder: 'COMMON.messageSection.phonePlaceholder',
  };

  return { ...field, ...options };
};

export const CFS_REGISTRATION_FORM: IRawFormBase[] = [
    {

        groupFields: true,
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.titleInfoSectionm',
        fieldGroup: [

            {
                type: 'input',
                key: 'legalName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.legalEntityName',
                disabled: false,
                required: true,
                id: 'legalname',
            },

            {
                type: 'input',
                key: 'registrationNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.busRegNum',
                disabled: false,
                required: false,
                id: 'registrationNumber',
            },

            {
                type: 'input',
                key: 'englishTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
                disabled: false,
                required: false,
                id: 'englishTradeName',
            },

            {
                type: 'input',
                key: 'frenchTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
                disabled: false,
                required: false,
                id: 'frenchTradeName',
            },

            getOrganizationEmail({ validators: ['email']}),
            getOrganizationPhone(),

        ],

    },

    {
        groupFields: true,
        key: 'postalAddress',
        label: 'newOrgPostalAddress',
        changeExpr: 'this.cloneField($event, field, \'authorizedOfficial.postalAddress\', \'authorizedOfficial.postalAddress.sameAsOrganizationAddress\')',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'postalAddressCountry',
                sort: false,
            },
            {
                type: 'dummy',
            },
            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'postalAddressStreetNumber',
            },
            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'postalAddressStreetName',
            },

            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                required: true,
                disabled: false,
                id: 'postalAddressCity',
            },

           PROVINCE,

            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'postalAddressPoBox',
            },

            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'postalAddressPostalCode',
            },
        ],
    },

    {

        groupFields: true,
        key: 'authorizedOfficial',
        label: 'newApllicantInfo',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [

            {
                type: 'input',
                key: 'title',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.title',
                disabled: false,
                required: true,
                id: 'title',
            },
            {
                type: 'input',
                key: 'name',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.name',
                disabled: false,
                required: true,
                id: 'name',
            },
            MOBILE,
           // DELIVERY_MEDIUM,
            BUSINESS_TELEPHONE,

            {
                type: 'input',
                key: 'email',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.email',
                disabled: false,
                required: true,
                id: 'email',
                validators: ['email'],
            },
        ],
    },

    {

        groupFields: true,
        key: 'authorizedOfficial.postalAddress',
        label: 'applicantPostalAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [

            {
                type: 'checkbox',
                key: REGISTRATION_FIELDS.sameAsOrganizationAddress,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.samePostalOrgAddress',
                disabled: false,
                required: false,
                changeExpr: 'this.disableForm($event, field, null, \'postalAddress\'); this.cloneFormGroup($event, field, \'postalAddress\', \'authorizedOfficial.postalAddress\')',
                className: 'col-12',
                id: 'sameAsOrganizationAddress',
            },
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
            {
                type: 'dummy',
            },

            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'AOstreetNumber',
            },

            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'AOstreetName',
            },

            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'AOcity',
            },

            PROVINCE,
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'AOpoBox',
            },

            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'AOpostalCode',
            },
        ],
    },
];

export const VB_ORG_INFO: IRawFormBase[] = [
    {

        groupFields: false,
        fieldGroup: [

            {
                type: 'input',
                key: 'legalName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.legalEntityName',
                disabled: false,
                required: true,
                id: 'legalname',
                disabledExpression: "model.editMode || model.existingOVB"
            },

            {
                type: 'input',
                key: 'registrationNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.busRegNum',
                disabled: false,
                required: false,
                id: 'registrationNumber',
                minLength: 9
            },

            {
                type: 'input',
                key: 'englishTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
                disabled: false,
                required: false,
                id: 'englishTradeName',
            },

            {
                type: 'input',
                key: 'frenchTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
                disabled: false,
                required: false,
                id: 'frenchTradeName',
            },

            getOrganizationEmail({ validators: ['email']}),
            getOrganizationPhone(),

        ],

    },

    {

        groupFields: true,
        key: 'postalAddress',
        label: 'postalAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        changeExpr: 'this.cloneField($event, field, \'authorizedOfficial.postalAddress\', \'authorizedOfficial.postalAddress.sameAsOrganizationAddress\')',
        fieldGroup: [
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
            {
                type: 'dummy',
            },
            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'POstreetNumber',
            },

            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'POstreetName',
            },

            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'POcity',
            },

           PROVINCE,

            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'POpoBox',
            },

            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'POpostalCode',
            },
        ],
    },

    {

        groupFields: true,
        key: 'authorizedOfficial',
        label: 'apllicantInfo',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [

            {
                type: 'input',
                key: 'title',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.title',
                disabled: false,
                required: true,
                id: 'title',
            },

            {
                type: 'input',
                key: 'name',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.name',
                disabled: false,
                required: true,
                id: 'name',
            },
            MOBILE,
           // DELIVERY_MEDIUM,
            BUSINESS_TELEPHONE,

            {
                type: 'input',
                key: 'email',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.email',
                disabled: false,
                required: true,
                id: 'email',
                validators: ['email'],
            },
        ],
    },

    /**{
        groupFields: true,
        label: 'professionalQualifications',
        translatePrefix:'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup:[

            {
                key: 'authorizedOfficial.professionalQualificationIds',
                type: 'multi-checkbox',
                source: SOURCE_DATA_TYPE.PROFESSIONAL_QUALIFICATIONS,
                disabled: false,
                required:true,
                className: 'col-12',
                id:'PROFESSIONAL_QUALIFICATIONS'
            },
        ]
    },**/

    {

        groupFields: true,
        key: 'authorizedOfficial.postalAddress',
        label: 'applicantPostalAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [

            {
                type: 'checkbox',
                key: REGISTRATION_FIELDS.sameAsOrganizationAddress,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.samePostalOrgAddress',
                disabled: false,
                required: false,
                className: 'col-12',
                changeExpr: 'this.disableForm($event, field); this.cloneFormGroup($event, field, \'postalAddress\', \'authorizedOfficial.postalAddress\')',
                id: 'sameAsOrganizationAddress',
            },
              {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
             },
             {
                type: 'dummy',
             },
             {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'AOstreetNum',
            },

            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'AOstreetName',
            },

            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'AOcity',
            },

            PROVINCE,
            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'AOpoBox',
            },

            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'AOpostalCode',
            },
        ],
    },
];

export const VB_REG_REP_SUBM: IRawFormBase[] = [
    {

        groupFields: false,
        fieldGroup: [

            {
                type: 'input',
                key: 'legalname',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.legalEntityName',
                disabled: false,
                required: true,
                id: 'legalname',
            },

            {
                type: 'input',
                key: 'busRegNum',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.busRegNum',
                disabled: false,
                required: true,
                id: 'busRegNum',
            },

            {
                type: 'input',
                key: 'engTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
                disabled: false,
                required: true,
                id: 'engTradeName',
            },

            {
                type: 'input',
                key: 'frTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
                disabled: false,
                required: true,
                id: 'frTradeName',
            },

        ],

    },

    {

        groupFields: true,
        key: 'postalAddress',
        label: 'postalAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [

            {
                type: 'input',
                key: 'streetNum',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'streetNum',
            },

            {
                type: 'input',
                key: 'streetNum',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'streetNum',
            },

            {
                type: 'input',
                key: 'cityTown',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'cityTown',
            },

            PROVINCE,

            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'poBox',
            },

            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'postalCode',
            },

            {
                type: 'input',
                key: 'county',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: false,
                required: true,
                id: 'county',
            },
        ],
    },

    {

        groupFields: true,
        key: 'postalAddress',
        label: 'civicAddress',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [

            {
                type: 'checkbox',
                key: 'samePostalOrgAddress',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.samePostalOrgAddress',
                disabled: false,
                required: true,
                className: 'col-12',
                id: 'samePostalOrgAddress',
            },

            {
                type: 'input',
                key: 'streetNum',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'streetNum',
            },

            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'streetName',
            },

            {
                type: 'input',
                key: 'cityTown',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                disabled: false,
                required: true,
                id: 'cityTown',
            },

            {
                type: 'dict-select',
                source: SOURCE_DATA_TYPE.COUNTRY,
                key: 'provState',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.provState',
                disabled: false,
                required: true,
                id: 'provState',
                sort: false,
            },

            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'poBox',
            },

            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'postalCode',
            },
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.country',
                disabled: false,
                required: true,
                defaultValue: 'CA',
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
        ],
    },

];

export const CFS_REGISTRATION_ACCOUNT_SUMMARY: IRawFormBase[] = [
    {

        groupFields: false,
        fieldGroup: [

            {
                type: 'input',
                key: 'englishTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.engTradeName',
                disabled: false,
                required: false,
                id: 'englishTradeName',
            },

            {
                type: 'input',
                key: 'frenchTradeName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.frTradeName',
                disabled: false,
                required: false,
                id: 'frenchTradeName',
            },

            {
                type: 'input',
                key: 'registrationNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.busRegNum',
                disabled: false,
                required: false,
                id: 'registrationNumber',
            },

            getOrganizationEmail({ validators: [] }),
            getOrganizationPhone({ validators: [], placeholder: null }),

        ],

    },

    {
        groupFields: true,
        key: 'postalAddress',
        label: 'orgPostalAddress',
        changeExpr: 'this.cloneField($event, field, \'authorizedOfficial.postalAddress\', \'authorizedOfficial.postalAddress.sameAsOrganizationAddress\')',
        translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
        fieldGroup: [

            {
                type: 'input',
                key: 'streetNumber',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                disabled: false,
                required: true,
                id: 'streetNumber',
            },

            {
                type: 'input',
                key: 'streetName',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                disabled: false,
                required: true,
                id: 'streetName',
            },

            {
                type: 'input',
                key: 'city',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                required: true,
                disabled: false,
                id: 'city',
            },

            PROVINCE,

            {
                type: 'input',
                key: 'poBox',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                disabled: false,
                required: false,
                id: 'poBox',
            },

            {
                type: 'input',
                key: 'postalCode',
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                disabled: false,
                requiredExpression: 'model && [\'CA\', \'US\'].includes(model.country)',
                id: 'postalCode',
            },
            {
                type: 'dict-select',
                key: REGISTRATION_FIELDS.country,
                source: SOURCE_DATA_TYPE.COUNTRY,
                label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                disabled: true,
                required: true,
                translatePrefix: 'COMMON.countryList',
                id: 'country',
                sort: false,
            },
        ],
    },
];

export const POSTAL_ADDRESS_FORM_SECTION = {
    prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
    key: 'postalAddress',
    changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
    sameAsOrganization: {
        hide: false,
        changeExpr: null,
    },
    hidePoBox: false,
    sameAsPostal: {
        hide: true,
    },
    hideLatLong: true,
};

export const CIVIC_ADDRESS_FORM_SECTION = {
    prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
    key: 'civicAddress',
    sameAsOrganization: {
        hide: true,
    },
    hidePoBox: true,
    sameAsPostal: {
        hide: false,
        changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
    },
    hideLatLong: true,
};

export const REQUEST_REPLACING_MAO: IRawFormBase[] = [
    {
        groupFields: false,
        fieldGroup: [
            {
                type: 'readonly-input',
                className: 'col-12',
                key: REGISTRATION_FIELDS.legalName,
                label: 'ACCOUNTS_MODULE.accountSummary.organizationName',
                disabled: true,
                required: false,
                id: 'organizationName',
            },
            {
                type: 'input',
                className: 'col-12',
                key: 'email',
                label: 'ACCOUNTS_MODULE.accountSummary.maoEmail',
                disabled: false,
                required: true,
                id: 'maoEmail',
            },
            {
                type: 'textarea',
                className: 'col-12',
                key: 'reason',
                label: 'ACCOUNTS_MODULE.accountSummary.reqReason',
                disabled: false,
                required: false,
                id: 'reqReason',
            },
            {
                type: 'input',
                key: 'accountId',
                id: 'accountId',
                disabled: true,
                required: true,
                hideExpression: true,
            },
        ],
    },
];

export const PRIMARY_SUPPLIER_ACTIVITY_DETAILS = [
    {
        groupFields: false,
        fieldGroup: [
            {
                type: 'input',
                className: 'col-6',
                key: 'name',
                label: 'FACILITY_MODULE.facilities.facilityName',
                disabled: true,
                required: false,
                id: 'facilityName',
            },
            {
                type: 'input',
                className: 'col-6',
                key: 'organizationName',
                label: 'FACILITY_MODULE.facilities.organizationName',
                disabled: true,
                required: true,
                id: 'organizationName',
            },
        ],
    },
    {
        groupFields: true,
        label: 'FACILITY_MODULE.facilities.fuelProducedAtFacility',
        fieldGroup: [
            {
                type: 'multi-checkbox',
                key: 'fuelList',
                className: 'col-12',
                source: SOURCE_DATA_TYPE.FUELS,
                dataType: 'PRODFF/LIQUID',
                disabled: false,
                required: true,
                inline: true,
            },
        ],
    },

];

export const RESPOND_TOADMIN_COMMENTS: IRawFormBase[] =   [{
    groupFields: false,
    fieldGroup: [
        {
            type: 'textarea',
            className: 'col-12',
            key: 'userComment',
            label: 'REGISTRATION_PAGE.cfrPendingRegistration.respondToAdminComments',
            required: true,
            validators: ['validateEmptyString']
        },
    ],
}];

export const PRIMARY_SUPPLIER = {
    key: 'primarySupplier',
    type: 'checkbox',
    className: 'ml-5 col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt1',
    required: true,
};

export const REGISTERED_CREATOR = {
    key: 'registeredCreator',
    type: 'checkbox',
    className: 'ml-5 col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt2',
    required: true,
};

export const FOREIGN_SUPPLIER = {
    key: 'foreignSupplier',
    type: 'checkbox',
    className: 'ml-5 col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt3',
    required: true,
};

export const CARBON_INTENSITY_CONTRIBUTOR = {
  key: 'carbonIntensityContributor',
  type: 'checkbox',
  className: 'ml-5 col-12',
  label: 'ACCOUNTS_MODULE.reqRegCancellation.opt6',
  required: true,
};

export const ACCEPT_CANCELLATION  = {
    key: 'acceptCancellation',
    type: 'checkbox',
    className: 'col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt4',
    required: true,
};

export const ACCEPT_CANCELLATION_VB = {
    key: 'acceptCancellation',
    type: 'checkbox',
    className: 'col-12',
    label: 'ACCOUNTS_MODULE.reqRegCancellation.opt4VB',
    required: true,
};

export const REQUEST_REGISTRATION_CANCELLATION_REMARKS = [
    {
        type: 'textarea',
        className: 'col-12',
        key: 'adminRemark',
        label: 'ACCOUNTS_MODULE.reqRegCancellation.remark',
        required: false,
        disabled: false,
        hideExpression: false,
    },
    {
        key: 'discloseInfo',
        type: 'checkbox',
        className: 'col-12',
        label: 'ACCOUNTS_MODULE.reqRegCancellation.opt5',
        required: false,
        disabled: false,
        hideExpression: false,
    },
];

export const ITP_ADMIN_REMARKS: IRawFormBase[] = [{
  groupFields: false,
  fieldGroup: [
    {
      type: 'textarea',
      className: 'col-12',
      key: 'itpAdminRemarks',
      label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
      required: true,
    },
  ],
}];

export const REGISTRATION_REVIEW_UPDATE: IRawFormBase[] = [{
  groupFields: false,
  fieldGroup: [
    {
      type: 'textarea',
      className: 'col-12',
      key: 'adminRemark',
      label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
      required: true,
    },
  ],
}];

export const APPLICATION_CONTACT_PERSON_POSTAL_ADDRESS_FORM_SECTION = {
  prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
  key: 'postalAddress',
  changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
  sameAsOrganization: {
      hide: false,
      changeExpr: null,
  },
  hidePoBox: false,
  sameAsPostal: {
      hide: true,
  },
  hideLatLong: true,
};

export const APPLICATION_CONTACT_PERSON_CIVIC_ADDRESS_FORM_SECTION = {
  prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
  key: 'civicAddress',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: true,
  sameAsPostal: {
      hide: false,
      changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
  },
  hideLatLong: true,
};

export const CREDIT_ADJUSTMENT_REPORT_REVIEW: IRawFormBase[] = [{
    groupFields: false,
    fieldGroup: [
      {
        type: 'textarea',
        className: 'col-12',
        key: 'adminRemark',
        label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
        required: true,
      },
    ],
  }];

export const COCR_REVIEW_UPDATE: IRawFormBase[] = [{
  groupFields: false,
  fieldGroup: [
    {
      type: 'textarea',
      className: 'col-12',
      key: 'adminRemark',
      label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
      required: true,
      validators: ['validateEmptyString']
    },
  ],
}];

export const RESPOND_TOADMIN_COMMENTS_COCR: IRawFormBase[] =   [{
    groupFields: false,
    fieldGroup: [
        {
            type: 'textarea',
            className: 'col-12',
            key: 'comment',
            label: 'REGISTRATION_PAGE.cfrPendingRegistration.respondToAdminComments',
            required: true,
            validators: ['validateEmptyString']
        },
    ],
}];

export const RESPOND_TOADMIN_COMMENTS_CCRR: IRawFormBase[] =   [{
    groupFields: false,
    fieldGroup: [
        {
            type: 'textarea',
            className: 'col-12',
            key: 'comment',
            label: 'REGISTRATION_PAGE.cfrPendingRegistration.respondToAdminComments',
            required: true,
            validators: ['validateEmptyString']
        },
    ],
}];

export const getMBRReviewFields = (sources?, model?) => {
    return [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'dict-select',
                key: 'carbonIntensityStatus',
                label: 'TASK_TO_DO.carbonIntensityPathwayReportModal.carbonIntensityStatus',
                disabled: false,
                translatePrefix: 'TASK_TO_DO.carbonIntensityPathwayReportModal',
                required: false,
                placeholder: 'selectItem',
                source: sources.carbonIntensityStatus,
                hideExpression: 'model.carbonIntensityId === "DEFAULT"'
            },
            {
                type: 'dict-select',
                key: 'ciInvalidReason',
                label: 'TASK_TO_DO.carbonIntensityPathwayReportModal.reason',
                disabled: false,
                requiredExpression: 'model.carbonIntensityStatus === "INVALID"',
                placeholder: 'selectItem',
                hideExpression: 'model.carbonIntensityStatus!== "INVALID"',
                bindValue: 'code',
                source: sources.ciInvalidReason,
            },
            {
              type: 'textarea',
              className: 'col-12',
              key: 'adminRemark',
              label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
              //required: true,
              //validators: ['validateEmptyString'],
          },
        ],
      }];
}
