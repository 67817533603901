<div class="row justify-content-center">
  <div class="col-sm-8 col-md-6">
    <h1 class="wcag-visuallyhidden">Login methods</h1>
    <h2 class="mt-5">{{ 'REGISTRATION_PAGE.authAccountSelection.secureSignInMethodText' | translate }}</h2>
    <p>{{ 'REGISTRATION_PAGE.authAccountSelection.secureSignInMethodDesc' | translate }}</p>

    <div class="row mt-5 pt-3 pb-3 border" *ngIf="getSAMLProvider('Native') && getSAMLProvider('Native').enabled">
      <div class="col-12">
        <h3>Use Native Method</h3>
       <button type="button" [routerLink]="['/login/1' | localize]"
        class="btn btn-secondary mb-3" aria-label="autho">
        Continue to Native Login
      </button>

       </div>
    </div>

    <div class="row mt-5 pt-3 pb-3 border" *ngIf="getSAMLProvider('SecureKey') && getSAMLProvider('SecureKey').enabled">
      <div class="col-7">
        <h3>{{ 'REGISTRATION_PAGE.authAccountSelection.signInPartnerLabel' | translate }}</h3>
        <div class="bd-callout bd-callout-warning"
             *ngIf="getSAMLProvider('SecureKey').displayIssueText"
             [innerHTML]="getSAMLProvider('SecureKey').issueTextKey | translate"></div>
        <button type="button"
                class="btn btn-secondary mb-3"
                (click)="openLoginPage('SecureKey')">
          {{ 'REGISTRATION_PAGE.authAccountSelection.signInPartnerBtn' | translate }}
        </button>
        <span [innerHTML]="'REGISTRATION_PAGE.authAccountSelection.signInPartnerDesc' | translate"></span>
      </div>
      <div class="col-5">
        <div class="text-center">
          <img class="logos" [src]="lang == 'en' ? '/assets/images/gc-csp-logos-eng-large-new-v2.png' : '/assets/images/gc-csp-logos-fra-large-new-v2.png'" alt="{{ 'REGISTRATION_PAGE.authAccountSelection.signinPartnersText' | translate}}"/>
        </div>
        <div class="mt-3">
          <ngb-accordion [closeOthers]="true">
            <ngb-panel id="partners" title="{{ 'REGISTRATION_PAGE.authAccountSelection.viewListSignInPartnersLink' | translate }}">
              <ng-template ngbPanelContent>
                <ul>
                  <ng-container *ngIf="lang == 'en'; else FR">
                    <li>Affinity Credit Union</li>
                    <li>Alberta Treasury Branches</li>
                    <li>BMO Financial Group</li>
                    <li>Caisse Alliance</li>
                    <li>CIBC Canadian Imperial Bank of Commerce</li>
                    <li>Coast Capital Savings</li>
                    <li>Conexus Credit Union</li>
                    <li>connectFirst</li>
                    <li>Desjardins Group (Caisses Populaires)</li>
                    <li>Libro Credit Union</li>
                    <li>Meridian Credit Union</li>
                    <li>National Bank of Canada</li>
                    <li>RBC Royal Bank</li>
                    <li>Scotiabank</li>
                    <li>Servus Credit Union</li>
                    <li>Simplii Financial</li>
                    <li>Steinbach Credit Union</li>
                    <li>Tangerine</li>
                    <li>TD Bank Group</li>
                    <li>UNI</li>
                    <li>Vancity</li>
                    <li>Wealthsimple</li>
                  </ng-container>
                  <ng-template #FR>
                    <li>Affinity Credit Union</li>
                    <li>Alberta Treasury Branches</li>
                    <li>BMO Groupe financier</li>
                    <li>Banque Nationale du Canada</li>
                    <li>Banque Scotia</li>
                    <li>Caisse Alliance</li>
                    <li>CIBC Banque Canadienne Impériale de Commerce</li>
                    <li>Coast Capital Savings</li>
                    <li>Conexus Credit Union</li>
                    <li>connectFirst</li>
                    <li>FINANCIÈRE SIMPLII</li>
                    <li>Groupe Desjardins (Caisse Populaire)</li>
                    <li>Groupe Banque TD</li>
                    <li>Libro Credit Union</li>
                    <li>Meridian Credit Union</li>
                    <li>RBC Banque Royale</li>
                    <li>Servus Credit Union</li>
                    <li>Steinbach Credit Union</li>
                    <li>Tangerine</li>
                    <li>UNI</li>
                    <li>Vancity</li>
                    <li>Wealthsimple</li>
                  </ng-template>
                </ul>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>

    <div class="row mt-5 pt-3 pb-3 border" *ngIf="getSAMLProvider('GCKey') && getSAMLProvider('GCKey').enabled">
      <div class="col-12">
        <h3>{{ 'REGISTRATION_PAGE.authAccountSelection.GCKeyLabel' | translate }}</h3>
        <div class="bd-callout bd-callout-warning"
             *ngIf="getSAMLProvider('GCKey').displayIssueText"
             [innerHTML]="getSAMLProvider('GCKey').issueTextKey | translate"></div>
        <button type="button" (click)="openLoginPage('GCKey')"
        class="btn btn-secondary mb-3" aria-label="autho">
        {{ 'REGISTRATION_PAGE.authAccountSelection.gcKeySignInBtn' | translate }}
      </button>

        <span [innerHTML]="'REGISTRATION_PAGE.authAccountSelection.gcKeySignInDesc' | translate"></span>
      </div>
    </div>
    <p><a [routerLink]="['/forgot-username' | localize]">{{ 'REGISTRATION_PAGE.authAccountSelection.recoverAccount' | translate }}</a></p>
    <p><a [routerLink]="['/register/faq' | localize]" target="_blank">{{ 'REGISTRATION_PAGE.authAccountSelection.faqLink' | translate }}</a></p>
  </div>
</div>

