import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IApp } from '@core/models/app.interfaces';
import { CiPathwayReportService } from '@module/ci-pathway-report/ci-pathway-report.service';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';

@Component({
  selector: 'app-ci-path-prev-selected-doc',
  templateUrl: './ci-path-prev-selected-doc.component.html',
  styleUrls: ['./ci-path-prev-selected-doc.component.scss'],
})
export class CiPathPrevSelectedDocComponent implements OnInit {
  @ViewChild('accountDocumentActions', {static: true}) public accountDocumentActions: TemplateRef<any>;
  public openUploadPopup = true;
  public firstItemIndex = 0;
  private pagination = {
    page: 0,
    size: 20,
  } as IApp.IPagination;
  tableData;
  selectedRows: any[] = [];
  
  tableDataList = new IApp.IFacilityEquipmentPage();
  public tableConfig: ITableViewConfig;
  previousDoc;
  documentUploadConfig: IApp.IDocumentUploadConfig = {
    comment: true,
    documentType: false,
    entityName: 'USER',
    documentTypeValue: 2,
  };
 

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<CiPathPrevSelectedDocComponent>,
    public service: CiPathwayReportService,
  ) { }

  ngOnInit() {
    // this.service.getPreviouslySelectedDocument().subscribe((data)=>{
    //   this.previousDoc = data;
      this.tableConfig = {
        translationPrefix: 'CARBON_INTENSITY_MODULE.carbonIntensityList',
        paginator: true,
        rowsPerPage: ROWS_PER_PAGE,
        columns: [
          {
            checkboxSelect:true,
            width: '3em',
          },
          {
            field: 'name',
            header: 'ciPrevDocumentName',
            sortField: 'name',
            width: AUTO_WIDTH_FOR_COLUMNS,
          },
          {
            field: 'lastModifiedDateStr',
            header: 'ciPrevdocumentTimeStamp',
            //translate: true,
            sortField: 'lastModifiedDateStr',
            width: AUTO_WIDTH_FOR_COLUMNS,
          },
          {
            header: 'actions',
            width: AUTO_WIDTH_FOR_COLUMNS,
            className: 'overflow-visible',
            templateRef: this.accountDocumentActions,
          }
        ],
      };
    //   this.tableDataList = {...this.tableDataList, content: this.previousDoc};
      
    // });
  }

  

  close() {
    this.openUploadPopup = false;
    this.dialogRef.close();
  }

  save()
  {    
    this.selectedRows.forEach(item => {
      item.selectFromPrevious = true;
  });
    let previouslyUploadedDocuments = [];
    let previousEntityIds = [];
    const uniqueDocuments = new Set();
    if (this.selectedRows && this.selectedRows.length) {
      for (let obj of this.selectedRows) {
        if (!uniqueDocuments.has(obj.id)) {
          uniqueDocuments.add(obj.id);
          previouslyUploadedDocuments.push(
             obj.id,
          );
          if(!previousEntityIds.includes(obj.entityId)){
            previousEntityIds.push(obj.entityId) }   
        }
      }
     // let previousEntityId = this.selectedRows[0].entityId;
    
    this.service.savePreviouslySelectedDocument(previousEntityIds, previouslyUploadedDocuments).
    subscribe((res)=>{}
    );
    }
    this.dialogRef.close(this.selectedRows);
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    this.loadDoc(this.pagination);
  }

  loadDoc(pagination: IApp.IPagination){
    this.service.getPreviouslySelectedDocument(pagination).subscribe((data)=>{
      this.previousDoc = data;
    //  this.tableDataList = {...this.tableDataList, content: this.previousDoc.content};  
    this.tableDataList = this.previousDoc;
    });
  }

  getSelections(selectedRows) {
    this.selectedRows = selectedRows;
  }

  


}

