import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import {
  accountMap,
  cmNewSubAccount,
  newSubAccount,
  newSubAccountByMop
} from '@core/constants/registration-route-types.const';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IRawFormBase } from '@core/models/raw-form.types';
import { CurrentUser } from '@core/models/user.model';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { RegistrationService } from '@module/registration/registration.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { ModalService } from '@shared/services/modal.service';
import { NavigationService } from '@shared/services/navigation.service';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { SOURCE_DATA_TYPE } from '../stepper/stepper.const';
import {
  CHECKBOX_IGNORED_FIELDS,
  CONTACT_PERSON_MAILING_ADDRESS,
  CONTACT_PERSON_PHYSICAL_ADDRESS,
  MAILING_ADDRESS_FORM_SECTION,
  OFFSET_REGISTRATION_FORM_WITHOUT_ARP,
  OFFSET_SUB_REGISTRATION_FORM,
  OFFSET_SUB_REGISTRATION_FORM_ADMIN,
  PHYSICAL_ADDRESS_FORM_SECTION,
} from './offset-account-type.const';
import { mergeObjectsSkipNulls } from '@core/utilities/utilities.constants';

const SAME_AS_AUTH_OFFICIAL = 'sameAsAuthorizedOfficial';
const MASTER_ACCOUNT_ID = 'masterAccount.id';

@Component({
  selector: 'app-offset-account-type',
  templateUrl: './offset-account-type.component.html',
  styleUrls: ['./offset-account-type.component.scss'],
})
export class OffsetAccountTypeComponent implements OnInit, OnDestroy {
  @ViewChild('ngForm', { static: false }) formEl: ElementRef;

  @Output() back = new EventEmitter();
  @Output() next = new EventEmitter();

  public formGroup = new FormGroup({});
  @Input() model: any;
  @Input() registrationType: any;
  @Input() masterAccountData: any;
  @Input() onestep = false;

  public fields: FormlyFieldConfig[];

  public accountId: number;
  masterAcccountId: number;
  accountConfig: any;
  private _destroy$ = new Subject<any>();
  public showSave = true;
  readonly container;
  user: CurrentUser;
  private isEmailConfirmedOnce = false;

  public get isCM() {
    return this.storeService.user.isCM();
  }

  constructor(
    private accountManagementService: AccountManagementService,
    private formFactoryService: FormFactoryService,
    private translateService: TranslateService,
    private registrationService: RegistrationService,
    private storeService: StoreService,
    private activatedRoute: ActivatedRoute,
    private lookupService: LookupService,
    private formService: FormService,
    private authService: AuthService,
    private modalService: ModalService,
    private navigation: NavigationService,
  ) {
    this.container = document.getElementById('main-content');
  }

  ngOnInit() {
    this.user = this.storeService.user;
    if (!this.model) {
      this.model = {
        accountType: {
          id: 3,
        },
        contactPerson: {},
      };
    }
    this.accountId = this.activatedRoute.snapshot.params['accountId'];
    this.masterAcccountId = this.activatedRoute.snapshot.params['id'];
    if (this.accountId && this.masterAcccountId) {
      this.registrationType = newSubAccount;
    }
    this.updateModel();
    this.subscribeCheckboxChange();
    this.businessStructureChangeSubscription();
    this.subscribeMasterAccountNameChange();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isRegistrationSubAccount() {
    return this.registrationType === newSubAccount;
  }

  isRegistrationSubAccountByMop() {
    return this.registrationType === newSubAccountByMop;
  }

  isRegistrationCMSubAccount() {
    return this.registrationType === cmNewSubAccount;
  }

  public nextStep() {
    this.formFactoryService.markFormAsDirty(this.formGroup);

    if (this.formGroup.valid) {
      if (this.activatedRoute.snapshot.data.step === 1 && !this.isEmailConfirmedOnce) {
        this._showEmailConfirmationModal();
      } else {
        this._proceedToNextStep();
      }
    } else {
      this.formService.scrollToFirstInvalidControl(this.formEl, this.container);
    }
  }

  public previousStep() {
    if (!this.formGroup.touched) {
      this.back.emit();
      return;
    }

    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: 'newAccountObpsConfirmation',
          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(val => {
        if (val) {
          this.back.emit();
        }
      });
  }

  public save() {
    this.formFactoryService.markFormAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      const saveMethod = this.isCM ? 'saveOMPAccount' : 'saveOffsetAccount';
      this.registrationService[saveMethod](this.model).subscribe(
        result => {
          this.navigation.back();
        },
        (error: HttpErrorResponse) => {
          this.formService.parseErrors(this.formGroup, error.error);
        },
      );
    } else {
      this.formService.scrollToFirstInvalidControl(this.formEl, this.container);
    }
  }

  public close() {
    // this.location.back();
    this.navigation.back();
  }

  private configureForm() {
    this.fields = [];
    this.accountConfig = accountMap[this.registrationType];
    if (this.accountConfig) {
      this.onestep = this.accountConfig.onestep;
    }
    if (this.isRegistrationSubAccount() || this.isRegistrationCMSubAccount()) {
      this.fields = this.formFactoryService.configureForm(OFFSET_SUB_REGISTRATION_FORM);
      this.fields[0].fieldGroup[1].templateOptions.disabled = false
      this.fields[0].fieldGroup[2].templateOptions.disabled = false

    } else if (this.isRegistrationSubAccountByMop()) {
      this.fields = this.formFactoryService.configureForm(OFFSET_SUB_REGISTRATION_FORM_ADMIN);
    }

    if (this.onestep) {
      if (!this.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER')) {
        this.formFactoryService.configureForm(OFFSET_REGISTRATION_FORM_WITHOUT_ARP).forEach(f => {
          f.key = f.key ? `masterAccount.${f.key}` : `masterAccount`;
          f.fieldGroup.forEach(fg => (fg.templateOptions.disabled = true));
          this.fields.push(f);
        });

        if (this.isRegistrationSubAccount() || this.isRegistrationSubAccountByMop()) {
          this.formFactoryService.addPrincipalContactPerson(this.fields);
        }
      } else if (this.isCM) {
        this.fields.push(...this.formFactoryService.getCMAccountRegistrationForm(
          this.masterAccountData.accountSubType.name,
          this.isRegistrationCMSubAccount()
        ));
        this._subscribeCheckboxChange();
      } else {
        this.addContactPersonFields(true);
      }
    } else {
      this.formFactoryService
        .configureForm(this.formFactoryService.offsetFormJobTitleRequiredExpression(this.formGroup))
        .forEach(f => this.fields.push(f));
      const APRaddress = this.formFactoryService.configurFormPostWithCivicAddress(
        PHYSICAL_ADDRESS_FORM_SECTION,
        MAILING_ADDRESS_FORM_SECTION,
      )[0];
      APRaddress.wrappers = ['app-formly-fieldset'];
      APRaddress.templateOptions = {label: this.translateService.instant('REGISTRATION_PAGE.offsetRegistrationForm.APRAddress')};
      this.fields.push(APRaddress);

      this.addContactPersonFields();
    }

    if (this.accountConfig) {
      if (this.accountConfig.subAccount) {
        this.getMasterAccountDetails();
        if (this.masterAccountData.id && !this.isCM) {
          this.fields.push(this.formFactoryService.configureForm(this.getProjectTransferSection())[0]);
        }
      } else if (this.accountConfig.account && !this.accountConfig.admin) {
        setTimeout(() => {
          this.formGroup.get('authorizedOfficial').disable();
        });
      }
    }
  }

  private addContactPersonFields(skipAOCopy = false) {
    const contactPersonForm = this.formFactoryService.configureForm([
      this.formFactoryService.contactPersonJobTitleRequiredExpression(this.formGroup),
    ])[0];
    this.fields.push(contactPersonForm);
    const physicalAddr = skipAOCopy ? {...CONTACT_PERSON_PHYSICAL_ADDRESS, sameAsPostal: { ...CONTACT_PERSON_PHYSICAL_ADDRESS.sameAsPostal, hide: true}} : CONTACT_PERSON_PHYSICAL_ADDRESS;
    const contactPersonAddress = this.formFactoryService.configurFormPostWithCivicAddress(
      physicalAddr,
      CONTACT_PERSON_MAILING_ADDRESS,
    )[0];
    contactPersonAddress.wrappers = ['app-formly-fieldset'];
    contactPersonAddress.templateOptions = {
      label: this.translateService.instant('REGISTRATION_PAGE.offsetRegistrationForm.contactPersonAddress'),
    };
    this.fields.push(contactPersonAddress);
  }

  getMasterAccountDetails() {
    if (this.onestep) {
      this.model.accountSubType = this.masterAccountData.accountSubType;
      this.model.masterAccountName = this.masterAccountData.legalName;
      this.model.englishTradeName = this.masterAccountData.englishTradeName;
      this.model.frenchTradeName = this.masterAccountData.frenchTradeName;
      this.model.address = this.masterAccountData.address;
      this.model.authorizedOfficial = mergeObjectsSkipNulls(this.masterAccountData.authorizedOfficial, this.model.authorizedOfficial);
      this.model.authorizedOfficial = {...this.model.authorizedOfficial, name: this.model.authorizedOfficial.fullName};
      this.model.businessStructure = this.masterAccountData.businessStructure;
      this.model.registeredCharityNumber = this.masterAccountData.registeredCharityNumber;
      this.model.registrationNumber = this.masterAccountData.registrationNumber;
      if (this.model.address) {
        this.model.address.id = null;
      }
    }
    this.model.masterAccount = this.masterAccountData;

    setTimeout(() => {
      if (this.formGroup.get('masterAccountName')) {
        this.formGroup.get('masterAccountName').disable();
      }
    });
  }

  private updateModel() {
    if (this.accountId) {
      this.registrationService.getOffsetAccountById(this.accountId).subscribe(result => {
        this.model = result;
        this.model.authorizedOfficial.displayAoProfilePublicView = this.model.displayAoProfilePublicView;
        this.masterAccountData = this.model.masterAccount;
        this.configureForm();
        setTimeout(() => {
          this.storeService.user.associationsList.forEach(asctn => {
            if (
              asctn.accountDto &&
              `${asctn.accountDto.id}` === `${this.accountId}` &&
              ['PENDING_REVIEW_ADMIN', 'PENDING_REVIEW_ADMIN2', 'ON_HOLD', 'REJECTED'].includes(asctn.accountDto.status)
            ) {
              this.formGroup.disable();
              this.showSave = false;
            } else {
              this.formGroup.get('legalName').disable();
              if (this.formGroup.get('authorizedOfficial.email')) {
                this.formGroup.get('authorizedOfficial.email').disable();
              }
            }
          });
        });
      });
    } else {
      this.configureForm();
    }
  }

  getProjectTransferSection(): IRawFormBase[] {
    const key = this.model.pendingProjectTransferList ? 'pendingProjectTransferList' : 'projectTransferList';
    return [
      {
        groupFields: true,
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.projectWishToMove',
        fieldGroup: [
          {
            type: 'multi-checkbox',
            className: 'col-6',
            key,
            source: SOURCE_DATA_TYPE.SEARCH_PROJECT_BY_ACCOUNT,
            dataType: this.accountId ? `${this.masterAccountData.id}/${this.accountId}` : this.masterAccountData.id,
            disabled: this.model.status === 'ACTIVE',
            required: false,
            id: 'entityId',
          },
        ],
      },
    ];
  }

  private subscribeCheckboxChange() {
    this.formFactoryService.event_callback.pipe(takeUntil(this._destroy$)).subscribe(field => {
      if (field.key === SAME_AS_AUTH_OFFICIAL && this.model.contactPerson[SAME_AS_AUTH_OFFICIAL]) {
        this.model = {
          id: null,
          ...this.model,
          contactPerson: { ...this.model.authorizedOfficial, [SAME_AS_AUTH_OFFICIAL]: true },
        };
        if (this.model.contactPerson.civicAddress && this.model.contactPerson.civicAddress.sameAsPostalAddress) {
          this.formGroup.get('contactPerson.civicAddress').disable();
          this.formGroup.get('contactPerson.civicAddress.sameAsPostalAddress').enable();
        }
      }
    });
  }

  private subscribeMasterAccountNameChange() {
    this.formFactoryService.dictCallback
      .pipe(
        takeUntil(this._destroy$),
        filter(field => field.key === MASTER_ACCOUNT_ID),
      )
      .subscribe(field => {
        if (this.model.masterAccount && this.model.masterAccount.id && this.masterAcccountId !== this.model.masterAccount.id) {
          this.masterAcccountId = this.model.masterAccount.id;
          this.accountManagementService.getAccountDetails(this.model.masterAccount.id).subscribe(accountResponse => {
            this.masterAccountData = accountResponse;
            this.configureForm();
          });
        }
      });
  }

  private businessStructureChangeSubscription() {
    this.formFactoryService.dictCallback
      .pipe(
        takeUntil(this._destroy$),
        filter(field => field.key === 'businessStructure.id'),
        switchMap(() => this.lookupService.getBusinessStructure(this.storeService.getAccountTypeIdByProgramType())),
      )
      .subscribe(result => {
        if (this.model.businessStructure) {
          const businessStructure = result.filter(data => data.id === this.model.businessStructure.id)[0];
          if (businessStructure && businessStructure.id === this.storeService.getBusinessStructureIdByProgramType()) {
            this.model.charitableFirm = true;
          } else {
            this.model.charitableFirm = false;
          }
        }
      });
  }

  private _showEmailConfirmationModal() {
    this.modalService.open(ServiceMessageComponent, {
        messages: null,
        yesNoButton: true,
        message: 'confirmationMessageRegistrationEmail',
        metaDataList: [
          this.model.authorizedOfficial.email,
          this.model.legalName
        ],
        type: SereviceMessageType.WARNING,
      },
      true, DEFAULT_DIALOG_CONFIG).afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.isEmailConfirmedOnce = true;
          this._proceedToNextStep();
        }
      });
  }

  private _proceedToNextStep() {
    this.registrationService.submitOffsetAccount(this.model, this.accountConfig.offset).subscribe(
      result => {
        this.storeService.setAccessKey(result.entity);
        if (this.storeService.user && this.storeService.user.id) {
          this.authService.changeProgram(this.storeService.user.programId)
            .pipe(switchMap(() => this.lookupService.getFacilityNames(false,  true)))
            .subscribe(() => {
              this.navigation.back();
            });
        } else {
          this.next.emit();
        }
      },
      (error: HttpErrorResponse) => {
        this.isEmailConfirmedOnce = false;
        this.formService.parseErrors(this.formGroup, error.error);
      },
    );
  }

  private _subscribeCheckboxChange() {
    setTimeout(() => {
      const keyPrefix = 'contactPerson';
      const FORM_GROUP_KEYS = ['postalAddress', 'civicAddress'];
      FORM_GROUP_KEYS.forEach(formGroupKey => {
        const sameAsPostalAddressCheckbox = this.formGroup.get(`${keyPrefix}.${formGroupKey}.sameAsPostalAddress`);
        sameAsPostalAddressCheckbox.valueChanges
          .pipe(takeUntil(this._destroy$))
          .subscribe(value => { if (value) this._mapAOModelToFormGroup(keyPrefix, formGroupKey) });
      });
    });
  }

  private _mapAOModelToFormGroup(keyPrefix, groupKey) {
    const postalAddressGroup = this.formGroup.get(`${keyPrefix}.${groupKey}`) as FormGroup;

    if (!postalAddressGroup) {
      return;
    }

    Object.keys(postalAddressGroup.controls).forEach(controlKey => {
      if (!CHECKBOX_IGNORED_FIELDS.includes(controlKey)) {
        postalAddressGroup.get(controlKey)
          .patchValue(this.masterAccountData.authorizedOfficial[groupKey][controlKey]);
      }
    });
  }
}
