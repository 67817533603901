
<div id="auth-contact-person-info">
    <h2>{{'AGREEMENT_CREDITS.Step.main_title' | translate }}</h2>

    <h2>{{'AGREEMENT_CREDITS.Step_4.rcaAuthOffInfo' | translate }}</h2>

    <formly-form [form]="formGroup" [model]="model" [fields]="fields"></formly-form>

    <h2 class="mt-3">{{'AGREEMENT_CREDITS.Step_4.rcaContactPersonInfo' | translate }}</h2>

    <formly-form [form]="formGroupOtherParty" [model]="model" [fields]="fieldsOtherParty"></formly-form>

</div>
