import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { IDocument } from '@module/registration/registration.model';
import { STATUS_ICON} from '@shared/shared.const';

@Component({
  selector: 'app-upload-pending-documents',
  templateUrl: './upload-pending-documents.component.html',
  styleUrls: ['./upload-pending-documents.component.scss']
})
export class UploadPendingDocumentsComponent implements OnInit {

  docAllowedFileTypes: string;
  docAllowedSize: string;
  statusIcon = STATUS_ICON;
 
  @Input() pendingDocumentMetaData: IApp.IPendingDocumentMetaData;
  @Input() hideTitleAndDescription: boolean = false;
  @Output() documentResult = new EventEmitter<IApp.IDocument[]>();


  constructor( private storeService: StoreService) { }
  ngOnInit() {
    // this.storeService.getAllowedDocTypes().subscribe(
    //   prop => this.docAllowedFileTypes = prop.value,
    // );
    console.log("fil exttttt "+this.pendingDocumentMetaData.fileExtension);
    if(this.pendingDocumentMetaData.fileExtension){
      this.storeService.getProperty(this.pendingDocumentMetaData.fileExtension).subscribe(
        prop => this.docAllowedFileTypes = prop.value,
      );
    }
    if(this.pendingDocumentMetaData.sizeProperty){
      this.storeService.getProperty(this.pendingDocumentMetaData.sizeProperty).subscribe(
        prop => this.docAllowedSize = prop.value,
      );
    }
    this.pendingDocumentMetaData.documentUploadConfig.showHeader = true;
  }

 updateDocumentDetails(documents: IDocument[]) {
    this.documentResult.emit(documents);
  }
}