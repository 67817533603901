<p-dialog #dialog [className]="'eccc'" [(visible)]="visible" [modal]="true" [responsive]="true"
  [style]="{width: '1200px'}" [minY]="100" [maximizable]="false" [autoZIndex]="false" [closable]="false"
  (onShow)="scrollToTop()" [header]="'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.TitleAddDetails' | translate">

  <p class="font-weight-bold mx-4 h6"> {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.internalTitle' | translate}}</p>

  <div class="container">
    <form [formGroup]="lcaFormLeft">
      <formly-form [form]="lcaFormLeft" [model]="model" [fields]="lca1FieldList"></formly-form>
    </form>
    <p>{{ (isElectricity() ? 'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.chargingDetailsForCI': isCompressed() ? 'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.fuelingDetailsForCI' : 'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.facilityDetailsForCI' ) | translate }}: <a class="link"
        href="#" (click)="viewFacilityDetails($event)"> {{ 'COMMON.actionsLabel.VIEW'  | translate }}</a></p>


    <div style="margin-top: 18px;">
      <form [formGroup]="lcaFormLeft">
        <formly-form [form]="lcaFormLeft" [model]="model" [fields]="lca2FieldList"></formly-form>
      </form>
    </div>
    <p class="font-weight-light h6"> <b> {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.CIInfoTitle' | translate}} </b></p>
    <div class="row">
      <div class="col-6" #leftCol>
        <p class="h6" style="margin-bottom: 4.6rem;"> <b>
            {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.DetailsTitleForApprovedCI' | translate}} </b></p>
        <form [formGroup]="lcaFormLeft">
          <formly-form [form]="lcaFormLeft" [model]="model" [fields]="lca3FieldListLeft"></formly-form>
        </form>
      </div>
      <div class="col-6 border-left" #rightCol>
        <p class="h6" style="margin-bottom: 6%;"> <b>
            {{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.DetailsTitleForCIPathwayReport' | translate}} </b>
        </p>
        <form [formGroup]="lcaFormRight">
          <formly-form [form]="lcaFormRight" [model]="model" [fields]="lca3FieldListRight"></formly-form>
        </form>
        <div style="margin-bottom: 10.8rem;"></div>
        <form [formGroup]="lcaForm1">
          <formly-form [form]="lcaForm1" [model]="model" [fields]="lca4FieldListRight"></formly-form>
        </form>
      </div>
    </div>
    <hr>
    <!-- Documents Table -->
    <div class="table-area my-5">
      <p class="h6"><b>{{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.staticDocumentsTableHeading' | translate}}</b>
      </p>
      <app-table-view [data]="staticDocumentsTableData" [config]="tableConfig"
       [relative]="true" [firstItemIndex]="firstItemIndex">
      </app-table-view>
      <!-- <app-documents
        [documentUploadConfig]="tableConfig"
        [documentsPage] = "staticDocumentsTableData"
        [showPlaceholders]="true"
        [hidden]="true" >
      </app-documents> -->

      <ng-template #actionTemplate let-data let-rowIndex="rowIndex">
        <ng-select
              wcag-label
              [placeholder]="'COMMON.actionsLabel.actions' | translate"
              [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.actions' | translate}"
              [items]="tableActionItems"
              class="ng-dropdown-left"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="data.dummyModel"
              (change)="triggerTableAction($event, rowIndex, data)"
              [appendTo]="'.ui-table-wrapper table'"
              [alignRight]="true">
          </ng-select>
      </ng-template>
    </div>

    <p class="h6"><b>{{'CARBON_INTENSITY_PATHWAY_REPORT.actualCIInformation.uploadedDocumentForApprovedCI' | translate}}</b></p>

    <div *ngIf="documentUploadConfig" class="form-group mt-4">
      <app-documents [documentUploadConfig]="documentUploadConfig" (documentResult)="updateDocumentDetails($event)"
        [documentsPage] = "documentsPage"
        [showPlaceholders]="true" [ciPathwayReport]="false"
        [disabled] = "disabled">
      </app-documents>
    </div>

    <form [formGroup]="lcaFormRight">
      <formly-form [form]="lcaFormRight" [model]="model" [fields]="lcaSection5List"></formly-form>
    </form>



  </div>



  <div class="d-flex justify-content-end mt-2">
    <button type="button" disableOnUpload class="btn btn-secondary mr-2"
      (click)="close()">{{'CARBON_INTENSITY_PATHWAY_REPORT.actions.close' | translate}}</button>
    <button type="button" disableOnUpload class="btn btn-primary" (click)="save()" [disabled] = "reportstatus()">
      {{'CARBON_INTENSITY_PATHWAY_REPORT.actions.save' | translate}}
    </button>
  </div>
</p-dialog>
