import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
export const CREATE_CARBON_INTENSITY_PATHWAY_REPORT_API = '/cfr-service/complianceCreditRevReport/getNewComplianceCreditRevReport';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { environment } from '@env/environment';
import { CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY, CREDIT_REVENUE_REPORT_DATA_KEY } from '@module/credit-revenue-report/credit-revenue-report.const';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { ModalService } from '@shared/services/modal.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IApprovedCiFacilitiesListPayload, ICIPathwayReport, ICIPathwayReportSearchPayload } from './ci-pathway-report.types';
import { CCR_CURRENT_STEP_KEY, CCR_DATA_KEY } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import { CI_CURRENT_STEP_KEY, CI_DATA_KEY } from './ci-pathway-report.const';
import { tap } from 'rxjs/operators';
import { StoreService } from '@core/store/store.service';
import { HelperService } from '@shared/services/helper.service';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { IApp } from '@core/models/app.interfaces';
const STEPS_TRANSLATION_PREFIX = 'CARBON_INTENSITY_PATHWAY_REPORT.stepDetails.';
export const CI_APPLICATION_SAVE_PRODUCTION_LOW_FUELS = '/account-service/ciPathwayReport/save';
export const CI_APPLICATION_SAVE_AND_PROCEED = '/account-service/ciPathwayReport/saveAndProceed';
export const CI_APPROVED_FACILITIES_FILTER_API = '/cfr-service/application/lookupApprovedCiFacilitiesFilter';
export const CI_PROJECT_DETAILS = '/account-service/ciPathwayReport/get';
export const GET_CI_PATHWAY_REPORT_DETAILS_API = '/account-service/ciPathwayReport/get';
export const GET_CI_REPORT_DETAILS_API = '/account-service/application/get';
export const CI_PATHWAY_APPLICATION_SEARCH = '/account-service/ciPathwayReport/search';
export const CI_PATHWAY_COMPLIANCE_YEAR_LIST_API = '/cfr-service/lookup/complianceYearsForCIPathwayReport';
export const ACCOUNT_SERVICE_URL = '/account-service';
export const COMMON_SAVE_ACTION = '/save';
export const APPLICATION = '/application';
export const CI_PATHWAY_REPORT = '/ciPathwayReport';
export const SAVE_PREV_DOC_CI_PATHWAY = '/savePreviouslyUploadedDocuments';
export const COMMON_SUBMIT_ACTION = '/submit';
export const COMMON_PROPOSE_ACTION = '/propose';
export const CI_REP_DISCARD = '/account-service/ciPathwayReport/discard';
export const CI_PREV_DOCUMENT = '/document/getPreviouslyUploadedDocuments';
export const GET_CI_INVALID_REASONS_API = '/registry-server/lookup/appStaticData/CI_INVALID_REASON';
export const GET_CI_STATUS_LIST_API = '/account-service/lookup/ciStatus';
export const CI_PATHWAY_REPORT_REVIEW_API = '/account-service/ciPathwayReport/review';
export const CI_PATHWAY_REPORT_NEED_MORE_INFO_SUBMISSION_API = '/account-service/ciPathwayReport/submitResponse';
export const REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION_STATUS_CHANGE_API = '/account-service/application/reviewCiApplication';
export const REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION_STATUS_CHANGE_FOR_USER_API = '/account-service/application/submitResponseForReviewTransferCIInvalid';
export const CI_PATHWAY_RESUBMIT_GET_API = '/account-service/ciPathwayReport/getReportToResubmit/';
export const DOWNLOAD_CI_PATHWAY_REPORT_API = '/cfr-service/application/downloadCiPathwayReport/';
export const MARK_AS_INVALID_CI_PATHWAY_REPORT_API = '/account-service/application/markAsInvalid';
export const MARK_AS_ACTIVE_CI_PATHWAY_REPORT_API = '/account-service/application/markAsActive';
export const MARK_AS_UNDER_REVIEW_CI_PATHWAY_REPORT_API = '/account-service/application/markAsUnderReview';

export const CI_PATHWAY_REPORT_SUBMITTED_STATUS = 'SUBMITTED';
export const CI_PATHWAY_REPORT_RESUBMITTED_STATUS = 'RESUBMITTED';
export const CI_PATHWAY_REPORT_PENDING_VB_SELECTION_STATUS = 'PENDING_VB_SELECTION';
export const CI_PATHWAY_REPORT_SUBMITTED_TO_VB_STATUS = 'SUBMITTED_TO_VB';

@Injectable({
  providedIn: 'root'
})
export class CiPathwayReportService extends BaseStepperService {
  public stepperStepUpdate$ = new Subject();
  public translationPrefix = STEPS_TRANSLATION_PREFIX;
  private _applicationApprovalModel = new BehaviorSubject({});
  public applicationApprovalModel$ = this._applicationApprovalModel.asObservable();
  private _report = new BehaviorSubject<ICIPathwayReport>(null);
  stepRoutineMap: any = {};
  public registrationReportsDetail;
  public registrationReportData;
  public ciApplicationDataReceivedFromAPI$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _refreshData$ = new Subject<any>();
  public refreshData$ = this._refreshData$.asObservable();

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private storeService: StoreService,
    private router: Router,
    private helperService: HelperService,
  ) {
    super();
  }

  set report(report: ICIPathwayReport) {
    this._report.next(report);
  }

  get report(): ICIPathwayReport {
    return this._report.getValue();
  }


  get applicationApprovalModel(): any {
    return this.currentData;
  }

  set applicationApprovalModel(data) {
    this.currentData = data;
  }

  resetModel() {
    this._applicationApprovalModel.next({});
  }

  triggerAction(metadata: any, redirect = false) {
   // Do not remove this method this is getting used in CI-Pathway-Report-list component nestedly.
  }


  public createCarbonIntensityPathwayReport(payload) {
    return this.http.post<any>(`${environment.apiUrl}${CREATE_CARBON_INTENSITY_PATHWAY_REPORT_API}`, payload);
  }

  saveAsDraft(payload?: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${CI_APPLICATION_SAVE_PRODUCTION_LOW_FUELS}`, payload ? payload : this.applicationApprovalModel.data);
  }

  saveAndProceed(payload?: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${CI_APPLICATION_SAVE_AND_PROCEED}`, payload ? payload : this.applicationApprovalModel);
  }

  getCIInvalidReasons() {
    return this.http.get(`${environment.apiUrl}${GET_CI_INVALID_REASONS_API}`);
  }

  downloadCiPathwayReport(documentId: number) {
    const uri = environment.serviceUrl + DOWNLOAD_CI_PATHWAY_REPORT_API + documentId;
    this.http.get(uri, { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe(
        (response: any) => downloadBlob(response),
        () => this.http.get(uri, { observe: 'response' }).subscribe(),
      );
  }

  getCiStatusList() {
    return this.http.get(`${environment.apiUrl}${GET_CI_STATUS_LIST_API}`);
  }

  getResubmitCiPathwayReport(id: number) {
    return this.http.get(`${environment.apiUrl}${CI_PATHWAY_RESUBMIT_GET_API}${id}`);
  }



  public openConfirmationModal(msg, yesNoButton = false) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: msg,
          type: SereviceMessageType.WARNING,
          yesNoButton,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
  }

  public getProjectDetails(projectId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}${CI_PROJECT_DETAILS}/${projectId}`);
  }

  public getExistingPathwayReportDetail(reportId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}${GET_CI_PATHWAY_REPORT_DETAILS_API}/${reportId}`);
  }

  public getExistingCiDetail(reportId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}${GET_CI_REPORT_DETAILS_API}/${reportId}`);
  }

  public getComplianceYearForCiPathwayReport(): Observable<any> {
    return this.http.get(`${environment.apiUrl}${CI_PATHWAY_COMPLIANCE_YEAR_LIST_API}`);
  }

  getCIApplication(payload: ICIPathwayReportSearchPayload): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${CI_PATHWAY_APPLICATION_SEARCH}`, payload)
      .pipe(
        tap(response => this.registrationReportData = response),
        tap(response => this.registrationReportsDetail = response ? response.account : null),
      );
  }



  storeCurrentData() {
    const data = JSON.stringify({ ...this.currentData });
    sessionStorage.setItem(CI_DATA_KEY, data);
    const currentStep = JSON.stringify(this.currentStep);
   // sessionStorage.setItem(CI_CURRENT_STEP_KEY, currentStep);
  }

  restoreCurrentData(skipRemove = false): any {
    const data = sessionStorage.getItem(CI_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(CI_DATA_KEY);
      return JSON.parse(data);
    } else {
      return null;
    }
  }


  restoreLastStep(): any {
    const currentStep = sessionStorage.getItem(CI_CURRENT_STEP_KEY);
    if (currentStep) {
      sessionStorage.removeItem(CI_CURRENT_STEP_KEY);
      return JSON.parse(currentStep);
    } else {
      return null;
    }
  }


  getCiFacilitiesReportList(payload?: IApprovedCiFacilitiesListPayload) {
    const defaultPayload: IApprovedCiFacilitiesListPayload = {
      startDate: null,
      endDate: null,
      freeText: null,
      ciPathwayReport: true,
    } as IApprovedCiFacilitiesListPayload;

    const finalPayload = {
      ...defaultPayload,
      ...payload,
    };
    return this.http.post<any>(`${environment.apiUrl}${CI_APPROVED_FACILITIES_FILTER_API}`, finalPayload);
  }

  saveApplication(draft: boolean, payload: any, quiet = false): Observable<any> {
    const type = draft ? '' : 'AndProceed';
    payload.fullJson = null;
    if (parseFloat(this.stepNum) !== parseFloat(payload.step) && parseFloat(payload.step) > this.lastSavedStep) {
      payload.step = this.lastSavedStep.toString();
    }
    // if (!this.isDirty && !draft) {
    //   return of({entity: this.currentData})
    //     .pipe(tap(() => this.adminCanUpdate.next(false)));
    // } else {
    return this.http.post<any>(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${CI_PATHWAY_REPORT}${COMMON_SAVE_ACTION}${type}${quiet ? '?quiet=1' : ''}`, payload)
      .pipe(
        tap(() => this.adminCanUpdate.next(false)),
        tap(data => data.entity && data.entity.step && (this.lastSavedStep = data.entity.step)),
      );
    // }
  }

  reviewCiPathway(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}${CI_PATHWAY_REPORT_REVIEW_API}`, payload);
  }
  

  needMoreInfoSubmissionCiPathway(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}${CI_PATHWAY_REPORT_NEED_MORE_INFO_SUBMISSION_API}`, payload);
  }

  reviewCIApplication(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}${REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION_STATUS_CHANGE_API}`, payload);
  }

  reviewCIApplicationForUser(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}${REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION_STATUS_CHANGE_FOR_USER_API}`, payload);
  }

  isTransactionProposer(accountId){
    return accountId ? this.storeService.user.checkIfTransactionProposer(accountId) : false;
  }

  submitApplication(type: any, payload: any, form?) {
    payload.fullJson = null;
    const isTransactionProposer = this.storeService.user.checkIfTransactionProposer(payload.accountId);
    const message = isTransactionProposer ? 'proposeApplicationConfirmation' : 'confirmationMessageApplication';
    let userType = payload.type === 'CI_PATHWAY' ? 'ecccTeam' : 'validationBody';
    let appType = payload.type;
    if (payload.ciMethod === 'DEFAULT') {
      userType = 'verificationBody';
    }
    if (payload.type === 'CI_PRODUCTION_OF_LOW_CI_FUELS'
      || payload.type === 'CI_FUELING_STATIONS'
      || payload.type === 'CI_CHARGING_STATIONS') {
      appType = 'CI_APPLICATION';
    }
    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message,
          metaDataList: [this.translateService.instant(`COMMON.actionsLabel.submit`),
          this.translateService.instant(`COMMON.submissionTypeList.${appType}`),
          payload.complianceYear,
          this.translateService.instant(`COMMON.actionsLabel.${userType}`),
          ],
          type,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.submit(payload, isTransactionProposer)
            .subscribe(resp => {
              this.resetStepper();
              this.returnPreviousLocation();
            }, (error: HttpErrorResponse) => {
              // if (form) {
              //   this.formService.parseErrors(form, error.error);
              // }
            });
        }
      });
  }

  submit(payload: any, isTransactionProposer: boolean): Observable<any> {
    if (isTransactionProposer) {
      return this.proposeApplication(payload);
    }
    return this.http.post<any>(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${CI_PATHWAY_REPORT}${COMMON_SUBMIT_ACTION}`, payload);
  }

  proposeApplication(payload: any): Observable<any> {
    payload.fullJson = null;
    return this.http.post<any>(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${CI_PATHWAY_REPORT}${COMMON_PROPOSE_ACTION}`, payload);
  }

  returnPreviousLocation() {
    const prevPage = this.storeService.lastVisitedUrl || this.storeService.getHistoryUrl();
    if (prevPage) {
      this.router.navigate(this.helperService.getTranslatedPath(prevPage));
    } else {
      this.storeService.back();
    }
  }

  discard(payload) {
    return this.http.post<any>(`${environment.apiUrl}${CI_REP_DISCARD}`, payload);
  }

  executeAction(id, accountId, facilityName, action, redirect = false) {
    const request = { id: id, accountId: accountId, action: action };
    const confirmationMessage = 'confirmationMessageCIReport';
    const actionLabel = action;
    return this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: confirmationMessage,
        metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
          facilityName
          ,
        ],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    );
  }

  getPreviouslySelectedDocument(pagination: IApp.IPagination) {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    const complianceYear = this.currentData.complianceYear;//2025
    const organizationId = this.currentData.accountId; //4710098;
    const facilityId = this.currentData.ciApplicationDto.applicationOrgEntities[0].entity.id; //59143;
    const ciPathwayReportId = this.currentData.ciApplicationDto.id;
    return this.http.get(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${CI_PREV_DOCUMENT}/${organizationId}/${complianceYear}/${facilityId}/${ciPathwayReportId}`, { params });
 
  }

  savePreviouslySelectedDocument(previousEntityIds, documentIds)
  {
    let currentEntityId = this.currentData.id;
    return this.http.post<any>(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${CI_PATHWAY_REPORT}${SAVE_PREV_DOC_CI_PATHWAY}`,
       {currentEntityId, previousEntityIds, documentIds})
  }



}