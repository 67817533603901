import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-checkbox',
  template: `
    <div [ngClass]="{
      'form-check': to.formCheck.indexOf('custom') === -1,
      'form-check-inline': to.formCheck === 'inline',
      'custom-control custom-checkbox': to.formCheck.indexOf('custom') === 0,
      'custom-control-inline': to.formCheck === 'custom-inline'
    }">
      <input type="checkbox"
        #checkboxEl
        [class.is-invalid]="showError"
        [class.form-check-input]="to.formCheck.indexOf('custom') === -1"
        [class.custom-control-input]="to.formCheck.indexOf('custom') === 0"
        [indeterminate]="to.indeterminate && formControl.value === null"
        [formControl]="formControl"
        [tabindex]="tabIndex"
        [id]="elementId"
        [formlyAttributes]="field">
      <label [for]="elementId"
        *ngIf="to.label"
        [class.form-check-label]="to.formCheck.indexOf('custom') === -1"
        [class.custom-control-label]="to.formCheck.indexOf('custom') === 0"
      >
        {{ to.label }}<span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
      </label>
      <label [for]="elementId"
        *ngIf="!to.label"
        [class.form-check-label]="to.formCheck.indexOf('custom') === -1"
        [class.custom-control-label]="to.formCheck.indexOf('custom') === 0"
      >
       <span class="wcag-visuallyhidden">checkbox label</span>
      </label>
    </div>
  `,
})
export class FormlyFieldCheckboxExtra extends FieldType implements OnInit, AfterViewInit {

  @ViewChild('checkboxEl', { static: false }) checkboxEl: ElementRef;

  defaultOptions = {
    templateOptions: {
      indeterminate: false,
      hideLabel: true,
      dummyLabel: false,
      formCheck: 'custom', // 'custom' | 'stacked' | 'inline' | 'custom-inline'
    },
  };

  ngOnInit() {
    if (this.field.defaultValue === 'undefined') {
      this.field.defaultValue = false;
    }
  }

  ngAfterViewInit() {
    if (!this.to.skipFirstChange && this.to.change) {
      setTimeout(() => {
        if (!this.form.disabled) {
          const _event = new Event('change');
          _event['initial'] = true;
          this.checkboxEl.nativeElement.dispatchEvent(_event);
        }
      });
    }
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }

  public get tabIndex(): number {
    return this.to.tabindex || this.field.hide ? -1 : 0;
  }
}
