/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-milestones.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../../node_modules/primeng/inputswitch/primeng-inputswitch.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "primeng/inputswitch";
import * as i7 from "./project-milestones.component";
import * as i8 from "../../project-milestone.service";
var styles_ProjectMilestonesComponent = [i0.styles];
var RenderType_ProjectMilestonesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectMilestonesComponent, data: {} });
export { RenderType_ProjectMilestonesComponent as RenderType_ProjectMilestonesComponent };
function View_ProjectMilestonesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "milestone"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "milestone-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showCompleted ? _v.context.$implicit.completionDate : _v.context.$implicit.deadLineDateStr); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(("COMMON.submissionTypeList." + ((_v.context.$implicit == null) ? null : _v.context.$implicit.deadLineType)))); _ck(_v, 4, 0, currVal_1); }); }
function View_ProjectMilestonesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectMilestonesComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.milestones; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProjectMilestonesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center mb-3 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PROJECTS_MODULE.projectMilestones.noUpcomingMilestones")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ProjectMilestonesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mt-3 border-bottom d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "d-flex mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p-inputSwitch", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_InputSwitch_0, i4.RenderType_InputSwitch)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.InputSwitch]), i1.ɵdid(7, 49152, null, 0, i6.InputSwitch, [i1.ChangeDetectorRef], { inputId: [0, "inputId"] }, { onChange: "onChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "label", [["class", "ml-2"], ["for", "mode"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "milestones mt-3 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectMilestonesComponent_1)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NO_MILESTONES", 2]], null, 0, null, View_ProjectMilestonesComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "mode"; _ck(_v, 7, 0, currVal_1); var currVal_3 = (_co.milestones && _co.milestones.length); var currVal_4 = i1.ɵnov(_v, 14); _ck(_v, 13, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PROJECTS_MODULE.projectMilestones.title")); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("PROJECTS_MODULE.projectMilestones.showCompleted")); _ck(_v, 9, 0, currVal_2); }); }
export function View_ProjectMilestonesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-milestones", [], null, null, null, View_ProjectMilestonesComponent_0, RenderType_ProjectMilestonesComponent)), i1.ɵdid(1, 114688, null, 0, i7.ProjectMilestonesComponent, [i8.ProjectMilestoneService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectMilestonesComponentNgFactory = i1.ɵccf("app-project-milestones", i7.ProjectMilestonesComponent, View_ProjectMilestonesComponent_Host_0, { projectId: "projectId" }, {}, []);
export { ProjectMilestonesComponentNgFactory as ProjectMilestonesComponentNgFactory };
