import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormlyFieldInput } from '@ngx-formly/bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'formly-field-input',
  template: `
    <label *ngIf="!to.label" [for]="elementId" class="wcag-visuallyhidden">
      <ng-container *ngIf="to.placeholder; else EMPTY_LABEL">{{ to.placeholder }}</ng-container>
      <ng-template #EMPTY_LABEL>Input field</ng-template>
    </label>
    <input
      *ngIf="to.type !== 'number' && to.type !== 'formatted-number' && to.type !== 'integer-number'; else numberTmp"
      #inputEl
      [class.is-invalid]="showError"
      [disable-with-validation]="disabledField"
      [disabled]="isFormDisabled"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [id]="elementId"
      [tabindex]="tabIndex"
      [type]="type"
      (change)="onChange($event)"
      class="form-control"
      [attr.aria-labelledby]="elementId"
    />
    <ng-template #numberTmp>
      <ng-container *ngIf="to.type == 'formatted-number'">
        <input
          #inputEl
          [allowNegativeNumbers]="to.allowNegativeNumbers"
          [class.is-invalid]="showError"
          [disable-with-validation]="disabledField"
          [disabled]="isFormDisabled"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [maxlength]="!to.skipLengthValidation ? 19 : 100"
          [id]="elementId"
          [mask]="formattedNumberMask"
          [thousandSeparator]="commaSeparator"
          [tabindex]="tabIndex"
          (change)="onChange($event)"
          class="form-control"
          type="text"
        />
      </ng-container>

      <ng-container *ngIf="to.type == 'integer-number'">
        <input
          #inputEl
          step="1"
          [class.hide-arrows]="hideArrows"
          [class.is-invalid]="showError"
          [disable-with-validation]="disabledField"
          [disabled]="isFormDisabled"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [id]="elementId"
          [tabindex]="tabIndex"
          (change)="onChange($event)"
          (keypress)="forceInteger($event)"
          (paste)="prevent($event)"
          (wheel)="handleWheel($event, to.min, to.max)"
          class="form-control"
          type="number"
        />
      </ng-container>

      <ng-container *ngIf="to.type == 'number'">
        <input
          #inputEl
          [class.hide-arrows]="hideArrows"
          [class.is-invalid]="showError"
          [disable-with-validation]="disabledField"
          [disabled]="isFormDisabled"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [id]="elementId"
          [tabindex]="tabIndex"
          (change)="onChange($event)"
          (wheel)="prevent($event)"
          class="form-control"
          type="number"
        />
      </ng-container>
    </ng-template>
  `,
})
export class FormlyFieldInputExtra extends FormlyFieldInput implements OnInit {
  disabledField = false;
  localFormDisabled = false;

  @ViewChild('inputEl', {static: false}) inputEl: ElementRef;

  constructor(private translate: TranslateService) {
    super();
  }

  get commaSeparator() {
    return this.translate.currentLang === 'en' ? ',' : ' ';
  }

  get formattedNumberMask(): string {
    return this.to.mask || 'separator.0';
  }

  ngOnInit() {
    this.localFormDisabled = this.to.formDisabled || this.to.disabled;
    if (this.form.enabled) {
      const formControlDisableFn = this.formControl.disable;
      const formControlEnableFn = this.formControl.enable;
      this.formControl.disable = opts => {
        if (this.to.ignoreLocalFormDisabled) {
          formControlDisableFn.apply(this.formControl, arguments);
        }
        this.disabledField = true;
      };

      this.formControl.enable = opts => {
        if (this.to.ignoreLocalFormDisabled) {
          formControlEnableFn.apply(this.formControl, arguments);
        }
        this.disabledField = false;
      };

      this.disabledField = this.isFormDisabled();
    } else {
      this.disabledField = true;
    }

    this.initDefaultValueSub();
  }

  isFormDisabled(): boolean {
    return this.form.disabled === true || this.localFormDisabled;
  }

  get hideArrows(): boolean {
    return this.to.hideArrows;
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }
      if (!prefix && this.field.parent.parent && this.field.parent.parent.type === 'form-array') {
        prefix = this.field.parent['id'];
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    }
    return this.field.id;
  }

  onChange($event: Event): void {
    if (typeof this.formControl.value === 'string') {
      setTimeout(() => this.formControl.setValue(this.formControl.value.trim()));
    }

    if (this.to.syncFromModel && this.model[this.key]) {
      this.inputEl.nativeElement.value = this.model[this.key];
    }
    if (this.to.allowNegativeNumbers === false) {
      this.formControl.setValue(Math.abs(this.formControl.value));
    }
    $event.preventDefault();
    if (this.to.onChange) {
      this.to.onChange($event, this.form);
    }
  }

  public forceInteger(event) {
    if (event.key !== 'Enter' && !/[0-9]/.test(event.key)) {
      this.prevent(event);
    }
  }

  public prevent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  public handleWheel(event, min = null, max = null) {
    if (min && min > event.target.valueAsNumber) {
      this.prevent(event);
    }

    if (max && max < event.target.valueAsNumber) {
      this.prevent(event);
    }
  }

  private initDefaultValueSub(): void {
    const { defaultValue } = this.to;

    if (typeof defaultValue !== 'string' && typeof defaultValue !== 'number') {
      return;
    }

    const value = typeof defaultValue === 'number' ? defaultValue.toString() : defaultValue;

    this.formControl.setValue(value);
  }

  public get tabIndex(): number {
    return this.to.tabindex || (this.disabledField || this.field.hide) ? -1 : 0;
  }
}
