import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgModel, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CIPathwayApplyPrefix, CI_INFO_ENERGY_TABLE_13, CI_INFO_ENERGY_TABLE_CONFIG_13, CI_INFO_ENERGY_TABLE_CONFIG_30_65, CI_PATHWAY_ADD_DETAIL_PREFIX, CI_PATHWAY_REPORT_SECTION1, CI_PATHWAY_REPORT_SECTION2, CI_PATHWAY_REPORT_SECTION3, getStaticText } from '@module/ci-pathway-report/ci-pathway-report.const';
import { CI_PATHWAY_REPORT_SUBMITTED_STATUS, CiPathwayReportService } from '@module/ci-pathway-report/ci-pathway-report.service';
import { IDocument } from '@module/registration/registration.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { Dialog } from 'primeng';
import { ViewFacilityDetailsComponent } from '../view-facility-details/view-facility-details.component';
import { IApp } from '@core/models/app.interfaces';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LookupService } from '@core/services/lookup.service';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { IRawFormBase } from '@core/models/raw-form.types';
import { CI_IFNO_ENTITY_LIST, } from '@module/submission-management/const/ci-info.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { CARApplyPrefix } from '@module/credit-adjustment-report/const/credit-adjustment-report.const';


@Component({
  selector: 'app-add-details-ci-path-report',
  templateUrl: './add-details-ci-path-report.component.html',
  styleUrls: ['./add-details-ci-path-report.component.scss']
})
export class AddDetailsCiPathReportComponent extends BaseStepperStepComponent implements OnInit {

  @ViewChild('leftCol', { static: true }) leftCol: ElementRef;
  @ViewChild('rightCol', { static: true }) rightCol: ElementRef;
  @ViewChild('dialog', { static: false }) dialog: Dialog;
  @ViewChild('rightInput', { static: true }) rightInput: ElementRef<HTMLInputElement>;
  @ViewChild('leftInput', { static: true }) leftInput: ElementRef<HTMLInputElement>;

  public visible = true;
  public currentStepObj;
  public documents: IDocument[];
  sectionFormLeft = new FormGroup({});
  sectionFormRight = new FormGroup({});
  model: any;
  section1FieldList: FormlyFieldConfig[];
  section2FieldList: FormlyFieldConfig[];
  section3FieldList: FormlyFieldConfig[];
  section4FieldList: FormlyFieldConfig[];
  section5FieldList: FormlyFieldConfig[];
  feedStockList: IApp.ILookup[];
  originalFeedStockList: IApp.ILookup[];
  prodAndTrans: IApp.ILookup[];
  compLiq: IApp.ILookup[];
  electricity: IApp.ILookup[];
  additionalTransPort: IApp.ILookup[];
  combustionUse: IApp.ILookup[];
  ciEngSrc: IApp.ILookup[];
  dummy = [{}];
  enableFieldForEdit = false;
  energyTableConfig: any;
  energyTableFields: any[];
  actionList: any[];
  tableData: any[];
  tableDataRight: any[];
  showTranport: boolean = true;
  feedStockArrayValue = [];

  section4: IRawFormBase[] = [
    {
      groupFields: false,
      fieldGroup: [
        {
          type: 'checkbox',
          className: 'col-12',
          key: 'ciEdited',
          label: CIPathwayApplyPrefix('checkboxToEditCIValues', CI_PATHWAY_ADD_DETAIL_PREFIX),
          disabledExpression: '((model.status === null) || (model.status === "IN_PROCESS") || (model.status === "PROPOSED"))? false: true',
          id: `ciEdited`,
          change: (field, $event) => this.enablingFields($event),
        },
        {
          type: 'input',
          className: 'col-12',
          key: 'actualCarbonIntensity',
          label: CIPathwayApplyPrefix('approvedCIUnderSection85', CI_PATHWAY_ADD_DETAIL_PREFIX),
          required: true,
          disabledExpression: '((model.status === null) || (model.status === "IN_PROCESS") || (model.status === "PROPOSED"))? false: true',
          customTemplateOptions: {
            type: 'formatted-number',
            //mask: 'separator.2',
            allowNegativeNumbers: false,
          },
          validators: ['positiveOrZeroNumber'],

          id: 'actualCarbonIntensity',
        },
        {
          type: 'input',
          className: 'col-12',
          key: 'instrumentId',
          label: CIPathwayApplyPrefix('approvedAlphanumeric', CI_PATHWAY_ADD_DETAIL_PREFIX),
          // disabledExpression: () => !this.fieldsEditable(),
          disabled: true,
          id: 'instrumentId',
        },
        getStaticText(CIPathwayApplyPrefix('SubHeadingForPathwayReport', CI_PATHWAY_ADD_DETAIL_PREFIX), 'col-12'),
      ],
    },
  ];

  section5: IRawFormBase[] = [
    {
      groupFields: false,
      fieldGroup: [
        {
          className: "rationable",
          type: 'textarea',
          key: 'rationable',
          id: 'rationable',
          label: CIPathwayApplyPrefix('differenceBtwnApprovedActual', CI_PATHWAY_ADD_DETAIL_PREFIX),
          requiredExpression: () => this.checkActualAndApprovedCI(),
          translate: true,
          disabledExpression: '((model.status === null) || (model.status === "IN_PROCESS") || (model.status === "PROPOSED"))? false: true',
          hideExpression: () => !this.fieldsEditable() && !this.model.ciEdited,
          templateOptions: {
            rows: 4,
          }
        }]
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddDetailsCiPathReportComponent>,
    private uploadDocumentService: UploadDocumentService,
    public service: CiPathwayReportService,
    private formFactoryService: FormFactoryService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private lookupService: LookupService,
    public _el: ElementRef,
  ) {
    super();
    this.pageEl = _el;
  }

  isReportSubmitted(): boolean {
    if (this.model && this.model.status && this.model.status === CI_PATHWAY_REPORT_SUBMITTED_STATUS)
      return true;
    return false;
  }

  reportstatus()
  {
    if(this.model && this.model.status)
    {
      if(this.model.status === null || this.model.status === "IN_PROCESS" || this.model.status === "PROPOSED")
        return false;
      else 
        return true;
    }
  }

  get isCurrentLangEnglish() {
    return this.translateService.currentLang === 'en' ? true : false;
  }

  ngOnInit() {
    this.model = this.data.currentStepObj;
    if (this.model && this.model.ciApplicationDto && (this.model.ciEdited === false || this.model.ciEdited === null)) {
      if (this.model.approvedCarbonIntensity) {
        this.model.actualCarbonIntensity = this.model.approvedCarbonIntensity;
      }
    }
    if (this.model && this.model.ciApplicationDto &&
      this.model.ciApplicationDto.distanceProductionToDistribution === null
      && this.model.ciApplicationDto.distanceSourceToProduction === null
      && this.model.ciApplicationDto.totalDistance === null)
      this.showTranport = false;

    console.log("this.model", this.model);
    if(this.model && this.model.ciApplicationDto && this.model.ciApplicationDto.fuel)
      {
      if(this.isCurrentLangEnglish)
        this.model.ciApplicationDto.fuel.name = this.model.ciApplicationDto.fuel.en;
      else
        this.model.ciApplicationDto.fuel.name = this.model.ciApplicationDto.fuel.fr;
      }
    this.initLookUp();
    if (this.model.lifecycleFeedstock === null)
      this.model.lifecycleFeedstock = { id: null };
    if(this.model.ciApplicationDto.stageProductionValue === 13)
      this.energyTableConfig = CI_INFO_ENERGY_TABLE_CONFIG_13;
    else 
    this.energyTableConfig = CI_INFO_ENERGY_TABLE_CONFIG_30_65;
    this.setCiEnergySourceTable();
    if(this.model && this.model.ciApplicationDto && this.model.ciApplicationDto.feedStock)
      {
        this.feedStockArrayValue = [this.model.ciApplicationDto.feedStock];
        CI_PATHWAY_REPORT_SECTION2[0].fieldGroup[0]['items'] = this.feedStockArrayValue;
      }
    this.section1FieldList = this.formFactoryService.configureForm(CI_PATHWAY_REPORT_SECTION1);
    this.section2FieldList = this.formFactoryService.configureForm(CI_PATHWAY_REPORT_SECTION2);
    this.section3FieldList = this.formFactoryService.configureForm(CI_PATHWAY_REPORT_SECTION3);
    this.section4FieldList = this.formFactoryService.configureForm(this.section4);
    this.isReportSubmitted() && this.section5[0].fieldGroup.forEach((field) => { delete field.hideExpression; }); // using this hack because the logic of hideExpression is removing the field from the UI when we disable the sectionFormRight form
    this.section5FieldList = this.formFactoryService.configureForm(this.section5);

    this.energyTableFields = CI_INFO_ENERGY_TABLE_13;
    this.actionList = CI_IFNO_ENTITY_LIST;
    setTimeout(() => {
      this.sectionFormRight.get('actualCarbonIntensity').disable();
      if (this.isReportSubmitted()) { this.sectionFormRight.disable(); }
    });

  }

  public onKeyDown(event) {
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();

    }
  }

  checkActualAndApprovedCI() {
    this.model.actualCarbonIntensity = +this.model.actualCarbonIntensity
    if (this.model.actualCarbonIntensity === this.model.approvedCarbonIntensity) {
      return false;
    }
    else {
      return true;
    }
  }

  calculateTotalDistance(event) {
    if (
      !isNaN(this.model.distanceSourceToProduction) &&
      !isNaN(this.model.distanceProductionToDistribution)
    ) {

      this.model.totalDistance =
        Number(this.model.distanceSourceToProduction) +
        Number(this.model.distanceProductionToDistribution)
    }

    this.checkTotalDistance();
  }

  checkTotalDistance() {
    if (this.model.totalDistance > 1500) {
      this.showErrorMessage('ci_info_distance');
      return;
    }
  }

  showErrorMessage(message: string) {
    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message: message,
            },
          ],
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
        }
      });
  }

  setCiEnergySourceTable() {
    let list = [];
    let list1 = [];
    if (this.model.ciApplicationDto['ciEnergySources'] !== null) {
      if(this.model.ciApplicationDto.stageProductionValue === 13){
      this.model.ciApplicationDto['ciEnergySources'].forEach(element => {
        list.push(
          {
            id: element.entity.id,
            quantity: element.quantity,
            name: element.entity.name,
            code: element.entity.code,
            // entityActionList: this.actionList
          });
      });
      this.tableData = list;
    }
    else{
      this.model.ciApplicationDto['ciEnergySources'].forEach(element => {
        list.push(
          {
            id: element.entity.id,
            name: element.entity.name,
            code: element.entity.code,
          });
      });
      this.tableData = list;

    }
    this.tableData.forEach(item => {
      item.name = this.translateService.instant(`SUBMISSION_MANAGEMENT_MODULE.ci_info.${item.code}`)
      });
      if (this.model['ciEnergySources'].length === 0)
        this.model['ciEnergySources'] = this.model.ciApplicationDto['ciEnergySources']
    }
    if(this.model.ciApplicationDto.stageProductionValue === 13){
    this.model['ciEnergySources'].forEach(element => {
      list1.push(
        {
          id: element.entity.id,
          quantity: element.quantity,
          name: element.entity.name,
          code: element.entity.code,
          // entityActionList: this.actionList
        });
    });
    this.tableDataRight = list1;
  }
  else{
    this.model['ciEnergySources'].forEach(element => {
      list1.push(
        {
          id: element.entity.id,
          name: element.entity.name,
          code: element.entity.code,
        });
    });
    this.tableDataRight = list1;
  }
  this.tableDataRight.forEach(item => {
    item.name = this.translateService.instant(`SUBMISSION_MANAGEMENT_MODULE.ci_info.${item.code}`)
    });
  }

  onInputChange(index: number, name, id, newValue: string): void {
    this.model.ciEnergySources[index].quantity = newValue;
    //this.tableData[index].quantity = newValue;
    this.getTotalEnergy();
    this.calculateTotalLowCiSources();
    this.calculateTotalLowCiPercentage();
  }

  checkEditableTableMandatory(): boolean {
    return this.model.ciEnergySources.every(item => item.quantity != null);
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  calculateTotalLowCiSources() {
    this.model.totalLowCiSources = 0;
    this.model['ciEnergySources'].forEach(element => {
      if(this.getCiEngerySrcById(element.entity.id).value === "low-ci" ){
        this.model.totalLowCiSources = isNaN(element.quantity) ?  (Number(this.model.totalLowCiSources)).toFixed(2) : (Number(this.model.totalLowCiSources) + Number(element.quantity)).toFixed(2);
      }
    });
  }

  getCiEngerySrcById(id) {
    let ciEngerg;
    for(var i=0 ; this.ciEngSrc.length>i; i++){
      if(this.ciEngSrc[i].id === id) {
        ciEngerg = this.ciEngSrc[i];
        return ciEngerg;
      }
    }
    return ciEngerg;
  }

  getTotalEnergy()
  {
    this.model.totalEnergy = 0;
    this.model['ciEnergySources'].forEach(element => {
      this.model.totalEnergy = isNaN(element.quantity) ? this.model.totalEnergy:  this.model.totalEnergy + element.quantity;
    });
    this.model.totalEnergy = this.model.totalEnergy.toFixed(2);
  }

  calculateTotalLowCiPercentage() {
    this.model.totalLowCiPercentage = 0;
    if(this.model.totalEnergy > 0) {
      this.model.totalLowCiPercentage = ((this.model.totalLowCiSources/this.model.totalEnergy )*100).toFixed(2);
    }
  }

  public close(): void {
    if (this.sectionFormRight.touched) {
      const sub$ = this.service.openConfirmationModal('confirmOnClose').subscribe(result => {
        sub$.unsubscribe();
        if (result) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  formValidate(form: FormGroup) {
    if (form.valid || form.disabled) {
      if (this.showTranport && this.model.distanceSourceToProduction !== null && this.model.distanceProductionToDistribution !== null) {
        if (this.checkEditableTableMandatory())
          return true;
      }
      else if (!this.showTranport)
        if (this.checkEditableTableMandatory())
          return true;
    }
    else {
      return false;
    }
  }


  public save() {
    this.model.totalEnergy = +this.model.totalEnergy;
    this.model.totalLowCiSources = +this.model.totalLowCiSources;
    this.model.totalLowCiPercentage = +this.model.totalLowCiPercentage;
    this.formFactoryService.markFormGroupDirty(this.sectionFormRight);
    if (this.formValidate(this.sectionFormRight)) {
      if (this.model.actualCarbonIntensity) {
        this.model.ciApplicationDto.actualCarbonIntensity = +this.model.actualCarbonIntensity;
      }
      this.dialogRef.close(this.model);
    }
  }

  public viewFacilityDetails(event) {
    event && event.preventDefault();
    const data = this.service.currentData.ciApplicationDto;
    let entityData;
    if(this.service.currentData.facilityId === this.service.currentData.ciApplicationDto.applicationOrgEntities[0].id)
      {
        entityData = (data.applicationOrgEntities && data.applicationOrgEntities.length) && data.applicationOrgEntities[0];
      }
      else
      {
       entityData = (data.applicationOrgEntities && data.applicationOrgEntities.length) && data.applicationOrgEntities[0].entity;
      }
    //let entityData = (data.applicationOrgEntities && data.applicationOrgEntities.length) && data.applicationOrgEntities[0].entity;    
    if(!entityData.name || (entityData.name !== this.model.facilityName)){
      entityData = (data.applicationOrgEntities && data.applicationOrgEntities.length) && data.applicationOrgEntities[0];
    }
    this.modalService.open(ViewFacilityDetailsComponent, {
      facility: entityData,
      tooltip: CARApplyPrefix('step1_tooltip', '.tooltip')
    }, true).afterClosed();
  }

  initLookUp() {
    this.lookupService.getLifecycleFeedstock().pipe(
      takeUntil(this._destroy$),
    ).subscribe(resp => {
      this.originalFeedStockList = resp;
      this.feedStockList =
        resp.map(option => ({
          ...option,
          name: this.translateService.instant(`COMMON.lifecyclefeedstockList.${option.name}`)
        }),
        );
    });

    this.lookupService.getStageProductionTransportation().pipe(
      takeUntil(this._destroy$),
    ).subscribe(resp => {
      this.prodAndTrans = resp.sort((a, b) => (a.name > b.name) ? 1 : -1);
    });

    this.lookupService.getStageCompressionLiquid().pipe(
      takeUntil(this._destroy$),
    ).subscribe(resp => {
      this.compLiq = resp.sort((a, b) => (a.name > b.name) ? 1 : -1);
    });

    this.lookupService.getStageElectricity().pipe(
      takeUntil(this._destroy$),
    ).subscribe(resp => {
      this.electricity = resp.sort((a, b) => (a.name > b.name) ? 1 : -1);
    });

    this.lookupService.getStageAdditionalTransportation().pipe(
      takeUntil(this._destroy$),
    ).subscribe(resp => {
      this.additionalTransPort = resp.sort((a, b) => (a.name > b.name) ? 1 : -1);
    });

    this.lookupService.getStageCombustion().pipe(
      takeUntil(this._destroy$),
    ).subscribe(resp => {
      this.combustionUse = resp.sort((a, b) => (a.name > b.name) ? 1 : -1);
    });

    this.lookupService.getTypeOfEnergy().pipe(
      takeUntil(this._destroy$),
    ).subscribe(resp => {
      this.ciEngSrc = resp;
    });
  }

  isCi_p_13() {
    return Number(this.model.stageProductionValue) === 13;
  }



  enablingFields(event) {
    if (event.target.checked) {
      this.enableFieldForEdit = true;
      this.model.ciEdited = true;
      const ciIdFieldControl = this.sectionFormRight && this.sectionFormRight.get('actualCarbonIntensity');
      ciIdFieldControl.enable();

    }
    else {
      this.enableFieldForEdit = false;
      this.model.ciEdited = false;
      this.sectionFormRight.get('actualCarbonIntensity').disable();
      this.sectionFormRight.controls['actualCarbonIntensity'].setValue(this.model.approvedCarbonIntensity);
      let listForRight = []
      if(this.model.ciApplicationDto.stageProductionValue === 13){
      this.model.ciApplicationDto['ciEnergySources'].forEach(element => {
        listForRight.push(
          {
            id: element.entity.id,
            quantity: element.quantity,
            name: element.entity.name,
          });
      });
      this.tableDataRight = listForRight;
    }
    else{
      this.model.ciApplicationDto['ciEnergySources'].forEach(element => {
        listForRight.push(
          {
            id: element.entity.id,
            name: element.entity.name,
          });
      });
      this.tableDataRight = listForRight;
    }
      this.model.distanceSourceToProduction = this.model.ciApplicationDto.distanceSourceToProduction;
      this.model.distanceProductionToDistribution = this.model.ciApplicationDto.distanceProductionToDistribution;
      this.model.totalDistance = this.model.ciApplicationDto.totalDistance;
    }
  }

  fieldsEditable() {
    return this.enableFieldForEdit;
  }

  modelStatus(){
    if((  (this.model.status === null) || (this.model.status === "IN_PROCESS")  ||  (this.model.status === "PROPOSED")))
       return false
    else 
       return true;
  }

}
