import { Component, Input, OnInit } from '@angular/core';
import { IPInputSwitchOnChange } from '@module/submission-management/components/submission-project-list/submission-project-list.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProjectMilestoneService } from '@shared/components/project/components/project-milestones/project-milestone.service';
import { IProjectMilestone } from '@shared/components/project/components/project-milestones/project-milestone.types';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-project-milestones',
  templateUrl: './project-milestones.component.html',
  styleUrls: ['./project-milestones.component.scss'],
})
export class ProjectMilestonesComponent implements OnInit {

  @Input() projectId: number;

  _milestones: IProjectMilestone[];
  showCompleted = false;

  constructor(
    private service: ProjectMilestoneService,
  ) { }

  ngOnInit() {
    this.service.getMilestones(this.projectId)
      .pipe(untilDestroyed(this))
      .subscribe(data => this._milestones = data);
  }

  get milestones(): IProjectMilestone[] {
    if (this.showCompleted) {
      return this._milestones.filter(m => moment(m.completionDate).isBefore(moment())) || [];
    }

    else {
      return this._milestones.filter(item => !item.completionDate);
    }
  }

  toggle(event: IPInputSwitchOnChange): void {
    this.showCompleted = event.checked;
  }

}
