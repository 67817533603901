import { IRawFormBase } from '@core/models/raw-form.types';
import {
  LOOKUP_APPLICATION_CI_STATUS,
  LOOKUP_APPLICATION_MONTHS_DATA,
  LOOKUP_CI_METHOD, LOOKUP_FUEL_SUBJECT,
  LOOKUP_PATHWAY,
} from '@core/services/lookup.service';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { ITableViewConfig } from '@shared/models/table-view.model';

export const APPLICATION_APPROVAL_CARBON_INTENSITY = {
  BASIC_INFO: 'BasicInfoComponent',
  FACILITY_OR_EQUIPMENT: 'FacilitieOrEquipmentsComponent',
  FACILITY_CI_INFO: 'FacilityCiInfoComponent',
  CI_PATHWAY_FUEL_IMPORT: 'CiPathwayFuelImportComponent',
  REVIEW_INFO_AUTH_OFFICIAL: 'ReviewInfoAuthOfficialComponent',
  UPLOAD_DOCUMNETS: 'PathwayInformationComponent',
  CONTACT_PERSON_INFO: 'ContactPersonInfoComponent',
  CO_INFO: 'CiInfoComponent',
  CHARGING_STATIONS: 'CiChargingStationsComponent',
};

export const CANADA_ONLY_COUNTRY_LIST = [{
  id: 'CA',
  name: 'CA',
}];

export const APPLICATION_CI_PRODUCTION_OF_LOW_CI_FUELS = {
  page_title: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.mainStepTitle',

  steps: [
    {
      id: 1,
      name: 'BASIC_INFO',
      completed: false,
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.BASIC_INFO, //.CO_INFO,
    },
    {
      id: 2,
      name: 'FACILITY_OR_EQUIPMENT',
      completed: false,
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_OR_EQUIPMENT,
      inputData: {
        ciApplication: true,
        step: '2',
      },
    },
    {
      id: 3,
      name: 'FACILITY_CI_INFO',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_CI_INFO,
    },
    {
      id: 4,
      name: 'UPLOAD_CI_DOCUMENTS',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.UPLOAD_DOCUMNETS,
      inputData: {
        documentType: 'ci',
      },
    },
    {
      id: 5,
      name: 'CONTACT_PERSON_INFO',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.CONTACT_PERSON_INFO,
      inputData: {
        step: '100',
      },
    },
  ],

  buttons: {
    showSave: true,
    showSubmit: true,
    submitPrefix: 'submitRegistrationReportButton',
  },

  // progress_bar_config: [
  //     {
  //         id: 1,
  //         name: "Basic Information",
  //         completed: false,
  //         hidden:false
  //     },
  //     {
  //         id: 2,
  //         name: "Facilities / Equipments Information",
  //         completed: false,
  //         hidden:false
  //     },
  //     {
  //         id: 3,
  //         name: "Verification / Valivdation Body Information",
  //         completed: false,
  //         hidden:false
  //     },
  //     {
  //         id: 4,
  //         name: "Enter Carbon Intensity Information",
  //         completed: false,
  //         hidden:false
  //     },
  //     {
  //         id: 5,
  //         name: "Upload Carbon Intensity Pathway Documents",
  //         completed: false,
  //         hidden:false
  //     },
  //     {
  //         id: 6,
  //         // name: "Review Information for Authorized Official",
  //         name: "Information of contact person",
  //         completed: false,
  //         hidden:false
  //     },
  // ],
  //
  // steps: [
  //     {
  //         id:0,
  //         type: APPLICATION_APPROVAL_CARBON_INTENSITY.BASIC_INFO,
  //         step_title: null,
  //         page_sub_title: null,
  //         before_next: true,
  //         next: false,
  //         back: false,
  //         close: true,
  //         saveAsDraft: true,
  //         updateStep: true,
  //         inputData: null
  //     },
  //     {
  //         id:1,
  //         type: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_OR_EQUIPMENT,
  //         step_title: null,
  //         page_sub_title: null,
  //         before_next: true,
  //         next: false,
  //         back: true,
  //         close: true,
  //         saveAsDraft: true,
  //         updateStep: false,
  //         inputData: {
  //           ciApplication: true,
  //           step: "2"
  //         }
  //     },
  //     {
  //         id:2,
  //         type: APPLICATION_APPROVAL_CARBON_INTENSITY.VERIFY_VALIDATION_BODY,
  //         step_title: null,
  //         page_sub_title: null,
  //         before_next: true,
  //         next: false,
  //         back: true,
  //         close: true,
  //         saveAsDraft: true,
  //         updateStep: false,
  //         inputData: {
  //           dropdownLabel: '.dropdownField',
  //           step: "3"
  //         }
  //     },
  //     {
  //         id:3,
  //         type: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_CI_INFO,
  //         step_title: null,
  //         page_sub_title: null,
  //         before_next: true,
  //         next: false,
  //         back: true,
  //         close: true,
  //         saveAsDraft: true,
  //         updateStep: false,
  //         inputData: null
  //     },
  //     {
  //         id:4,
  //         // type: APPLICATION_APPROVAL_CARBON_INTENSITY.CI_PATHWAY_FUEL_IMPORT,
  //         type: APPLICATION_APPROVAL_CARBON_INTENSITY.UPLOAD_DOCUMNETS,
  //         step_title: null,
  //         page_sub_title: null,
  //         before_next: true,
  //         next: false,
  //         back: true,
  //         close: true,
  //         saveAsDraft: true,
  //         updateStep: false,
  //         inputData: {
  //           documentType: 'ci'
  //         }
  //     },
  //     {
  //         id:5,
  //         // type: APPLICATION_APPROVAL_CARBON_INTENSITY.REVIEW_INFO_AUTH_OFFICIAL,
  //         type: APPLICATION_APPROVAL_CARBON_INTENSITY.CONTACT_PERSON_INFO,
  //         step_title: null,
  //         page_sub_title: null,
  //         before_next: true,
  //         next: false,
  //         back: true,
  //         close: true,
  //         saveAsDraft: true,
  //         updateStep: false,
  //         inputData: {
  //           step: "100"
  //         }
  //     },
  // ]
};

export const APPLICATION_CI_CHARGING_STATIONS = {
  page_title: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.mainStepTitle',

  steps: [
    {
      id: 1,
      name: 'BASIC_INFO',
      completed: false,
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.BASIC_INFO,
    },
    {
      id: 2,
      name: 'ADD_CHARGING_STATION',
      completed: false,
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.CHARGING_STATIONS,
      inputData: {
        ciApplication: true,
        step: '2',
      },
    },
    {
      id: 3,
      name: 'FACILITY_CI_INFO',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_CI_INFO,
    },
    {
      id: 4,
      name: 'UPLOAD_CI_DOCUMENTS',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.UPLOAD_DOCUMNETS,
      inputData: {
        documentType: 'ci',
      },
    },
    {
      id: 5,
      name: 'CONTACT_PERSON_INFO',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.CONTACT_PERSON_INFO,
      inputData: {
        step: '100',
      },
    },
  ],

  buttons: {
    showSave: true,
    showSubmit: true,
    submitPrefix: 'submitRegistrationReportButton',
  },
};

export const APPLICATION_CI_FUELING_STATIONS = {
  page_title: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.mainStepTitle',

  steps: [
    {
      id: 1,
      name: 'BASIC_INFO',
      completed: false,
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.BASIC_INFO,
    },
    {
      id: 2,
      name: 'FUELING_STATION',
      completed: false,
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_OR_EQUIPMENT,
      inputData: {
        ciApplication: true,
        step: '2',
      },
    },
    {
      id: 3,
      name: 'FACILITY_CI_INFO',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_CI_INFO,
    },
    {
      id: 4,
      name: 'UPLOAD_CI_DOCUMENTS',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.UPLOAD_DOCUMNETS,
      inputData: {
        documentType: 'ci',
      },
    },
    {
      id: 5,
      name: 'CONTACT_PERSON_INFO',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.CONTACT_PERSON_INFO,
      inputData: {
        step: '100',
      },
    },
  ],

  buttons: {
    showSave: true,
    showSubmit: true,
    submitPrefix: 'submitRegistrationReportButton',
  },
};

export const APPLICATION_APPROVAL_CARBON_INTENSITY_BASIC_INFO: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        key: 'type',
        label: 'type',
        hideExpression: true,
        id: 'type_hidden',
      },
      {
        type: 'dict-select',
        className: 'col-sm-12 col-md-6',
        key: 'fuelId',
        preventAutoPopulate: true,
        items: [],
        dataProvided: true,
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.dropDownLowCIFuels',
        disabled: false,
        required: true,
        id: 'fuel',
        placeholder: 'COMMON.submissionTypeList.selectItem',
      },
      {
        type: 'dummy',
      },
      {
        key: 'fuelSubjectCode',
        type: 'radio',
        className: 'col-12',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.fuelSubject',
        required: true,
        dummyInputForLabel: true,
        apiOptions: true,
        apiService: LOOKUP_FUEL_SUBJECT,
        bindValue: 'code',
        bindLabel: 'code',
        hideExpression: "model.type === 'CI_FUELING_STATIONS' || model.type === 'CI_CHARGING_STATIONS'"
      },
      {
        key: 'approvedCiFromAnotherPerson',
        type: 'dict-select',
        className: 'col-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.approvedCiFromAnotherPerson',
        required: true,
        items: [
          { id: true, name: 'Yes' },
          { id: false, name: 'No' },
        ],
        dataProvided: true,
        translatePrefix: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm'
      },
      {
        type: 'dummy',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'linkApplicationId',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.ciFuelID',
        placeholder: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.ciApplicationIDPlaceholder',
        required: true,
        id: 'orgName',
        hideExpression: "!model.approvedCiFromAnotherPerson",
      },
      {
        type: 'button',
        id: 'searchOrgName',
        className: 'col-0 mr-2',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.search',
        customTemplateOptions: {},
        hideExpression: "!model.approvedCiFromAnotherPerson",
      },
      {
        type: 'button',
        id: 'clearOrgName',
        className: 'p-0',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.clear',
        customTemplateOptions: {},
        hideExpression: "!model.approvedCiFromAnotherPerson",
      },
      {
        type: 'dummy',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'linkAccountName',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.ciFuelOrgName',
        required: true,
        formDisabled: true,
        placeholder: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.ciFuelOrgNamePlaceholder',
        id: 'ciFuelOrgName',
        hideExpression: "!model.approvedCiFromAnotherPerson",
        ignoreLocalFormDisabled: true
      },
      {
        key: 'formType',
        type: 'radio',
        className: 'col-12',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.radioOptLowCiFuel',
        required: true,
        dummyInputForLabel: true,
        options: [
          { value: 'Yes', key: 'REPLACED' },
          { value: 'No', key: 'NEW' },
        ],
        hideExpression: "model.type === 'CO_PROCESSED_LOW_CI_FUEL'",
      },
      {
        type: 'dict-select',
        className: 'col-sm-12 col-md-6',
        key: 'recentApplicationId',
        items: [],
        dataProvided: true,
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.dropDown2',
        disabled: false,
        id: 'recentApplicationList',
        requiredExpression: 'model.formType === \'REPLACED\'',
        hideExpression: 'model.formType === \'NEW\'',
        placeholder: 'COMMON.submissionTypeList.selectItem',
      },
      {
        key: 'numMonthsData',
        type: 'radio',
        name: 'numMonthsData',
        className: 'col-12',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.radioOpt4',
        apiOptions: true,
        dummyInputForLabel: true,
        apiService: LOOKUP_APPLICATION_MONTHS_DATA,
        required: true,
        hideExpression: "model.type === 'CO_PROCESSED_LOW_CI_FUEL'",
      },
      {
        key: 'ciMethod',
        type: 'radio',
        name: 'ciMethod',
        className: 'col-12',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.radioOpt2',
        required: true,
        apiOptions: true,
        apiService: LOOKUP_CI_METHOD,
        dummyInputForLabel: true,
        id: 'ciMethod',
      },
      {
        key: 'pathwayType',
        type: 'radio',
        className: 'col-12',
        name: 'pathwayType',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.radioOpt3',
        apiOptions: true,
        dummyInputForLabel: true,
        apiService: LOOKUP_PATHWAY,
        hideExpression: "model.numMonthsData === 'LESS_THAN_3_MONTHS' || (model.ciMethod === 'DEFAULT' && model.type === 'CI_PRODUCTION_OF_LOW_CI_FUELS')",
        requiredExpression: "model.numMonthsData !== 'LESS_THAN_3_MONTHS' || model.ciMethod === 'LCA_MODEL' || !(model.ciMethod === 'DEFAULT' && model.type === 'CI_PRODUCTION_OF_LOW_CI_FUELS')",
        id: 'type',
      },
      {
        type: 'dict-select',
        className: 'col-sm-12 col-md-6',
        key: 'pathwayId',
        items: [],
        dataProvided: true,
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.dropDown3',
        disabled: false,
        preventAutoPopulate: true,
        requiredExpression: "!((model.type  === 'CI_PRODUCTION_OF_LOW_CI_FUELS' && model.ciMethod === 'DEFAULT') || (!model.pathwayType || model.pathwayType === 'EXISTING') )",
        id: 'pathwayList',
        hideExpression: "((model.type  === 'CI_PRODUCTION_OF_LOW_CI_FUELS' && model.ciMethod === 'DEFAULT') || (!model.pathwayType || model.pathwayType === 'EXISTING') )",
        placeholder: 'COMMON.submissionTypeList.selectItem',
      },
      {
        key: 'ciStatus',
        type: 'radio',
        className: 'col-12',
        name: 'ciStatus',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.radioOpt5',
        apiOptions: true,
        apiService: LOOKUP_APPLICATION_CI_STATUS,
        dummyInputForLabel: true,
        hideExpression: "model.numMonthsData === 'LESS_THAN_3_MONTHS' || (model.ciMethod === 'DEFAULT' && (model.type === 'CI_PRODUCTION_OF_LOW_CI_FUELS' || model.type === 'CO_PROCESSED_LOW_CI_FUEL'))",
        requiredExpression: "model.numMonthsData !== 'LESS_THAN_3_MONTHS' || model.ciMethod === 'LCA_MODEL' || !(model.ciMethod === 'DEFAULT' && (model.type === 'CI_PRODUCTION_OF_LOW_CI_FUELS' || model.type === 'CO_PROCESSED_LOW_CI_FUEL'))",
      },
    ],
  },
];

export const APPLICATION_APPROVAL_CARBON_INTENSITY_REVIEW_ORG_INFO: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'legalName',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.legalName',
        disabled: true,
        required: false,
        id: 'legalName',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'accountId',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.orgId',
        disabled: true,
        required: true,
        id: 'accountId',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'englishTradeName',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.enTradeName',
        disabled: true,
        required: false,
        id: 'englishTradeName',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'frenchTradeName',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.frTradeName',
        disabled: true,
        required: false,
        id: 'frenchTradeName',
      },
    ],
  },
];

export const APPLICATION_APPROVAL_CARBON_INTENSITY_AUTH_OFFICIAL: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'title',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.title',
        disabled: true,
        required: true,
        id: 'title',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'fullName',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.name',
        disabled: true,
        required: true,
        id: 'name',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'businessTelephone',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.telephoneNum',
        disabled: true,
        required: true,
        id: 'telephoneNum',
        placeholder: 'COMMON.messageSection.phonePlaceholder',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'email',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.email',
        disabled: true,
        required: true,
        id: 'email',
      },
    ],
  },
];

export const APPLICATION_APPROVAL_CARBON_INTENSITY_CONTACT_PERSON: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'checkbox',
        key: 'contactPersonSame',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.sameAsContactPersonOrg',
        disabled: false,
        required: false,
        className: 'col-6',
        id: 'contactPersonSame',
      },
      {
        type: 'checkbox',
        key: 'sameAsAuthorizedOfficial',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.sameAsAuthorizedOfficial',
        disabled: false,
        required: false,
        className: 'col-6',
        id: 'sameAsAuthorizedOfficial',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'title',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.title',
        disabled: false,
        required: true,
        id: 'title',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'name',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.name',
        disabled: false,
        required: true,
        id: 'name',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'businessTelephone',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.telephoneNum',
        disabled: false,
        required: true,
        id: 'telephoneNum',
        placeholder: 'COMMON.messageSection.phonePlaceholder',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'email',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.email',
        disabled: false,
        required: true,
        id: 'email',
      },
    ],
  },
];

export const APPLICATION_APPROVAL_CARBON_INTENSITY_PROD_LOW_FUEL: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'field1',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field1',
        disabled: false,
        required: true,
        id: 'field1',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'field2',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field2',
        disabled: false,
        required: true,
        id: 'field2',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'field3',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field3',
        disabled: false,
        required: true,
        id: 'field3',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'field4',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field4',
        disabled: false,
        required: true,
        id: 'field4',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'field5',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field5',
        disabled: false,
        required: true,
        id: 'field5',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'field6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field6',
        disabled: false,
        required: true,
        id: 'field6',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'field7',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field7',
        disabled: false,
        required: true,
        id: 'field7',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'field8',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field8',
        disabled: false,
        required: true,
        id: 'field8',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'field9',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.field9',
        disabled: false,
        required: true,
        id: 'field9',
      },
    ],
  },
];

export const CI_APP_NON_RESIDENTIAL_CHARGING_STATION = {
  groupFields: true,
  label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.electricEnergySource',
  fieldGroup: [
    {
      type: 'multi-checkbox',
      className: 'col-12',
      wrappers: ['form-field'],
      key: 'electricityByTechnologyIds',
      source: SOURCE_DATA_TYPE.ELECTRIC_ENERGEY_SOURCE,
      label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.electricityTech',
      disabled: false,
      required: true,
      dummyInputForLabel: true,
      id: 'electricityByTechnologyIds',
      translatePrefix: 'COMMON.energySourceList',
      hideExpression: 'false',
      dataType: null,
      expressionProperties: {
        'templateOptions.required':
          '!((model.electricityByTechnologyIds && model.electricityByTechnologyIds.length) || (model.electricityFromProvincialGridIds && model.electricityFromProvincialGridIds.length))',
      },
    },
    {
      type: 'input',
      className: 'col-6',
      key: 'electricityByTechnologyOther',
      label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.electricityTechOther',
      disabled: false,
      required: true,
      dummyInputForLabel: true,
      id: 'electricityByTechnologyOther',
      hideExpression: '!(model.electricityByTechnologyIds && model.electricityByTechnologyIds.includes(35))',
    },
    {
      type: 'multi-checkbox',
      className: 'col-12',
      wrappers: ['form-field'],
      key: 'electricityFromProvincialGridIds',
      source: SOURCE_DATA_TYPE.PROVINCIAL_GRID,
      label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.provicialGrid',
      translatePrefix: 'COMMON.jurisdictionsList',
      disabled: false,
      required: true,
      dummyInputForLabel: true,
      id: 'ElectricityFromProvincialGridIds',
      hideExpression: 'false',
      dataType: null,
      expressionProperties: {
        'templateOptions.required':
          '!((model.electricityByTechnologyIds && model.electricityByTechnologyIds.length) || (model.electricityFromProvincialGridIds && model.electricityFromProvincialGridIds.length))',
      },
    },
  ],
};

export const CI_APP_FUELING_STATION = {
  groupFields: true,
  label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.typeThermalElectricalEnergy',
  fieldGroup: [
    {
      type: 'multi-checkbox',
      className: 'col-6',
      key: 'thermalElectricCompressionsIds',
      source: SOURCE_DATA_TYPE.THERMAL_ELECTRICAL_COMPRESSION,
      //  label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.typeThermalElectricalEnergy',
      disabled: false,
      required: true,
      id: 'thermalElectricCompressionsIds',
      hideExpression: 'false',
      translatePrefix: 'COMMON.fuelList',
      dataType: null,
    },
  ],
};

export const APPLICATION_APPROVAL_CARBON_INTENSITY_CI_SECTION: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'carbonIntensityFuel',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.carbonIntensityOFuel',
        disabled: false,
        required: true,
        id: 'carbonIntensityFuel',
        placeholder: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.ciValuePlaceholder',
        customTemplateOptions: {
          type: 'formatted-number',
          mask: 'separator.2',
          allowNegativeNumbers: false,
        },
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'additionalCarbonIntensityFuel',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.additionalCarbonIntensityLowCiFuel',
        disabled: false,
        required: false,
        id: 'additionalCarbonIntensityFuel',
        placeholder: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.additionalCarbonIntensityFuelPlaceholder',
        hideExpression: "model.type === 'CO_PROCESSED_LOW_CI_FUEL'",
        customTemplateOptions: {
          type: 'formatted-number',
          mask: 'separator.2',
          allowNegativeNumbers: false,
        },
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'ciValue',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.carbonIntensityRounded',
        disabled: true,
        required: true,
        id: 'ciValue',
        customTemplateOptions: {
          type: 'formatted-number',
        },
      },
      {
        type: 'dict-select',
        className: 'col-6',
        key: 'feedStockId',
        source: SOURCE_DATA_TYPE.FEED_STOCK,
        translatePrefix: 'COMMON.feedstockList',
        dataType: 'PRODFF/LIQUID',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.fieldLowCi_1',
        disabled: false,
        required: true,
        hideExpression: "model.type !== 'CI_PRODUCTION_OF_LOW_CI_FUELS' && model.type !== 'CO_PROCESSED_LOW_CI_FUEL'",
        id: 'feedstockOfFuel',
        placeholder: 'selectItem',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'feedstockRegion',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.regionOfFuel',
        disabled: false,
        required: true,
        id: 'regionOfFuel',
        hideExpression: "model.type !== 'CI_PRODUCTION_OF_LOW_CI_FUELS' && model.type !== 'CO_PROCESSED_LOW_CI_FUEL'",
      },
      {
        type: 'dict-select',
        className: 'col-6',
        source: SOURCE_DATA_TYPE.CI_APPLICATION_TYPE,
        translatePrefix: 'COMMON.ciApplicationTypeList',
        key: 'ciApplicationTypeObj',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.ciApplicationType',
        disabled: false,
        required: true,
        id: 'ciApplicationType',
        hideExpression: "model.type !== 'CO_PROCESSED_LOW_CI_FUEL'",
        placeholder: 'selectItem',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'otherFeedStock',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.otherFeedStock',
        disabled: false,
        required: true,
        id: 'otherFeedStock',
        hideExpression: "(!model.feedstockName || (model.feedstockName).toLowerCase() != 'other')",
      },
    ],
  },
];

export const APPLICATION_APPROVAL_CARBON_INTENSITY_NON_RES_CHARG_STATION = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'multi-checkbox',
        className: 'col-6',
        key: 'applicationCompressions',
        source: SOURCE_DATA_TYPE.ELECTRIC_ENERGEY_SOURCE,
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.electricityTechnology',
        disabled: false,
        required: true,
        translatePrefix: 'COMMON.energySourceList',
        id: 'entityId',
      },
      {
        type: 'multi-checkbox',
        className: 'col-6',
        key: 'applicationCompressions',
        source: SOURCE_DATA_TYPE.PROVINCE,
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.electricityTechnologyProvince',
        disabled: false,
        translatePrefix: 'COMMON.jurisdictionsList',
        required: true,
        id: 'entityId',
      },
    ],
  },
];

export const APPLICATION_APPROVAL_CARBON_INTENSITY_CI_STAGE_PRODUCTION_SECTION: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col-6 p-2',
        key: 'thermalElectricalCompression',
        source: SOURCE_DATA_TYPE.THERMAL_ELECTRICAL_COMPRESSION,
        dataType: 'PRODFF/LIQUID',
        label: '',
        disabled: false,
        required: true,
        id: 'thermalElectricalCompression',
      },
      {
        type: 'input',
        className: 'col-6 p-2',
        key: 'name',
        label: '',
        disabled: false,
        required: true,
        id: 'stageOfProduction',
      },
    ],
  },
];

export const stageTableConfig: ITableViewConfig = {
  translationPrefix: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm',
  paginator: false,
  columns: [
    {
      field: 'entityId',
      header: 'typeThermalElectricalEnergy',
      width: '150px',
    },
    {
      field: 'name',
      header: 'stageProduction',
      width: '70px',
    },
  ],
};

export const CI_CHARGING_STATION: IRawFormBase[] = [
  {
    groupFields: true,
    label: '',
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'name',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.chargingStationName',
        required: true,
        id: 'name',
      },
    ],
  },
];

export const CI_FACILITY: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        key: 'facilityType',
        type: 'radio',
        className: 'col-12 inline mb-3',
        required: true,
        dummyInputForLabel: true,
        options: [
          { value: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.newFacility', key: 'NEW' },
          {
            value: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.existingFacility',
            key: 'EXISTING',
            // tooltip: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.addMyFacilitiesInfoText'
          },
        ],
      },
    ]
  },
  {
    groupFields: true,
    label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.facilityInfo',
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'name',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.facilityName',
        required: true,
        id: 'name',
        hideExpression: "model.facilityType === 'EXISTING'"
      },
      {
        type: 'dict-select',
        className: 'col-sm-12 col-md-6',
        key: 'facilityId',
        items: [],
        dataProvided: true,
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.facilityName',
        disabled: false,
        required: true,
        id: 'facilityId',
        placeholder: 'COMMON.submissionTypeList.selectItem',
        hideExpression: "!model.facilityType || model.facilityType === 'NEW'",
        bindValue: 'pkId'
      },
    ],
  },
];

export const APPLICATION_FACILITY_CIVIC_ADDRESS_SECTION = {
  prefix: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.',
  label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.facilityCivicAddress',
  key: 'civicAddress',
  hide: false,
  hidePoBox: true,
  sameAsPostal: {
      hide: true,
  },
  sameAsOrganization: {
    hide: false,
    changeExpr: `this.cloneFormGroup($event, field, '_organizationAddress', 'civicAddress'); this.enableLatLongWithDisabledForm($event, field)`,
  },
  hideLatLong: false,
  requiredLatLong: true,
  optional: true,
  className: 'col-12',
};

export const FACILITY_MODE = {
  manual: 'MANUAL'
}
export const FACILITY_TYPE = {
  new: 'NEW',
  existing: 'EXISTING'
}

export const COMPLIANCE_REPORT_CONTACT_PERSON: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'checkbox',
        key: 'contactPersonSame',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.sameAsContactPersonOrg',
        disabled: false,
        required: false,
        className: 'col-6',
        id: 'contactPersonSame',
        disabledExpression: 'model._disabled',
      },
      {
        type: 'checkbox',
        key: 'sameAsAuthorizedOfficial',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.sameAsAuthorizedOfficial',
        disabled: false,
        required: false,
        className: 'col-6',
        id: 'sameAsAuthorizedOfficial',
        disabledExpression: 'model._disabled',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'title',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.title',
        disabled: false,
        required: true,
        id: 'title',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'name',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.name',
        disabled: false,
        required: true,
        id: 'name',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'businessTelephone',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.telephoneNum',
        disabled: false,
        required: true,
        id: 'telephoneNum',
        placeholder: 'COMMON.messageSection.phonePlaceholder',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'email',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.email',
        disabled: false,
        required: true,
        id: 'email',
      },
    ],
  },
];
