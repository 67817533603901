/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-approval.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/components/documents/documents.component.ngfactory";
import * as i5 from "../../../../shared/components/documents/documents.component";
import * as i6 from "../../../../shared/services/modal.service";
import * as i7 from "../../../../shared/services/upload-document.service";
import * as i8 from "../../../../core/store/store.service";
import * as i9 from "../../../../shared/services/document-in-progress.service";
import * as i10 from "../../../../shared/components/project-builder/project-list/project-list.component.ngfactory";
import * as i11 from "../../../../shared/components/project-builder/project-list/project-list.component";
import * as i12 from "@angular/router";
import * as i13 from "../../../../shared/services/project.service";
import * as i14 from "../../../../shared/services/table-template-controls.service";
import * as i15 from "../../../../shared/services/notifications.service";
import * as i16 from "../../../../core/services/lookup.service";
import * as i17 from "../../../../shared/services/helper.service";
import * as i18 from "../reg-rept-list/reg-rept-list.component.ngfactory";
import * as i19 from "../reg-rept-list/reg-rept-list.component";
import * as i20 from "../../services/account-management.service";
import * as i21 from "../../../cfr-registration/services/cfr-registration.service";
import * as i22 from "@angular/common";
import * as i23 from "./account-approval.component";
import * as i24 from "../../services/base-account-actions.service";
import * as i25 from "../../../users-management/services/user-management.service";
import * as i26 from "../../../../shared/services/previous-location.service";
import * as i27 from "../../../../shared/services/navigation.service";
import * as i28 from "../../../../shared/components/project-builder/project-transfer-request/project-transfer.service";
var styles_AccountApprovalComponent = [i0.styles];
var RenderType_AccountApprovalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountApprovalComponent, data: {} });
export { RenderType_AccountApprovalComponent as RenderType_AccountApprovalComponent };
function View_AccountApprovalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "panel mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ": "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ": ", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-documents", [], null, [[null, "refreshTableData"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onBeforeUnload($event) !== false);
        ad = (pd_0 && ad);
    } if (("refreshTableData" === en)) {
        var pd_1 = (_co.updateDocumentDetails($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DocumentsComponent_0, i4.RenderType_DocumentsComponent)), i1.ɵdid(8, 770048, null, 0, i5.DocumentsComponent, [i6.ModalService, i7.UploadDocumentService, i8.StoreService, i9.DocumentUploadInProgressService, i3.TranslateService], { documentUploadConfig: [0, "documentUploadConfig"], hidden: [1, "hidden"] }, { refreshTableData: "refreshTableData" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.documentUploadConfig; var currVal_4 = true; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.approveAccounts.authorizationDocuments")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ACCOUNTS_MODULE.approveAccounts.accountName")); var currVal_2 = _co.account.legalName; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
function View_AccountApprovalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "panel mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ": "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ": ", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-documents", [], null, [[null, "refreshTableData"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onBeforeUnload($event) !== false);
        ad = (pd_0 && ad);
    } if (("refreshTableData" === en)) {
        var pd_1 = (_co.updateDocumentDetails($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DocumentsComponent_0, i4.RenderType_DocumentsComponent)), i1.ɵdid(8, 770048, null, 0, i5.DocumentsComponent, [i6.ModalService, i7.UploadDocumentService, i8.StoreService, i9.DocumentUploadInProgressService, i3.TranslateService], { documentUploadConfig: [0, "documentUploadConfig"], hidden: [1, "hidden"] }, { refreshTableData: "refreshTableData" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.replaceUserDocumentUploadConfig; var currVal_4 = true; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.approveAccounts.authorizationDocuments")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ACCOUNTS_MODULE.approveAccounts.accountName")); var currVal_2 = _co.account.legalName; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
function View_AccountApprovalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "panel mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-project-list", [], null, [[null, "projectListData"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("projectListData" === en)) {
        var pd_0 = (_co.fetchProjectData($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ProjectListComponent_0, i10.RenderType_ProjectListComponent)), i1.ɵdid(5, 245760, null, 0, i11.ProjectListComponent, [i12.ActivatedRoute, i13.ProjectService, i14.TableTemplateControls, i3.TranslateService, i15.NotificationsService, i16.LookupService, i17.HelperService, i6.ModalService, i12.Router, i8.StoreService, i1.ChangeDetectorRef], { showTableDetails: [0, "showTableDetails"], showFilters: [1, "showFilters"], registrationAccountId: [2, "registrationAccountId"] }, { projectListData: "projectListData" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; var currVal_2 = false; var currVal_3 = _co.id; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PROJECTS_MODULE.tableDetails.title")); _ck(_v, 2, 0, currVal_0); }); }
function View_AccountApprovalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "panel mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-reg-rept-list", [], null, null, null, i18.View_RegReptListComponent_0, i18.RenderType_RegReptListComponent)), i1.ɵdid(2, 245760, null, 0, i19.RegReptListComponent, [i12.Router, i20.AccountManagementService, i21.CfrRegistrationService, i3.TranslateService, i16.LookupService, i17.HelperService, i8.StoreService], { accountId: [0, "accountId"], accountStatus: [1, "accountStatus"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.account.id; var currVal_1 = _co.account.status; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AccountApprovalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.approve("confirmationMessageAccountKycDocproject") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.APPROVE_KYC_DOCS")); _ck(_v, 2, 0, currVal_0); }); }
function View_AccountApprovalComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.approve("confirmationMessageAccount") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.APPROVE")); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountApprovalComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_9)), i1.ɵdid(1, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.replace; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AccountApprovalComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.needMoreInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.NEED_MORE_INFO")); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountApprovalComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.REJECT")); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountApprovalComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.approveReplaceUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.APPROVE")); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountApprovalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_7)), i1.ɵdid(2, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["approveTemplate", 2]], null, 0, null, View_AccountApprovalComponent_8)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_10)), i1.ɵdid(5, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_11)), i1.ɵdid(7, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_12)), i1.ɵdid(9, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.replace && _co.account.projectTransferRequestDto) && (_co.account.status === "PENDING_REVIEW_ADMIN")); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.replace; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.replace; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.replace; _ck(_v, 9, 0, currVal_4); }, null); }
function View_AccountApprovalComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendToProponentForConsent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.SEND_CURRENT_PROPONENT_CONSENT")); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountApprovalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "panel mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ": "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ": ", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-documents", [], null, [[null, "refreshTableData"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onBeforeUnload($event) !== false);
        ad = (pd_0 && ad);
    } if (("refreshTableData" === en)) {
        var pd_1 = (_co.updateDocumentDetails($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DocumentsComponent_0, i4.RenderType_DocumentsComponent)), i1.ɵdid(15, 770048, null, 0, i5.DocumentsComponent, [i6.ModalService, i7.UploadDocumentService, i8.StoreService, i9.DocumentUploadInProgressService, i3.TranslateService], { documentUploadConfig: [0, "documentUploadConfig"], hidden: [1, "hidden"] }, { refreshTableData: "refreshTableData" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_2)), i1.ɵdid(17, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_3)), i1.ɵdid(19, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_4)), i1.ɵdid(21, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_5)), i1.ɵdid(23, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 8, "div", [["class", "sticky-action-button sticky-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 7, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 6, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.returnPreviousLocation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(28, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_6)), i1.ɵdid(31, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["consentTemplate", 2]], null, 0, null, View_AccountApprovalComponent_13))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.userDocumentUploadConfig; var currVal_6 = true; _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_7 = !_co.replace; _ck(_v, 17, 0, currVal_7); var currVal_8 = _co.replace; _ck(_v, 19, 0, currVal_8); var currVal_9 = _co.isOffset; _ck(_v, 21, 0, currVal_9); var currVal_10 = (_co.isCFR && _co.isVBAccount); _ck(_v, 23, 0, currVal_10); var currVal_12 = (!_co.account.enableSendConsent && !_co.enableSendConsent); var currVal_13 = i1.ɵnov(_v, 32); _ck(_v, 31, 0, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.approveAccounts.approveAccounts")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ACCOUNTS_MODULE.approveAccounts.reviewText")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("ACCOUNTS_MODULE.approveAccounts.identificationDocuments")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("ACCOUNTS_MODULE.approveAccounts.mainAuthorizedOfficial")); var currVal_4 = _co.authorizedUserName; _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_11 = i1.ɵunv(_v, 28, 0, i1.ɵnov(_v, 29).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 28, 0, currVal_11); }); }
export function View_AccountApprovalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountApprovalComponent_1)), i1.ɵdid(1, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AccountApprovalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-approval", [], null, null, null, View_AccountApprovalComponent_0, RenderType_AccountApprovalComponent)), i1.ɵdid(1, 245760, null, 0, i23.AccountApprovalComponent, [i8.StoreService, i24.BaseAccountActionsService, i20.AccountManagementService, i12.ActivatedRoute, i21.CfrRegistrationService, i22.Location, i6.ModalService, i3.TranslateService, i25.UserManagementService, i26.PreviousLocationService, i12.Router, i17.HelperService, i27.NavigationService, i28.ProjectTransferService, i13.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountApprovalComponentNgFactory = i1.ɵccf("app-account-approval", i23.AccountApprovalComponent, View_AccountApprovalComponent_Host_0, {}, {}, []);
export { AccountApprovalComponentNgFactory as AccountApprovalComponentNgFactory };
