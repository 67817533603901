 <h2 class="title" *ngIf="!hideTitleAndDescription">{{ pendingDocumentMetaData.prefix + pendingDocumentMetaData.title | translate}}</h2>
<p *ngIf="!hideTitleAndDescription">{{ pendingDocumentMetaData.prefix + pendingDocumentMetaData.dataLabel | translate}} {{pendingDocumentMetaData.data}}</p>

   <h3>{{ pendingDocumentMetaData.prefix + pendingDocumentMetaData.uploadDocumentText  | translate }}</h3>
    <br/>

    <div class="row">
       <div class="col-1" *ngIf="pendingDocumentMetaData.status">
            <fa-icon class="float-left back-icon"
                     [ngStyle]="statusIcon[pendingDocumentMetaData.status].style"
                     [icon]="statusIcon[pendingDocumentMetaData.status].icon">
            </fa-icon>
       </div>
        <div class="col-11">
          <p [innerHTML]="pendingDocumentMetaData.identificationDocuments | translate"></p>
        </div>
    </div>

    <app-documents  *ngIf="pendingDocumentMetaData"
        [documentsPage]="pendingDocumentMetaData.documentsPage"
        [disabled]="pendingDocumentMetaData.disabled"
        [documentUploadConfig]="pendingDocumentMetaData.documentUploadConfig"
        [showPlaceholders]="true"
        (documentResult)="updateDocumentDetails($event)">
    </app-documents>

    <p>{{ 'REGISTRATION_PAGE.pendingRegistrations.supportedFileTypes' | translate }}  {{ docAllowedFileTypes }}</p>
    <p>{{ 'REGISTRATION_PAGE.pendingRegistrations.supportedFileSize' | translate }}  {{ docAllowedSize }} MB</p>



