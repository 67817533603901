import { PROGRAM_OBPS, PROGRAM_OFFSET } from '@core/models/eccc-programs.const';
import { ITaskAction, ITaskActionMap, ITaskFieldsetMap, TTaskFieldset } from '@core/models/task.types';
import { reportType } from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.constants';
import { ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';

export const ACCOUNT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'accountName',
  'accountId',
];

export const ACCREDITATION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'expiryDateStr',
  'status',
];

export const PAYMENTS_FIELDSET: TTaskFieldset = [
  'createdOn',
  'obligationAssignmentDate',
  {complianceYear: [PROGRAM_OBPS]},
  {reportingPeriod: [PROGRAM_OFFSET]},
  'obligationId',
  'regularRateDeadline',
  {'increasedRateDeadline': [PROGRAM_OBPS]},
];

export const SECTION58_FIELDSET: TTaskFieldset = [
  'accountName',
  {complianceYear: [PROGRAM_OBPS]},
  {reportingPeriod: [PROGRAM_OFFSET]},
  'itpRefNumber',
  'intendedPaymentDateStr',
  'intendedPaymentAmount',
];

export const OVERPAYMENTS_FIELDSET: TTaskFieldset = [
  'createdOn',
  'obligationId',
  'complianceYear',
];

export const TRANSFERS_FIELDSET: TTaskFieldset = [
  'dateInitiatedOn',
  'sourceAccountId',
  'destinationAccountId',
  'transferQuantity',
];

export const REMITTANCES_FIELDSET: TTaskFieldset = [
  'createdOn',
  'obligationAssignmentDate',
  {complianceYear: [PROGRAM_OBPS]},
  {reportingPeriod: [PROGRAM_OFFSET]},
  'obligationId',
  'regularRateDeadline',
  {'increasedRateDeadline': [PROGRAM_OBPS]},
];

export const OUTSTANDING_OBLIGATION_FIELDSET: TTaskFieldset = [
  'accountName',
  'compliancePeriod',
  'obligationId',
  'compensationOutstandingObligation',
  'regularRateDeadline',
  {'increasedRateDeadline': [PROGRAM_OBPS]},
];

export const ASSIGN_181_FIELDSET: TTaskFieldset = [
  'createdOn',
  {complianceYear: [PROGRAM_OBPS]},
  {reportingPeriod: [PROGRAM_OFFSET]},
  'obligationId',
  'regularRateDeadline',
];

export const UNITS_FIELDSET: TTaskFieldset = [
  'createdOn',
  'comments',
];

export const AGREEMENT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'agreementIdentifier',
  'rcName',
  'opa',
  'complianceYear',
];

export const APPLICATION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionType',
  'activityType',
  'applicationStatus',
];

export const CI_PATHWAY_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear'
];

export const CI_PATHWAY_REPORT_NEED_MORE_INFO_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'nmiComments'
];

export const REVIEW_CI_PATHWAY_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'submissionId',
  'quantity',
  'regulateeResponse'
];

export const REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'submissionId',
];

export const APPLICATION_NEED_MORE_INFO_TRANSFER_CI_INVALID_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'submissionId',
  'nmiComments'
];

export const CHECKLIST_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionType',
  'coiChecklistId',
  'complianceYear',
  'coiChecklistStatus',
];

export const VERIFICATION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'participantOrganizationName',
  'submissionType',
  'activityType',
  'applicationStatus',
];

export const MAO_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'currentMAO',
];

export const USER_REVIEW_FIELDSET = [
  'createdOn',
  'organizationName',
  'userName',
  'email',
  'role',
];

export const ERP_VERIFICATION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'participantOrganizationName',
  'submissionType',
  'quantificationMethod',
  'applicationStatus',
];

export const ERP_APPLICATION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionType',
  'quantificationMethod',
  'applicationStatus',
];

export const REGISTRATION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'reportVersion',
  'registrationStatus',
];

export const REGISTRATION_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'accountId',
  'accountName',
];

export const REGISTRATION_ADMIN_FIELDSET: TTaskFieldset = [
  'createdOn',
  'accountSubTypesTranslated',
  'organizationName',
  'reportVersion',
  'registrationStatus',
];

export const REVIEW_ADMIN_COMMENTS_ON_REGISTRATION_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'nmiComments',
  'reportVersion',
  'registrationStatus',
];

export const REGISTRATION_CANCELLATION_ADMIN_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
];

export const APPLICATION_ADMIN_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'activityType',
  'applicationStatus',
];

export const CO2E_EMISSION_REDUCTIONREVIEW_APPLICATION_SUBMISSION_FIELDSET = [
  'createdOn',
  'submissionType',
  'quantificationMethod',
  'applicationStatus',
];

export const CI_APPLICATIONREVIEW_VFB_STATEMENT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'activityType',
  'applicationStatus',
  'verificationOrganizationName',
];

export const CI_APPLICATIONREVIEW_VLB_STATEMENT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'activityType',
  'applicationStatus',
  'validationOrganizationName',
];

export const CO2E_EMISSION_REDUCTIONREVIEW_VFB_STATEMENT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'quantificationMethod',
  'applicationStatus',
  'verificationOrganizationName',
];

export const CO2E_EMISSION_REDUCTIONREVIEW_VLB_STATEMENT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'quantificationMethod',
  'applicationStatus',
  'validationOrganizationName',
];

export const PROJECT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'accountName',
  'accountId',
  'projectName',
  'projectId',
  'projectAggregatedName',
  'projectDescription',
];

export const AGGREGATION_TRANSFER_FIELDSET: TTaskFieldset = [
  'createdOn',
  'sourceAccountName',
  'sourceAccountId',
  'destinationAccountName',
  'destinationAccountId',
];

export const PROJECT_TRANSFER_FIELDSET: TTaskFieldset = [
  'createdOn',
  'sourceAccountName',
  'sourceAccountId',
  'destinationAccountName',
  'destinationAccountId',
  'projectName',
  'projectId',
  'projectAggregatedName',
  'projectDescription',
];

export const PROJECT_NEED_MORE_INFORMATION_ADDON_FIELDSET: TTaskFieldset = [
  'nmiComments',
];

export const REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'projectAggregatedName',
];

export const NOT_ERR_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'projectAggregatedName',
  'projectReport'
];

export const  NOT_GHG_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'projectReport',
  'submissionDeadline'
];

export const NOT_START_DATE_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'projectAggregatedName'
];

export const NOT_CARRYOUT_PROJ_ACT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'projectAggregatedName'
];

export const NOT_CHANGE_LAND_OWNERSHIP_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'projectAggregatedName'
];

export const CORRECTED_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'projectAggregatedName',
  'projectReport',
  'submissionDeadline'
];

export const COMMENT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'projectName',
  'projectId'
];

export const AGREEMENT_TO_TRANSFER_CREDIT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'agreementIdentifier',
  'transferorName',
  'transfereeName',
  'complianceYear'
]

export const CREDIT_CREATION_REOPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'complianceYear',
  'quarterName',
]

export const CREDIT_ADJUSTMENT_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'complianceYear',
  'quarterName',
]

export const NOE_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionType',
  'reportTypeTranslated',
  'quantityOfCreditsToBeCancelled',
];

export const NOE_FIELDSET_NO_QUANTITY: TTaskFieldset = [
  'createdOn',
  'submissionType',
  'reportTypeTranslated',
];

export const NOE_NEED_MORE_INFORMATION_ADDON_FIELDSET: TTaskFieldset = [
  'noenmiComments',
  'regulateeResponse',
];

export const NOS_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionType',
  'reportTypeTranslated',
  'quantityOfCreditsToBeCancelled',
];

export const ASSIGN_VB_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const REVIEW_ENTITY_ACCESS_REQUEST_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
  'vbAccessRequestedEntities',
];

export const REVIEW_VB_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const TEAM_LEAD_ASSIGN_FIELDSET: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const SUBMIT_COI_CHECKLIST: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const COMPLIANCE_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionType',
  'complianceYear',
];

export const START_COI_NOTICE_REVIEW: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const COI_NOTICE_REVIEW: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL_FIELDSET: TTaskFieldset = [
  'createdOn',
  'reportTypeTranslated',
  'complianceYear',
];

export const SUBMIT_CREDIT_ADJUSTMENT_INSUFFICIENT_CREDIT_TO_CANCEL_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
];

export const SUBMIT_CREDIT_ADJUSTMENT_CANCEL_EXCESS_CREDITS_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
];

export const RESUBMIT_COI_NOTICE_REVIEW: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const SUBMIT_VERIFICATION_REPORT: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const PENDING_VERIFICATION_REPORT_SUBMISSION: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const SUBMIT_VERIFICATION_REPORT_TO_ECCC: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const SUBMIT_PENDING_COI_CHECKLIST: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const SUBMIT_UPDATED_COI_CHECKLIST: TTaskFieldset = [
  'createdOn',
  'organizationName',
  'submissionType',
  'complianceYear',
];

export const REVIEW_CREDIT_ADJUSTMENT_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'reason',
];

export const CREDIT_BALANCE_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'complianceYear'
];

export const CREDIT_BALANCE_REPORT_PENDING_REVIEW_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'comments',
  'complianceYear'
];

export const CCM_FIELDSET: TTaskFieldset = [
  'createdOn',
  'complianceYear'
];

export const CCM_PURCHASE_CREDITS_FIELDSET: TTaskFieldset = [
  'createdOn',
  'complianceYear',
  'maxAcquireCredit',
  'totalReductionRequirement'
];

export const REVIEW_COMPLIMENTRY_COMPLIANCE_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'statusTranslated',
];

export const MORE_INFO_REQUIRED_COMPLIMENTRY_COMPLIANCE_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'nmiComments'
];

export const COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear'
];

export const PARTICIPANT_RESPONSE_RECIEVED_FOR_COMPLIMENTRY_COMPLIANCE_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'nmiComments',
  'participantComments'
];

export const PARTICIPANT_RESPONSE_RECIEVED_FOR_CREDIT_REVENUE_REPORT_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'nmiComments',
  'regulateeResponse'
];

export const COMPLIANCE_CREDIT_REVENUE_REPORT_PENDING_SUBMISSION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear'
];

export const COMPLIANCE_CREDIT_REVENUE_REPORT_NEED_MORE_INFO_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'nmiComments',
];

export const REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'submissionDeadline'
];

export const START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
];

export const REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_CUST_FIELDSET : TTaskFieldset = [
  'createdOn',
  'submissionsProjectProponent',
  'projectName',
  'projectId',
  'submissionDeadline',
];

export const MATERIAL_BALANCE_REPORT_PENDING_SUBMISSION_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
];

export const MATERIAL_BALANCE_REPORT_REVIEW_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'submissionId',
  'comments',
];

export const MATERIAL_BALANCE_REPORT_ADDITIONAL_INFO_FIELDSET: TTaskFieldset = [
  'createdOn',
  'submissionTypeTranslated',
  'complianceYear',
  'nmiComments'
];

export const TASKTYPE_FIELDSET_MAP: ITaskFieldsetMap = {
  // CI Pathway Report
  CI_PATHWAY_REPORT_PENDING_SUBMISSION: CI_PATHWAY_REPORT_FIELDSET,
  CI_PATHWAY_REPORT_NEED_MORE_INFO: CI_PATHWAY_REPORT_NEED_MORE_INFO_FIELDSET,
  REVIEW_CI_PATHWAY_REPORT: REVIEW_CI_PATHWAY_REPORT_FIELDSET,
  REVIEW_PROPOSED_CI_PATHWAY_REPORT: REVIEW_CI_PATHWAY_REPORT_FIELDSET,
  APPLICATION_NEED_MORE_INFO_TRANSFER_CI_INVALID: APPLICATION_NEED_MORE_INFO_TRANSFER_CI_INVALID_FIELDSET,

  REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION: REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION_FIELDSET,
  APPLICATION_MATERIAL_BALANCE_REPORT: REVIEW_APPLICATION_FOR_TRANSFER_CI_INVALIDATION_FIELDSET,


  REVIEW_PROJECT_COMMENT_FROM_ADMIN_TEAM: COMMENT_FIELDSET,
  REVIEW_RESPONSE_ON_COMMENT_FROM_PARTICIPANT:COMMENT_FIELDSET,
  REVIEW_COMMENT_FROM_PARTICIPANT:COMMENT_FIELDSET,
  REVIEW_RESPONSE_ON_COMMENT_FROM_ADMIN_TEAM:COMMENT_FIELDSET,

  RENEW_ACCREDITATION: ACCREDITATION_FIELDSET,
  REGISTRATION_REPORT_PENDING_SUBMISSION: REGISTRATION_FIELDSET,
  REGISTRATION_REPORT_REVIEW_VERIFICATION_DOCUMENT: REGISTRATION_REPORT_FIELDSET,
  REGISTRATION_REPORT_DOC_UPLOAD_NEEDED: REGISTRATION_REPORT_FIELDSET,
  REVIEW_REGISTRATION_REPORT: REGISTRATION_ADMIN_FIELDSET,
  REVIEW_REGISTRATION_REPORT_2: REGISTRATION_ADMIN_FIELDSET,
  REVIEW_REGISTRATION_REPORT_PROPOSED_UPDATE: REGISTRATION_FIELDSET,
  REVIEW_REGISTRATION_REPORT_UPDATE: REGISTRATION_ADMIN_FIELDSET,
  REVIEW_ADMIN_COMMENTS_ON_REGISTRATION_REPORT: REVIEW_ADMIN_COMMENTS_ON_REGISTRATION_REPORT_FIELDSET,
  REGISTRATION_REPORT_CANCELLATION_REVIEW1: REGISTRATION_CANCELLATION_ADMIN_FIELDSET,
  REGISTRATION_REPORT_CANCELLATION_REVIEW2: REGISTRATION_CANCELLATION_ADMIN_FIELDSET,
  REVIEW_RESPONSE_FOR_REGISTRATION_REPORT_REVIEW: REGISTRATION_FIELDSET,
  REVIEW_REGISTRATION_ACCOUNT_FOR_NEED_MORE_INFO: REVIEW_ADMIN_COMMENTS_ON_REGISTRATION_REPORT_FIELDSET,

  ACCOUNT_REVIEW: ACCOUNT_FIELDSET,
  ACCOUNT_REVIEW2: ACCOUNT_FIELDSET,
  ACCOUNT_REVIEW_CONSENT: ACCOUNT_FIELDSET,
  ACCOUNT_NEED_MORE_INFORMATION: ACCOUNT_FIELDSET,
  OBPS_RESULT_REVIEW: ACCOUNT_FIELDSET,
  UPLOAD_OBPS_RESULT_REVIEW: ACCOUNT_FIELDSET,
  EEC_PAYMENT_REMITTANCE: PAYMENTS_FIELDSET,
  EEC_PAYMENT_REMITTANCE_DUAL: PAYMENTS_FIELDSET,
  EEC_PAYMENT_MANUAL_REVIEW: PAYMENTS_FIELDSET,
  SECTION_58_REPORT_SUBMISSION: SECTION58_FIELDSET,
  REFUND_REQUEST_REVIEW: OVERPAYMENTS_FIELDSET,
  REFUND_REQUEST_REVIEW_DUAL: OVERPAYMENTS_FIELDSET,
  OVERPAID_OBLIGATION: OVERPAYMENTS_FIELDSET,
  TRANSFER: TRANSFERS_FIELDSET,
  TRANSFER_DUAL: TRANSFERS_FIELDSET,
  REVIEW_INCOMING_TRANSFER: TRANSFERS_FIELDSET,
  REVIEW_PROPOSED_TRANSFER: TRANSFERS_FIELDSET,
  REVIEW_PROPOSED_REMITTANCE: REMITTANCES_FIELDSET,
  RECOGNIZED_UNIT_REMITTANCE: REMITTANCES_FIELDSET,
  RECOGNIZED_UNIT_REMITTANCE_DUAL: REMITTANCES_FIELDSET,
  SURPLUS_CREDIT_REMITTANCE: REMITTANCES_FIELDSET,
  SURPLUS_CREDIT_REMITTANCE_DUAL: REMITTANCES_FIELDSET,
  CANCELLATION: UNITS_FIELDSET,
  CANCELLATION_DUAL: UNITS_FIELDSET,
  REVIEW_PROPOSED_CANCELLATION: UNITS_FIELDSET,
  CREDIT_REVOCATION: UNITS_FIELDSET,
  CREDIT_REVOCATION_DUAL: UNITS_FIELDSET,
  CREDIT_SUSPEND: UNITS_FIELDSET,
  CREDIT_ISSUANCE: UNITS_FIELDSET,
  RECOGNIZED_UNIT_ADJUSTMENT_DUAL: REMITTANCES_FIELDSET,
  RECOGNIZED_UNIT_ADJUSTMENT: REMITTANCES_FIELDSET,
  SECTION_181_OBLIGATION_REVIEW_DUAL: ASSIGN_181_FIELDSET,
  SECTION_181_OBLIGATION_REVIEW: ASSIGN_181_FIELDSET,
  OBLIGATION_OUTSTANDING: OUTSTANDING_OBLIGATION_FIELDSET,

  APPLICATION_CI_PATHWAY: APPLICATION_FIELDSET,
  APPLICATION_CI_PATHWAY_ADMIN: APPLICATION_ADMIN_FIELDSET,
  APPLICATION_CI_APPLICATION: APPLICATION_FIELDSET,
  APPLICATION_CI_PATHWAY_REPORT: REVIEW_CI_PATHWAY_REPORT_FIELDSET,
  APPLICATION_CI_APPLICATION_ADMIN: APPLICATION_ADMIN_FIELDSET,
  APPLICATION_ERP_APPLICATION: APPLICATION_FIELDSET,
  APPLICATION_ERP_APPLICATION_ADMIN: APPLICATION_ADMIN_FIELDSET,
  APPLICATION_CO2E_EMISSION_REDUCTION: CO2E_EMISSION_REDUCTIONREVIEW_APPLICATION_SUBMISSION_FIELDSET,
  APPLICATION_CO2E_EMISSION_REDUCTION_ADMIN: CO2E_EMISSION_REDUCTIONREVIEW_APPLICATION_SUBMISSION_FIELDSET,

  VERIFICATION_CI_PATHWAY: VERIFICATION_FIELDSET,
  VERIFICATION_CI_PATHWAY_ADMIN: VERIFICATION_FIELDSET,
  VERIFICATION_CI_APPLICATION: VERIFICATION_FIELDSET,
  VERIFICATION_CI_APPLICATION_ADMIN: VERIFICATION_FIELDSET,
  VERIFICATION_CO2E_EMISSION_REDUCTION: ERP_VERIFICATION_FIELDSET,
  VERIFICATION_CO2E_EMISSION_REDUCTION_ADMIN: ERP_VERIFICATION_FIELDSET,

  REVIEW_COI_CHECKLIST: CHECKLIST_FIELDSET,
  REVIEW_COI_CHECKLIST_ADMIN: CHECKLIST_FIELDSET,
  REVIEW_COI_CHECKLIST_ADMIN2: CHECKLIST_FIELDSET,
  REVIEW_COI_CHECKLIST_PVB: CHECKLIST_FIELDSET,
  START_REVIEW_COI_CHECKLIST: CHECKLIST_FIELDSET,

  MAO_REPLACE_REQUEST_SUBMITTED: MAO_FIELDSET,
  USER_REVIEW: USER_REVIEW_FIELDSET,
  USER_REVIEW_AO_REPLACEMENT: USER_REVIEW_FIELDSET,
  AGREEMENT_PENDING_ADMIN_REVIEW: AGREEMENT_FIELDSET,
  AGREEMENT_PENDING_OPA_APPROVAL: AGREEMENT_FIELDSET,
  AGREEMENT_PENDING_RCA_APPROVAL: AGREEMENT_FIELDSET,
  AGREEMENT_PENDING_UPLOAD: AGREEMENT_FIELDSET,

  CI_APPLICATIONREVIEW_VFB_STATEMENT: CI_APPLICATIONREVIEW_VFB_STATEMENT_FIELDSET,
  CI_APPLICATIONREVIEW_VLB_STATEMENT: CI_APPLICATIONREVIEW_VLB_STATEMENT_FIELDSET,
  APPLICATION_CI_CANCELLATION_SUBMITTED: APPLICATION_ADMIN_FIELDSET,
  APPLICATION_ERP_CANCELLATION_SUBMITTED: APPLICATION_ADMIN_FIELDSET,

  CO2E_EMISSION_REDUCTIONAPPLICATION_ASSIGNED_VFB: CO2E_EMISSION_REDUCTIONREVIEW_VLB_STATEMENT_FIELDSET,
  CO2E_EMISSION_REDUCTIONREVIEW_VFB_STATEMENT: CO2E_EMISSION_REDUCTIONREVIEW_VFB_STATEMENT_FIELDSET,
  CO2E_EMISSION_REDUCTIONREVIEW_VLB_STATEMENT: CO2E_EMISSION_REDUCTIONREVIEW_VLB_STATEMENT_FIELDSET,

  // Project
  PROJECT_ON_HOLD: PROJECT_FIELDSET,
  PROJECT_UNDER_REVIEW: PROJECT_FIELDSET,
  PROJECT_REVIEW2: PROJECT_FIELDSET,
  REVIEW_PROJECT_SUBMISSION: PROJECT_FIELDSET,
  PROJECT_PENDING_SUBMISSION: PROJECT_FIELDSET,
  PROJECT_COMMENT: PROJECT_FIELDSET,
  REVIEW_ADDITION_OF_PROJECT_SUBMISSION: PROJECT_FIELDSET,
  APPROVE_ADDITION_OF_PROJECT_SUBMISSION: PROJECT_FIELDSET,
  SUBMIT_ADDITION_OF_PROJECT_SUBMISSION: PROJECT_FIELDSET,
  NEED_MORE_INFO_ADDITION_OF_PROJECT_SUBMISSION: PROJECT_FIELDSET,

  PROJECT_OFFSET_RECEIVER_PENDING_SUBMISSION: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_PENDING_REVIEW: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_PENDING_REVIEW_2: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_PENDING_UNDER_REVIEW: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_PENDING_UNDER_REVIEW_2: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_TRANSFER_APPROVED_FIRST: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_PENDING_REVIEW_ADMIN_CONSENT: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_REJECTED_REVIEW_2: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_REJECTED_CONSENT_REVIEW_2: PROJECT_FIELDSET,
  PROJECT_OFFSET_RECEIVER_ON_HOLD: PROJECT_FIELDSET,

  // Project cancellation
  PROJECT_CANCELLATION_REVIEW: PROJECT_FIELDSET,
  PROJECT_CANCELLATION_REVIEW2: PROJECT_FIELDSET,
  PROJECT_CANCELLATION_NEED_MORE_INFO: [...PROJECT_FIELDSET, ...PROJECT_NEED_MORE_INFORMATION_ADDON_FIELDSET],

  // Project review
  REVIEW_PROJECT_TRANSFER_REQUEST: PROJECT_TRANSFER_FIELDSET,
  REVIEW2_PROJECT_TRANSFER_REQUEST: PROJECT_TRANSFER_FIELDSET,
  PENDING_ACCEPTANCE_PROJECT_TRANSFER_REQUEST: PROJECT_TRANSFER_FIELDSET,
  PROJECT_TRANSFER_REQUEST_VIEW_NEED_MORE_INFO: PROJECT_TRANSFER_FIELDSET,
  REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST: PROJECT_TRANSFER_FIELDSET,
  PROJECT_OFFSET_RECEIVER_PENDING_REVIEW_CONSENT: AGGREGATION_TRANSFER_FIELDSET,

  // Monitoring Report
  SUBMISSIONS_ON_HOLD_MONITORING_REPORT: REPORT_FIELDSET,
  SUBMISSIONS_PENDING_SUBMISSION_MONITORING_REPORT: REPORT_FIELDSET,
  SUBMISSIONS_REVIEW2_MONITORING_REPORT: REPORT_FIELDSET,
  SUBMISSIONS_UNDER_REVIEW_MONITORING_REPORT: REPORT_FIELDSET,
  REVIEW_SUBMISSIONS_SUBMISSION_MONITORING_REPORT: REPORT_FIELDSET,

  // Project Report
  SUBMISSIONS_ON_HOLD_PROJECT_REPORT: REPORT_FIELDSET,
  SUBMISSIONS_PENDING_SUBMISSION_PROJECT_REPORT: REPORT_FIELDSET,
  SUBMISSIONS_REVIEW2_PROJECT_REPORT: REPORT_FIELDSET,
  SUBMISSIONS_UNDER_REVIEW_PROJECT_REPORT: REPORT_FIELDSET,
  REVIEW_SUBMISSIONS_SUBMISSION_PROJECT_REPORT: REPORT_FIELDSET,

  // Notification or error of ommissions
  REVIEW2_NOT_ERR_OMI_SUBMISSION: NOT_ERR_FIELDSET,
  NOT_ERR_OMI_PENDING_SUBMISSION: NOT_ERR_FIELDSET,
  START_REVIEW_NOT_ERR_OMI_SUBMISSION: NOT_ERR_FIELDSET,
  ADDITIONAL_INFO_REQUESTED_NOT_ERR_OMI: NOT_ERR_FIELDSET,
  REVIEW_NOT_ERR_OMI_SUBMISSION: NOT_ERR_FIELDSET,
  REVIEW_NOT_ERR_OMI_SUBMISSION_CUST: CORRECTED_REPORT_FIELDSET,
  REVIEW_CREDIT_SUSPENSION: CORRECTED_REPORT_FIELDSET,
  SUBMISSIONS_REVIEW2_CORRECTED_REPORT: CORRECTED_REPORT_FIELDSET,
  SUBMIT_CORRECTED_REPORT: CORRECTED_REPORT_FIELDSET,
  REVIEW_SUBMISSIONS_SUBMISSION_CORRECTED_REPORT:CORRECTED_REPORT_FIELDSET,
  SUBMISSIONS_UNDER_REVIEW_CORRECTED_REPORT:CORRECTED_REPORT_FIELDSET,
  SUBMISSIONS_ON_HOLD_CORRECTED_REPORT:CORRECTED_REPORT_FIELDSET,
  SUBMISSIONS_PENDING_SUBMISSION_CORRECTED_REPORT:CORRECTED_REPORT_FIELDSET,
  SUBMISSIONS_PENDING_GHG_REVERSAL_REPORT: CORRECTED_REPORT_FIELDSET,

  //Notification of ghg reversal
  SUBMISSIONS_PENDING_NOT_GHG_REVERSAL: NOT_GHG_FIELDSET,
  REVIEW_NOT_GHG_REVERSAL_SUBMISSION: NOT_GHG_FIELDSET,
  SUBMIT_GHG_REVERSAL_REPORT: NOT_GHG_FIELDSET,

  // Notification of change in Project Start Date
  REVIEW2_NOT_START_DATE_SUBMISSION: NOT_START_DATE_FIELDSET,
  NOT_START_DATE_PENDING_SUBMISSION: NOT_START_DATE_FIELDSET,
  START_REVIEW_NOT_START_DATE_SUBMISSION: NOT_START_DATE_FIELDSET,
  ADDITIONAL_INFO_REQUESTED_NOT_START_DATE: NOT_START_DATE_FIELDSET,
  REVIEW_NOT_START_DATE_SUBMISSION: NOT_START_DATE_FIELDSET,

  // Notification of change in informationa
  INITIATE_PROJECT_CANCELLATION_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  REVIEW_SUBMISSIONS_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  SUBMISSIONS_UNDER_REVIEW_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  SUBMISSIONS_PENDING_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  SUBMISSIONS_ON_HOLD_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  SUBMISSIONS_REVIEW2_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_FIELDSET,

  //  Notification of change of Proponant Name and Civic Address
  INITIATE_PROJECT_TRANSFER_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: PROJECT_TRANSFER_FIELDSET,
  INITIATE_PROJECT_CANCELLATION_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  REVIEW_SUBMISSIONS_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  SUBMISSIONS_UNDER_REVIEW_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  SUBMISSIONS_PENDING_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  SUBMISSIONS_ON_HOLD_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CARRYOUT_PROJ_ACT_FIELDSET,
  SUBMISSIONS_REVIEW2_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CARRYOUT_PROJ_ACT_FIELDSET,

  //  Notification of change of landownership
  REVIEW_SUBMISSIONS_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_FIELDSET,
  SUBMISSIONS_UNDER_REVIEW_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_FIELDSET,
  SUBMISSIONS_PENDING_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_FIELDSET,
  SUBMISSIONS_ON_HOLD_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_FIELDSET,
  SUBMISSIONS_REVIEW2_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_FIELDSET,

  // Agreement to Transfer Credit
  REVIEW_SIGN_AGREEMENT_TUC_TRANSFEREE: AGREEMENT_TO_TRANSFER_CREDIT_FIELDSET,
  AGREEMENT_TO_TRANSFER_CREDIT_UPON_CREATION_PENDING_SUBMISSION: AGREEMENT_TO_TRANSFER_CREDIT_FIELDSET,
  REVIEW_SIGN_AGREEMENT_TUC_TRANSFEROR: AGREEMENT_TO_TRANSFER_CREDIT_FIELDSET,

  // Credit Creation Report
  CREDIT_CREATION_REPORT_PENDING_SUBMISSION: CREDIT_CREATION_REOPORT_FIELDSET,
  CREDIT_CREATION_REPORT_SUBMIT_PROPOSED_SUBMISSION: CREDIT_CREATION_REOPORT_FIELDSET,
  SUBMIT_QUARTERLY_CREDIT_CREATION_REPORT: CREDIT_CREATION_REOPORT_FIELDSET,
  CREDIT_CREATION_REPORT_NEED_MORE_INFO: CREDIT_CREATION_REOPORT_FIELDSET,
  REVIEW_CREDIT_CREATION_REPORT: CREDIT_CREATION_REOPORT_FIELDSET,
  SUBMIT_ANNUALY_CREDIT_CREATION_REPORT: CREDIT_CREATION_REOPORT_FIELDSET,
  SUBMIT_QUARTERLY_Q3Q4_CREDIT_CREATION_REPORT: CREDIT_CREATION_REOPORT_FIELDSET,
  CREDIT_CREATION_REPORT_PENDING_RESUBMISSION: CREDIT_CREATION_REOPORT_FIELDSET,

  // Credit Adjustment Report
  CREDIT_ADJUSTMENT_REPORT_PENDING_SUBMISSION: CREDIT_ADJUSTMENT_REPORT_FIELDSET,
  CREDIT_ADJUSTMENT_REPORT_PENDING_RESUBMISSION: CREDIT_ADJUSTMENT_REPORT_FIELDSET,
  CREDIT_ADJUSTMENT_REPORT_SUBMIT_PROPOSED_SUBMISSION: CREDIT_ADJUSTMENT_REPORT_FIELDSET,

  // Notice of Error
  NOTICE_OF_ERROR_PENDING_SUBMISSION: NOE_FIELDSET_NO_QUANTITY,
  START_REVIEW_NOTICE_OF_ERROR: NOE_FIELDSET,
  NOTICE_OF_ERROR_DISCARDED: NOE_FIELDSET,
  REVIEW_NOTICE_OF_ERROR: NOE_FIELDSET,
  SEND_NOTICE_OF_ERROR_INSUFFICIENT_CREDIT_TO_CANCEL: NOE_FIELDSET,
  REVIEW_NOTICE_OF_ERROR_INSUFFICIENT_CREDIT_TO_CANCEL: NOE_FIELDSET,
  NOTICE_OF_ERROR_PENDING_REVIEW_CANCELLATION: NOE_FIELDSET,
  CANCEL_EXCESS_COMPLIANCE_CREDIT: NOE_FIELDSET,
  ADDITIONAL_INFO_REQUESTED_FOR_NOTICE_OF_ERROR: [...NOE_FIELDSET, ...NOE_NEED_MORE_INFORMATION_ADDON_FIELDSET],
  RESPONSE_RECIEVED_FROM_PARTICIPANT_FOR_NOTICE_OF_ERROR: [...NOE_FIELDSET, ...NOE_NEED_MORE_INFORMATION_ADDON_FIELDSET],

  // Notice of Suspension
  NOTICE_OF_SUSPENSION_PENDING_REVIEW_CANCELLATION: NOS_FIELDSET,
  SEND_NOTICE_OF_SUSPENSION_INSUFFICIENT_CREDIT_TO_CANCEL: NOS_FIELDSET,
  REVIEW_NOTICE_OF_SUSPENSION_INSUFFICIENT_CREDIT_TO_CANCEL: NOS_FIELDSET,

  // Verification workflow
  ASSIGN_VB: ASSIGN_VB_FIELDSET,
  REVIEW_VERIFICATION_ASSIGNMENT: REVIEW_VB_FIELDSET,
  REVIEW_ENTITY_ACCESS_REQUEST: REVIEW_ENTITY_ACCESS_REQUEST_FIELDSET,
  SELECT_TL_IR: TEAM_LEAD_ASSIGN_FIELDSET,
  SUBMIT_COI_CHECKLIST: SUBMIT_COI_CHECKLIST,
  START_COI_NOTICE_REVIEW: START_COI_NOTICE_REVIEW,
  COI_NOTICE_REVIEW: COI_NOTICE_REVIEW,
  RESUBMIT_COI_NOTICE_REVIEW: RESUBMIT_COI_NOTICE_REVIEW,
  SUBMIT_VERIFICATION_REPORT: SUBMIT_VERIFICATION_REPORT,
  PENDING_VERIFICATION_REPORT_SUBMISSION: PENDING_VERIFICATION_REPORT_SUBMISSION,
  SUBMIT_VERIFICATION_REPORT_TO_ECCC: SUBMIT_VERIFICATION_REPORT_TO_ECCC,
  RESUBMIT_VERIFICATION_REPORT_TO_ECCC: SUBMIT_VERIFICATION_REPORT_TO_ECCC,
  SUBMIT_PENDING_COI_CHECKLIST: SUBMIT_PENDING_COI_CHECKLIST,
  SUBMIT_UPDATED_COI_CHECKLIST: SUBMIT_UPDATED_COI_CHECKLIST,

  // Compliance Report
  COMPLIANCE_REPORT_PENDING_SUBMISSION: COMPLIANCE_REPORT_FIELDSET,
  COMPLIANCE_REPORT_PENDING_RESUBMISSION: COMPLIANCE_REPORT_FIELDSET,
  COMPLIANCE_REPORT_SUBMIT_PROPOSED_SUBMISSION: COMPLIANCE_REPORT_FIELDSET,
  SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL: SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL_FIELDSET,
  CANCEL_COMPLIANCE_REPORT_EXCESS_COMPLIANCE_CREDIT: SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL_FIELDSET,

  // Insuficient Credit Notice
  SUBMIT_COMPLIANCE_CREDIT_INSUFFICIENT_CREDIT_TO_CANCEL: SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL_FIELDSET,
  CANCEL_COMPLIANCE_CREDIT_EXCESS_CREDIT: SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL_FIELDSET,

  // Credit Adjustment Report
  REVIEW_CREDIT_ADJUSTMENT_REPORT_ON_ADVERSE_OPINION: REVIEW_CREDIT_ADJUSTMENT_REPORT_FIELDSET,
  CREDIT_ADJUSTMENT_REPORT_CANCEL_EXCESS_COMPLIANCE_CREDIT: SUBMIT_CREDIT_ADJUSTMENT_CANCEL_EXCESS_CREDITS_FIELDSET,
  CREDIT_ADJUSTMENT_REPORT_SEND_NOTICE_INSUFFICIENT_CREDIT_TO_CANCEL: SUBMIT_CREDIT_ADJUSTMENT_INSUFFICIENT_CREDIT_TO_CANCEL_FIELDSET,

  //  Credit Balance Report
  CREDIT_BALANCE_REPORT_PENDING_SUBMISSION: CREDIT_BALANCE_REPORT_FIELDSET,
  CREDIT_BALANCE_REPORT_SUBMIT_PROPOSED_SUBMISSION: CREDIT_BALANCE_REPORT_FIELDSET,
  CREDIT_BALANCE_REPORT_PENDING_REVIEW: CREDIT_BALANCE_REPORT_PENDING_REVIEW_FIELDSET,

  //  CCM
  CREDIT_CLEARANCE_MECHANISM_PENDING_SUBMISSION: CCM_FIELDSET,
  CREDIT_CLEARANCE_MECHANISM_SETUP: CCM_FIELDSET,
  CREDIT_CLEARANCE_MECHANISM_PURCHASE_CREDITS: CCM_PURCHASE_CREDITS_FIELDSET,

  // Complimentary Compliance Report
  REVIEW_COMPLIMENTRY_COMPLIANCE_REPORT: REVIEW_COMPLIMENTRY_COMPLIANCE_REPORT_FIELDSET,
  MORE_INFO_REQUIRED_COMPLIMENTRY_COMPLIANCE_REPORT: MORE_INFO_REQUIRED_COMPLIMENTRY_COMPLIANCE_REPORT_FIELDSET,
  COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION: COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_FIELDSET,
  PARTICIPANT_RESPONSE_RECIEVED_FOR_COMPLIMENTRY_COMPLIANCE_REPORT: PARTICIPANT_RESPONSE_RECIEVED_FOR_COMPLIMENTRY_COMPLIANCE_REPORT_FIELDSET,
  COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_RESUBMISSION: COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_FIELDSET,
  REVIEW_PROPOSED_COMPLIMENTRY_COMPLIANCE_REPORT: COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_FIELDSET,

  // Revenue Report
  COMPLIANCE_CREDIT_REVENUE_REPORT_PENDING_SUBMISSION: COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_FIELDSET,
  COMPLIANCE_CREDIT_REVENUE_REPORT_PENDING_RESUBMISSION: COMPLIANCE_CREDIT_REVENUE_REPORT_PENDING_SUBMISSION_FIELDSET,
  REVIEW_COMPLIANCE_CREDIT_REVENUE_REPORT: COMPLIANCE_CREDIT_REVENUE_REPORT_PENDING_SUBMISSION_FIELDSET,
  COMPLIANCE_CREDIT_REVENUE_REPORT_NEED_MORE_INFO: COMPLIANCE_CREDIT_REVENUE_REPORT_NEED_MORE_INFO_FIELDSET,
  REVIEW_PROPOSED_COMPLIANCE_CREDIT_REVENUE_REPORT: COMPLIANCE_CREDIT_REVENUE_REPORT_PENDING_SUBMISSION_FIELDSET,
  PARTICIPANT_RESPONSE_RECIEVED_FOR_CREDIT_REVENUE_REPORT: PARTICIPANT_RESPONSE_RECIEVED_FOR_CREDIT_REVENUE_REPORT_FIELDSET,

  // GHG Reversal Report
  REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  REVIEW_2_NOT_GHG_REVERSAL_SUBMISSION: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  ADDITIONAL_INFO_REQUESTED_NOT_GHG_REVERSAL: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  START_REVIEW_NOT_GHG_REVERSAL_SUBMISSION: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  REVIEW_2_NOT_SUSPECTED_GHG_REVERSAL_SUBMISSION: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  REVIEW_SUBMISSIONS_GHG_REVERSAL_REPORT: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  SUBMISSIONS_UNDER_REVIEW_GHG_REVERSAL_REPORT: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  SUBMISSIONS_ADDITIONAL_INFO_REQUESTED_GHG_REVERSAL_REPORT: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  REVIEW_SUBMISSIONS_SUBMISSION_GHG_REVERSAL_REPORT: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  SUBMISSIONS_REVIEW2_GHG_REVERSAL_REPORT: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,
  REVIEW_SUSPECTED_NOT_GHG_REVERSAL: REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST_FIELDSET,


//Notice of intent to cancel project Registration
  START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION: START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET,
  REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_CUST: REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_CUST_FIELDSET,
  SUBMISSIONS_REVIEW2_NOT_INT_CANCEL_PROJ_REG: START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET,
  REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION: START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET,
  INITIATE_PROJECT_CANCELLATION_NOT_INT_CANCEL_PROJ_REG: START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET,
  RESPOND_TO_NOTICE_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION: START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET,
  RESPOND_TO_NOTICE_SUBMISSIONS_REVIEW2_NOT_INT_CANCEL_PROJ_REG: START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET,
  ADDITIONAL_INFO_REQUESTED_NOT_INT_CANCEL_PROJ_REG: REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_CUST_FIELDSET,
  NOT_INT_CANCEL_PROJ_REG_PENDING_SUBMISSION: START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET,
  RESPONSE_RECEIVED_FROM_PARTICIPANT_NOT_INT_CANCEL_PROJ_REG_SUBMISSION: START_REVIEW_NOT_INT_CANCEL_PROJ_REG_SUBMISSION_FIELDSET,

  // Material Balance Report
  MATERIAL_BALANCE_REPORT_PENDING_SUBMISSION: MATERIAL_BALANCE_REPORT_PENDING_SUBMISSION_FIELDSET,
  MATERIAL_BALANCE_REPORT_SUBMIT_VERIFICATION_BODY: MATERIAL_BALANCE_REPORT_PENDING_SUBMISSION_FIELDSET,
  MATERIAL_BALANCE_REPORT_REVIEW_MATERIAL_BALANCE_REPORT: MATERIAL_BALANCE_REPORT_REVIEW_FIELDSET,
  MATERIAL_BALANCE_REPORT_ADDITIONAL_INFORMATION_REQUESTED_FOR_CARBON_INTENSITY: MATERIAL_BALANCE_REPORT_ADDITIONAL_INFO_FIELDSET,
};

export const CHECKLIST_ACTION: ITaskAction = {
  url: '/submission-management/coi-checklist/${entityId}',
  params: ['entityId'],
};

export const APPLICATION_ACTION: ITaskAction = {
  url: '/submission-management/wizard/application/${entityId}',
  params: ['entityId'],
};

export const CI_PATHWAY_REPORT_ACTION: ITaskAction = {
  url: '/ci-pathway-reports/report/${entityId}',
  params: ['entityId'],
};

export const VERIFICATION_ACTION: ITaskAction = {
  url: '/verification/manage-verification/${applicationId}',
  params: ['applicationId'],
};

export const AGREEMENT_ACTION: ITaskAction = {
  url: '/agreement-credits/wizard/agreement/${entityId}',
  params: ['entityId'],
};

export const AGREEMENT_UPLOAD_ACTION: ITaskAction = {
  url: '/agreement-credits/wizard/agreement/upload/${entityId}',
  params: ['entityId'],
};

export const APPLICATION_CANCEL: ITaskAction = {
  url: '/submission-management/cancel-application/${entityId}',
  params: ['entityId'],
};

export const APPLICATION_RELEASE: ITaskAction = {
  url: '/submission-management/release-application/${entityId}',
  params: ['entityId'],
};

export const ACCOUNT_SUMMARY: ITaskAction = {
  url: '/account-management/account-summary-user/${accountId}',
  params: ['accountId'],
};

export const VB_REPORT_ACTION: ITaskAction = {
  url: '/submission-management/wizard/statement/${vbIdentifier}/${entityId}',
  params: ['vbIdentifier', 'entityId'],
};

export const REDIRECT_TO_PROJECT: ITaskAction = {
  url: 'REDIRECT_TO_PROJECT',
  params: [],
};

export const MONITORING_REPORT_ACTION: ITaskAction = {
  url: '/project-report/MONITORING_REPORT/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};

export const PROJECT_REPORT_ACTION: ITaskAction = {
  url: '/project-report/PROJECT_REPORT/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};

export const NOT_ERR_OMI_REPORT_ACTION: ITaskAction = {
  url: '/project-notification/NOT_ERR_OMI/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};

export const RESPOND_TO_NOTICE_ACTION_SUSPENSION: ITaskAction = {
  url: '/unit-management/list/credit-suspension/${creditActionId}',
  params: ['creditActionId'],
};

export const CREDIT_ISSUANCE: ITaskAction = {
  url: '/unit-management/list/issue-credit/${entityId}',
  params: ['entityId'],
};

export const RESPOND_TO_NOTICE_ACTION: ITaskAction = {
  url: '/project-notification/NOT_ERR_OMI/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};


export const NOT_START_DATE_REPORT_ACTION: ITaskAction = {
  url: '/project-notification/NOT_START_DATE/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};

export const NOT_GHG_REVERSAL_REPORT_ACTION: ITaskAction = {
  url: '/project-notification/NOT_GHG_REVERSAL/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
}

export const NOT_GHG_SUSPECTED_REVERSAL_REPORT_ACTION: ITaskAction = {
  url: '/project-notification/NOT_SUSPECTED_GHG_REVERSAL/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
}

export const GHG_REVERSAL_REPORT_ACTION: ITaskAction = {
  url: `/project-report/${reportType.ghgReversalReport}`+'/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
}

export const GHG_REVERSAL_REPORT_CREATION_ACTION: ITaskAction = {
  url: `/project-report/${reportType.ghgReversalReport}`+'/${accountId}/${projectPid}/${entityId}/true',
  params: ['accountId', 'projectPid', 'entityId'],
}

export const NOT_CARRYOUT_PROJ_ACT_REPORT_ACTION: ITaskAction = {
  url: '/project-notification/NOT_CARRYOUT_PROJ_ACT/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};

export const NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS_ACTION: ITaskAction = {
  url: '/project-notification/NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};

export const NOT_CHANGE_LAND_OWNERSHIP_ACTION: ITaskAction = {
  url: '/project-notification/NOT_CHANGE_LAND_OWNERSHIP/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};

export const CANCEL_PROJECT_ACTION: ITaskAction = {
  url: '/program-cancellation/${projectPid}/submission/${entityId}',
  params: ['projectPid', 'entityId'],
};


export const CORRECTED_REPORT_ACTION: ITaskAction = {
  url: '/project-report/CORRECTED_REPORT/${accountId}/${projectPid}/${entityId}',
  params: ['accountId', 'projectPid', 'entityId'],
};

export const PROJECT_CANCELLATION_ACTION: ITaskAction = {
  url: '/program-cancellation/${projectPid}/review/${entityId}',
  params: ['projectPid', 'entityId'],
};

export const PROJECT_TRANSFER_ACTION: ITaskAction = {
  url: '/project-transfer-request/${projectPid}/REVIEW_PROJECT_TRANSFER_REQUEST',
  params: ['projectPid'],
};

export const INITIATE_PROJECT_TRANSFER_ACTION: ITaskAction = {
  url: 'REDIRECT_TO_PROJECT_TRANSFER',
  params: []
};

export const PROJECT_TRANSFER_ACTION_CUST: ITaskAction = {
  url: '/project-transfer-request/${projectPid}/REVIEW_PROJECT_TRANSFER_REQUEST_TRANSFEREE',
  params: ['projectPid'],
};

export const PROJECT_TRANSFER_ACTION_CUST1: ITaskAction = {
  url: '/project-transfer-request/${projectPid}/REVIEW_PROJECT_TRANSFER_REQUEST_CUST',
  params: ['projectPid'],
};

export const REGISTRATION_REPORT_REVIEW_VERIFICATION_DOCUMENT_ACTION: ITaskAction = {
  url: '/cfr-registration/cfr/DUPS/${accountId}',
  params: ['accountId'],
};

export const AGREEMENT_TO_TRANSFER_CREDIT_ACTION: ITaskAction = {
  url: '/submission-management/agreement-to-transfer-credit/${entityId}',
  params: ['entityId']
}

export const CREDIT_CREATION_REPORT_ACTION: ITaskAction = {
  url: '/credit-creation-reports/report/${entityId}',
  params: ['entityId']
}

export const CREDIT_ADJUSTMENT_REPORT_ACTION: ITaskAction = {
  url: '/credit-adjustment-reports/report/${entityId}',
  params: ['entityId']
}

export const CFR_CREDIT_TRANSFER_ACTION: ITaskAction = {
  url: '/unit-management/list/credit-transfer/${creditActionId}',
  params: ['creditActionId'],
}

export const REVIEW_REGISTRATION_REPORT_ACTION: ITaskAction = {
  url: '/cfr-registration/cfr/RRPS/${accountId}/${entityId}',
  params: ['accountId', 'entityId'],
};
export const REVIEW_REGISTRATION_ACCOUNT_FOR_NEED_MORE_INFO_ACTION: ITaskAction = {
  url: '/cfr-registration/cfr/RRVB/${accountId}/${entityId}',
  params: ['accountId', 'entityId'],
};

export const NOS_ACTION: ITaskAction = {
  url: '/notice-of-suspension/${entityId}',
  params: ['entityId'],
};

export const USER_REVIEW_AO_REPLACEMENT_ACTION = {
  url: '/account-management/approve-account/${accountId}/${userId}',
  params: ['accountId', 'userId'],
}

export const SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL_ACTION = {
  url: '/submission-management/compliance-report/insufficient-credits/${entityId}',
  params: ['entityId'],
}

export const SUBMIT_COMPLIANCE_CREDIT_INSUFFICIENT_CREDIT_TO_CANCEL_ACTION = {
  url: '/unit-management/list/notice-of-insufficient-credits/${entityId}',
  params: ['entityId'],
}

export const CREDIT_BALANCE_REPORT_ACTION = {
  url: '/credit-balance/report/${entityId}',
  params: ['entityId']
}

export const CCM_ACTION = {
  url: '/credit-clearance/mechanism/details/${entityId}',
  params: ['entityId']
}

export const CCM_PURCHASE_CREDITS_ACTION = {
  url: '/credit-clearance/mechanism/list',
  params: []
}

export const SETUP_CCM_ACTION = {
  url: 'SETUP_CCM',
  params: []
}

export const COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_ACTION = {
  url: '/complimentary-compliance-report/report/${entityId}',
  params: ['entityId']
}

export const COMPLIANCE_REVENUE_REPORT_PENDING_SUBMISSION_ACTION = {
  url: '/credit-revenue/report/${entityId}',
  params: ['entityId']
}

export const MATERIAL_BALANCE_REPORT_PENDING_SUBMISSION_ACTION = {
  url: '/material-balance/report/${entityId}',
  params: ['entityId']
}

export const TASKTYPE_ACTION: ITaskActionMap = {
  USER_REVIEW: {
    url: '/user/list/${entityId}',
    params: ['entityId'],
  },
  USER_REVIEW_AO_REPLACEMENT: USER_REVIEW_AO_REPLACEMENT_ACTION,

  CI_PATHWAY_REPORT_PENDING_SUBMISSION: CI_PATHWAY_REPORT_ACTION,
  REVIEW_PROPOSED_CI_PATHWAY_REPORT: CI_PATHWAY_REPORT_ACTION,
  // CI_PATHWAY_REPORT_NEED_MORE_INFO: CI_PATHWAY_REPORT_ACTION,

  START_VERIFICATION: VERIFICATION_ACTION,
  ISSUE_VFB_STATEMENT: VB_REPORT_ACTION,
  REVIEW_VFB_STATEMENT: VB_REPORT_ACTION,
  VB_REQUEST_CLARIFICATION: VB_REPORT_ACTION,
  REVIEW_COI_CHECKLIST: CHECKLIST_ACTION,
  REVIEW_COI_CHECKLIST_ADMIN: CHECKLIST_ACTION,
  REVIEW_COI_CHECKLIST_ADMIN2: CHECKLIST_ACTION,
  REVIEW_COI_CHECKLIST_PVB: CHECKLIST_ACTION,
  START_REVIEW_COI_CHECKLIST: CHECKLIST_ACTION,

  AGREEMENT_PENDING_OPA_APPROVAL: AGREEMENT_ACTION,
  AGREEMENT_PENDING_RCA_APPROVAL: AGREEMENT_ACTION,
  AGREEMENT_PENDING_ADMIN_REVIEW: AGREEMENT_UPLOAD_ACTION,
  AGREEMENT_PENDING_UPLOAD: AGREEMENT_UPLOAD_ACTION,
  AGREEMENT_SAVED: AGREEMENT_ACTION,

  SUBMIT_APPLICATION: APPLICATION_ACTION,
  START_APPLICATION_VFB_PROCESS: APPLICATION_ACTION,
  ASSIGN_VFB: APPLICATION_ACTION,
  APPLICATION_PENDING_SUBMISSION: APPLICATION_ACTION,
  REVIEW_APPLICATION_PROPOSAL: APPLICATION_ACTION,
  REVIEW_APPLICATION_SUBMISSION: APPLICATION_ACTION,
  APPLICATION_NEED_MORE_INFO: APPLICATION_ACTION,
  APPLICATION_UNDER_REVIEW: APPLICATION_ACTION,
  APPLICATION_UNDER_REVIEW_2: APPLICATION_ACTION,
  APPLICATION_CANCELLATION_SUBMITTED: APPLICATION_CANCEL,
  APPLICATION_RELEASE_SUBMITTED: APPLICATION_RELEASE,
  APPLICATION_CI_CANCELLATION_SUBMITTED : {
    url: '/carbon-intensity/cancel-ci/${entityId}',
    params: ['entityId'],
  },
  APPLICATION_ERP_CANCELLATION_SUBMITTED : {
    url: '/emissions-reduction/cancel-erp/${entityId}',
    params: ['entityId'],
  },
  APPLICATION_ASSIGNED_VFB:  {
    url: '/submission-management/assignment/${vbIdentifier}/${entityId}',
    params: ['vbIdentifier', 'entityId'],
  },
  SELECT_TL_IR: {
    url: '/submission-management/team-assignment/VFB/${entityId}',
    params: ['entityId'],
  },
  SELECT_VFB_TEAM: {
    url: '/submission-management/team-selection/VFB/${entityId}',
    params: ['entityId'],
  },
  SUBMIT_COI_CHECKLIST: {
    url: '/submission-management/coi-checklist/new/${entityId}',
    params: ['entityId'],
  },
  // Verification
  CO2E_EMISSION_REDUCTIONAPPLICATION_ASSIGNED_VFB: APPLICATION_ACTION,
  VIEW_REGISTRATION_REPORT: REVIEW_REGISTRATION_REPORT_ACTION,
  REVIEW_REGISTRATION_REPORT: ACCOUNT_SUMMARY,
  REVIEW_REGISTRATION_REPORT_2: ACCOUNT_SUMMARY,
  REVIEW_REGISTRATION_REPORT_PROPOSED_UPDATE: REVIEW_REGISTRATION_REPORT_ACTION,
  REVIEW_REGISTRATION_ACCOUNT_FOR_NEED_MORE_INFO: REVIEW_REGISTRATION_ACCOUNT_FOR_NEED_MORE_INFO_ACTION,
  RENEW_ACCREDITATION:  ACCOUNT_SUMMARY,
  MAO_REPLACE_REQUEST_SUBMITTED:  {
    url: '/replace-mao/${entityId}',
    params: ['entityId'],
  },
  ACCOUNT_REVIEW: ACCOUNT_SUMMARY,
  ACCOUNT_REVIEW2: ACCOUNT_SUMMARY,
  ACCOUNT_REVIEW_CONSENT: ACCOUNT_SUMMARY,
  ACCOUNT_NEED_MORE_INFORMATION: {
    url: '/registration-pending',
    params: [],
  },
  OBPS_RESULT_REVIEW: {
    url: '/program-management/facility-obps/${obligationId}',
    params: ['obligationId'],
  },
  UPLOAD_OBPS_RESULT_REVIEW: {
    url: '/program-management/upload-obps-results/${entityId}',
    params: ['entityId'],
  },
  EEC_PAYMENT_REMITTANCE: {
    url: '/program-management/cra-payments/${entityId}',
    params: ['entityId'],
  },
  EEC_PAYMENT_REMITTANCE_DUAL: {
    url: '/program-management/cra-payments/${entityId}',
    params: ['entityId'],
  },
  SECTION_58_REPORT_SUBMISSION: {
    url: '/obligation-management/remittance/EEC/${obligationId}/${entityId}',
    params: ['obligationId', 'entityId'],
  },
  OBLIGATION_OUTSTANDING: {
    url: '/obligation-management/list',
    params: ['obligationId'],
  },
  REVIEW_PROPOSED_REMITTANCE: {
    url: '/obligation-management/remittance/RU/${obligationId}/${entityId}',
    params: ['obligationId', 'entityId'],
  },
  EEC_PAYMENT_MANUAL_REVIEW: {
    url: '/program-management/cra-payments/${entityId}',
    params: ['entityId'],
  },
  REFUND_REQUEST_REVIEW: {
    url: '/obligation-management/refunds-requested/${entityId}',
    params: ['entityId'],
  },
  REFUND_REQUEST_REVIEW_DUAL: {
    url: '/obligation-management/refunds-requested/${entityId}',
    params: ['entityId'],
  },
  OVERPAID_OBLIGATION: {
    url: '/obligation-management/list/${obligationId}',
    params: ['obligationId'],
  },
  SECTION_181_OBLIGATION_REVIEW_DUAL: {
    url: '/obligation-management/assign-obligation-section-181/${obligationId}',
    params: ['obligationId'],
  },
  SECTION_181_OBLIGATION_REVIEW: {
    url: '/obligation-management/assign-obligation-section-181/${obligationId}',
    params: ['obligationId'],
  },
  RECOGNIZED_UNIT_REMITTANCE: {
    url: '/obligation-management/list/ru-credit-remittance/${entityId}',
    params: ['entityId'],
  },
  RECOGNIZED_UNIT_REMITTANCE_DUAL: {
    url: '/obligation-management/list/ru-credit-remittance/${entityId}',
    params: ['entityId'],
  },
  RECOGNIZED_UNIT_ADJUSTMENT_DUAL: {
    url: '/obligation-management/list/ru-credit-remittance/${entityId}',
    params: ['entityId'],
  },
  RECOGNIZED_UNIT_ADJUSTMENT: {
    url: '/obligation-management/list/ru-credit-remittance/${entityId}',
    params: ['entityId'],
  },
  START_COI_NOTICE_REVIEW: {
    url: '/verification/manage-notice-of-coi/notice-of-coi/review/${entityId}',
    params: ['entityId'],
  },
  COI_NOTICE_REVIEW: {
    url: '/verification/manage-notice-of-coi/notice-of-coi/review/${entityId}',
    params: ['entityId'],
  },
  PENDING_VERIFICATION_REPORT_SUBMISSION: {
    url: '/verification/report-summary/report-submission/${entityId}',
    params: ['entityId'],
  },

  // Project
  PROJECT_ON_HOLD: REDIRECT_TO_PROJECT,
  PROJECT_UNDER_REVIEW: REDIRECT_TO_PROJECT,
  REVIEW_PROJECT_SUBMISSION: REDIRECT_TO_PROJECT,
  PROJECT_PENDING_SUBMISSION: REDIRECT_TO_PROJECT,
  PROJECT_COMMENT: REDIRECT_TO_PROJECT,
  PROJECT_REVIEW2: REDIRECT_TO_PROJECT,

  PROJECT_OFFSET_RECEIVER_PENDING_SUBMISSION: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_PENDING_REVIEW: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_PENDING_REVIEW_2: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_PENDING_UNDER_REVIEW: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_PENDING_UNDER_REVIEW_2: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_TRANSFER_APPROVED_FIRST: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_PENDING_REVIEW_ADMIN_CONSENT: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_REJECTED_REVIEW_2: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_REJECTED_CONSENT_REVIEW_2: REDIRECT_TO_PROJECT,
  PROJECT_OFFSET_RECEIVER_ON_HOLD: REDIRECT_TO_PROJECT,

  //comments
  REVIEW_PROJECT_COMMENT_FROM_ADMIN_TEAM: REDIRECT_TO_PROJECT,
  REVIEW_RESPONSE_ON_COMMENT_FROM_PARTICIPANT:REDIRECT_TO_PROJECT,
  REVIEW_COMMENT_FROM_PARTICIPANT:REDIRECT_TO_PROJECT,
  REVIEW_RESPONSE_ON_COMMENT_FROM_ADMIN_TEAM:REDIRECT_TO_PROJECT,

  // Project cancellation
  PROJECT_CANCELLATION_REVIEW: PROJECT_CANCELLATION_ACTION,
  PROJECT_CANCELLATION_REVIEW2: PROJECT_CANCELLATION_ACTION,
  PROJECT_NEED_MORE_INFORMATION_ADDON_FIELDSET: PROJECT_CANCELLATION_ACTION,
  PROJECT_CANCELLATION_NEED_MORE_INFO: PROJECT_CANCELLATION_ACTION,

  // Project transfer
  REVIEW_PROJECT_TRANSFER_REQUEST: PROJECT_TRANSFER_ACTION,
  REVIEW2_PROJECT_TRANSFER_REQUEST: PROJECT_TRANSFER_ACTION,
  PENDING_ACCEPTANCE_PROJECT_TRANSFER_REQUEST: PROJECT_TRANSFER_ACTION_CUST,
  PROJECT_TRANSFER_REQUEST_VIEW_NEED_MORE_INFO: PROJECT_TRANSFER_ACTION_CUST1,

  // Monitoring Report
  SUBMISSIONS_ON_HOLD_MONITORING_REPORT: MONITORING_REPORT_ACTION,
  SUBMISSIONS_PENDING_SUBMISSION_MONITORING_REPORT: MONITORING_REPORT_ACTION,
  SUBMISSIONS_REVIEW2_MONITORING_REPORT: MONITORING_REPORT_ACTION,
  SUBMISSIONS_UNDER_REVIEW_MONITORING_REPORT: MONITORING_REPORT_ACTION,
  REVIEW_SUBMISSIONS_SUBMISSION_MONITORING_REPORT: MONITORING_REPORT_ACTION,

  // Project Report
  SUBMISSIONS_ON_HOLD_PROJECT_REPORT: PROJECT_REPORT_ACTION,
  SUBMISSIONS_PENDING_SUBMISSION_PROJECT_REPORT: PROJECT_REPORT_ACTION,
  SUBMISSIONS_REVIEW2_PROJECT_REPORT: PROJECT_REPORT_ACTION,
  SUBMISSIONS_UNDER_REVIEW_PROJECT_REPORT: PROJECT_REPORT_ACTION,
  REVIEW_SUBMISSIONS_SUBMISSION_PROJECT_REPORT: PROJECT_REPORT_ACTION,

  // Notification or error of ommissions
  REVIEW2_NOT_ERR_OMI_SUBMISSION: NOT_ERR_OMI_REPORT_ACTION,
  NOT_ERR_OMI_PENDING_SUBMISSION: NOT_ERR_OMI_REPORT_ACTION,
  START_REVIEW_NOT_ERR_OMI_SUBMISSION: NOT_ERR_OMI_REPORT_ACTION,
  ADDITIONAL_INFO_REQUESTED_NOT_ERR_OMI: NOT_ERR_OMI_REPORT_ACTION,
  REVIEW_NOT_ERR_OMI_SUBMISSION: NOT_ERR_OMI_REPORT_ACTION,
  RESPOND_TO_NOTICE: RESPOND_TO_NOTICE_ACTION,
  REVIEW_NOT_ERR_OMI_SUBMISSION_CUST: CORRECTED_REPORT_ACTION,
  SUBMIT_CORRECTED_REPORT: CORRECTED_REPORT_ACTION,
  REVIEW_SUBMISSIONS_SUBMISSION_CORRECTED_REPORT: CORRECTED_REPORT_ACTION,
  SUBMISSIONS_UNDER_REVIEW_CORRECTED_REPORT: CORRECTED_REPORT_ACTION,
  SUBMISSIONS_ON_HOLD_CORRECTED_REPORT: CORRECTED_REPORT_ACTION,
  SUBMISSIONS_PENDING_SUBMISSION_CORRECTED_REPORT: CORRECTED_REPORT_ACTION,
  REVIEW_CREDIT_SUSPENSION: CORRECTED_REPORT_ACTION,
  SUBMISSIONS_REVIEW2_CORRECTED_REPORT: CORRECTED_REPORT_ACTION,

  //Notification of change in GHG Reversal
  SUBMISSIONS_PENDING_NOT_GHG_REVERSAL : NOT_GHG_REVERSAL_REPORT_ACTION,
  REVIEW_NOT_GHG_REVERSAL_SUBMISSION: NOT_GHG_REVERSAL_REPORT_ACTION,
  SUBMIT_GHG_REVERSAL_REPORT: GHG_REVERSAL_REPORT_CREATION_ACTION,
  REVIEW_NOT_GHG_REVERSAL_SUBMISSION_CUST: NOT_GHG_REVERSAL_REPORT_ACTION,
  ADDITIONAL_INFO_REQUESTED_NOT_GHG_REVERSAL: NOT_GHG_REVERSAL_REPORT_ACTION,
  START_REVIEW_NOT_GHG_REVERSAL_SUBMISSION: NOT_GHG_REVERSAL_REPORT_ACTION, //NOT_GHG_SUSPECTED_REVERSAL_REPORT_ACTION,
  REVIEW_2_NOT_GHG_REVERSAL_SUBMISSION: NOT_GHG_REVERSAL_REPORT_ACTION, //NOT_GHG_SUSPECTED_REVERSAL_REPORT_ACTION,
  REVIEW_2_NOT_SUSPECTED_GHG_REVERSAL_SUBMISSION: NOT_GHG_SUSPECTED_REVERSAL_REPORT_ACTION,
  REVIEW_SUSPECTED_NOT_GHG_REVERSAL: NOT_GHG_SUSPECTED_REVERSAL_REPORT_ACTION,
  REVIEW_SUBMISSIONS_GHG_REVERSAL_REPORT: GHG_REVERSAL_REPORT_ACTION,
  SUBMISSIONS_UNDER_REVIEW_GHG_REVERSAL_REPORT: GHG_REVERSAL_REPORT_ACTION,
  SUBMISSIONS_ADDITIONAL_INFO_REQUESTED_GHG_REVERSAL_REPORT: GHG_REVERSAL_REPORT_ACTION,
  REVIEW_SUBMISSIONS_SUBMISSION_GHG_REVERSAL_REPORT: GHG_REVERSAL_REPORT_ACTION,
  SUBMISSIONS_REVIEW2_GHG_REVERSAL_REPORT: GHG_REVERSAL_REPORT_ACTION,
  SUBMISSIONS_PENDING_GHG_REVERSAL_REPORT: GHG_REVERSAL_REPORT_ACTION,

  // Notification of change in Project Start Date
  REVIEW2_NOT_START_DATE_SUBMISSION: NOT_START_DATE_REPORT_ACTION,
  NOT_START_DATE_PENDING_SUBMISSION: NOT_START_DATE_REPORT_ACTION,
  START_REVIEW_NOT_START_DATE_SUBMISSION: NOT_START_DATE_REPORT_ACTION,
  ADDITIONAL_INFO_REQUESTED_NOT_START_DATE: NOT_START_DATE_REPORT_ACTION,
  REVIEW_NOT_START_DATE_SUBMISSION: NOT_START_DATE_REPORT_ACTION,

  // Notification of change in informationa
  INITIATE_PROJECT_CANCELLATION_NOT_CARRYOUT_PROJ_ACT: CANCEL_PROJECT_ACTION,
  REVIEW_SUBMISSIONS_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_REPORT_ACTION,
  SUBMISSIONS_UNDER_REVIEW_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_REPORT_ACTION,
  SUBMISSIONS_PENDING_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_REPORT_ACTION,
  SUBMISSIONS_ON_HOLD_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_REPORT_ACTION,
  SUBMISSIONS_REVIEW2_NOT_CARRYOUT_PROJ_ACT: NOT_CARRYOUT_PROJ_ACT_REPORT_ACTION,

  //  Notification of change of Proponant Name and Civic Address
  INITIATE_PROJECT_TRANSFER_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: INITIATE_PROJECT_TRANSFER_ACTION,
  REVIEW_SUBMISSIONS_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS_ACTION,
  SUBMISSIONS_UNDER_REVIEW_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS_ACTION,
  SUBMISSIONS_PENDING_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS_ACTION,
  SUBMISSIONS_ON_HOLD_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS_ACTION,
  SUBMISSIONS_REVIEW2_NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS: NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS_ACTION,

  //  Notification of change of landownership
  REVIEW_SUBMISSIONS_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_ACTION,
  SUBMISSIONS_UNDER_REVIEW_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_ACTION,
  SUBMISSIONS_PENDING_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_ACTION,
  SUBMISSIONS_ON_HOLD_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_ACTION,
  SUBMISSIONS_REVIEW2_NOT_CHANGE_LAND_OWNERSHIP: NOT_CHANGE_LAND_OWNERSHIP_ACTION,

  //REGISTRATION_REPORT_REVIEW_VERIFICATION_DOCUMENT
  REGISTRATION_REPORT_REVIEW_VERIFICATION_DOCUMENT: REGISTRATION_REPORT_REVIEW_VERIFICATION_DOCUMENT_ACTION,
  REGISTRATION_REPORT_DOC_UPLOAD_NEEDED: REGISTRATION_REPORT_REVIEW_VERIFICATION_DOCUMENT_ACTION,

  // AGREEMENT_TO_TRANSFER_CREDIT
  REVIEW_SIGN_AGREEMENT_TUC_TRANSFEREE: AGREEMENT_TO_TRANSFER_CREDIT_ACTION,
  AGREEMENT_TO_TRANSFER_CREDIT_UPON_CREATION_PENDING_SUBMISSION: AGREEMENT_TO_TRANSFER_CREDIT_ACTION,
  REVIEW_SIGN_AGREEMENT_TUC_TRANSFEROR: AGREEMENT_TO_TRANSFER_CREDIT_ACTION,
  REVIEW_INCOMING_TRANSFER: CFR_CREDIT_TRANSFER_ACTION,

  // CREDIT_CREATION_REPORT
  CREDIT_CREATION_REPORT_PENDING_SUBMISSION: CREDIT_CREATION_REPORT_ACTION,
  CREDIT_CREATION_REPORT_SUBMIT_PROPOSED_SUBMISSION: CREDIT_CREATION_REPORT_ACTION,
  SUBMIT_QUARTERLY_CREDIT_CREATION_REPORT: CREDIT_CREATION_REPORT_ACTION,
  REVIEW_CREDIT_CREATION_REPORT: CREDIT_CREATION_REPORT_ACTION,
  CREDIT_CREATION_REPORT_NEED_MORE_INFO: CREDIT_CREATION_REPORT_ACTION,
  CREDIT_CREATION_REPORT_PENDING_RESUBMISSION: CREDIT_CREATION_REPORT_ACTION,

  // CREDIT_ADJUSTMENT_REPORT_ACTION
  CREDIT_ADJUSTMENT_REPORT_PENDING_SUBMISSION: CREDIT_ADJUSTMENT_REPORT_ACTION,
  CREDIT_ADJUSTMENT_REPORT_SUBMIT_PROPOSED_SUBMISSION: CREDIT_ADJUSTMENT_REPORT_ACTION,
  CREDIT_ADJUSTMENT_REPORT_PENDING_RESUBMISSION: CREDIT_ADJUSTMENT_REPORT_ACTION,

  CREDIT_SUSPEND: RESPOND_TO_NOTICE_ACTION_SUSPENSION,
  TRANSFER: CFR_CREDIT_TRANSFER_ACTION,
  TRANSFER_DUAL: CFR_CREDIT_TRANSFER_ACTION,
  CANCELLATION: {
    url: '/unit-management/list/credit-cancellation/${creditActionId}',
    params: ['creditActionId'],
  },
  CREDIT_ISSUANCE: CREDIT_ISSUANCE,
  REVIEW_PROPOSED_TRANSFER: CFR_CREDIT_TRANSFER_ACTION,

  // Notice of Suspension
  NOTICE_OF_SUSPENSION_PENDING_REVIEW_CANCELLATION: NOS_ACTION,
  SEND_NOTICE_OF_SUSPENSION_INSUFFICIENT_CREDIT_TO_CANCEL: NOS_ACTION,
  REVIEW_NOTICE_OF_SUSPENSION_INSUFFICIENT_CREDIT_TO_CANCEL: NOS_ACTION,

  // Compliance Report
  SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL: SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL_ACTION,
  CANCEL_COMPLIANCE_REPORT_EXCESS_COMPLIANCE_CREDIT: SUBMIT_COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_TO_CANCEL_ACTION,

  // Insuficient Credit Notice
  SUBMIT_COMPLIANCE_CREDIT_INSUFFICIENT_CREDIT_TO_CANCEL: SUBMIT_COMPLIANCE_CREDIT_INSUFFICIENT_CREDIT_TO_CANCEL_ACTION,
  CANCEL_COMPLIANCE_CREDIT_EXCESS_CREDIT: SUBMIT_COMPLIANCE_CREDIT_INSUFFICIENT_CREDIT_TO_CANCEL_ACTION,

  // Credit Balance report
  CREDIT_BALANCE_REPORT_PENDING_SUBMISSION: CREDIT_BALANCE_REPORT_ACTION,
  CREDIT_BALANCE_REPORT_SUBMIT_PROPOSED_SUBMISSION: CREDIT_BALANCE_REPORT_ACTION,

  // CCM
  CREDIT_CLEARANCE_MECHANISM_SETUP: SETUP_CCM_ACTION,
  CREDIT_CLEARANCE_MECHANISM_PENDING_SUBMISSION: CCM_ACTION,
  CREDIT_CLEARANCE_MECHANISM_PURCHASE_CREDITS: CCM_PURCHASE_CREDITS_ACTION,

  // Complimentary Compliance Report
  COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION: COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_ACTION,
  COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_RESUBMISSION: COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_ACTION,
  REVIEW_PROPOSED_COMPLIMENTRY_COMPLIANCE_REPORT: COMPLIMENTRY_COMPLIANCE_REPORT_PENDING_SUBMISSION_ACTION,

//revenue report
  COMPLIANCE_CREDIT_REVENUE_REPORT_PENDING_SUBMISSION: COMPLIANCE_REVENUE_REPORT_PENDING_SUBMISSION_ACTION,
  COMPLIANCE_CREDIT_REVENUE_REPORT_PENDING_RESUBMISSION: COMPLIANCE_REVENUE_REPORT_PENDING_SUBMISSION_ACTION,
  REVIEW_PROPOSED_COMPLIANCE_CREDIT_REVENUE_REPORT: COMPLIANCE_REVENUE_REPORT_PENDING_SUBMISSION_ACTION,

  // Material Balance Report
  MATERIAL_BALANCE_REPORT_PENDING_SUBMISSION:  MATERIAL_BALANCE_REPORT_PENDING_SUBMISSION_ACTION,
  MATERIAL_BALANCE_REPORT_SUBMIT_VERIFICATION_BODY:  MATERIAL_BALANCE_REPORT_PENDING_SUBMISSION_ACTION,
};

export const taskNotesConfig: ITableViewConfig = {
  translationPrefix: 'COMMON.custom_messages',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  editService: '/registry-server/todo/notes',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false,
    }, {
      field: 'notes',
      header: 'notes',
      sortField: 'respond',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12',
    },

  ],
};

export const taskAssignToAdminConfig: ITableViewConfig = {
  translationPrefix: 'COMMON.custom_messages',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  editService: '/registry-server/todo/assignToUser',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false,
    },
    {
      field: 'assignedTo',
      header: 'asignTaskTo',
      width: '200px',
      editable: false,
      fieldType: 'dict-select',
      source: 'getAssignedTo',
      inputType: 'string',
      editRowField: true,
      className: 'col-12',
      required: true,
    },
  ],
};

export const ENTITIES_REVIEW_ACCESS_REQUEST: ITableViewConfig = {
  translationPrefix: 'VERIFICATION_MANAGEMENT_MODULE.reportList',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  columns: [
    {
      field: 'entityType',
      header: 'entityType',
      sortField: 'entityType',
      width: '200px',
      translate: true,
      fieldType: 'chips',
      translationPrefix: 'COMMON.submissionTypeList',
    },
    {
      field: 'compliancePeriod',
      header: 'compliancePeriod',
      sortField: 'compliancePeriod',
      width: '200px',
    },
    {
      field: 'applicationId',
      header: 'applicationId',
      sortField: 'applicationId',
      width: '200px',
    },
  ],
};

export const CCR_SUBMIT_REMINDER_TASKS = [
  'SUBMIT_ANNUALY_CREDIT_CREATION_REPORT',
  'SUBMIT_QUARTERLY_CREDIT_CREATION_REPORT',
  'SUBMIT_QUARTERLY_Q3Q4_CREDIT_CREATION_REPORT',
];

export const ADDITION_OF_PROJECT_TASKS = [
  'REVIEW_ADDITION_OF_PROJECT_SUBMISSION',
  'SUBMIT_ADDITION_OF_PROJECT_SUBMISSION',
  'APPROVE_ADDITION_OF_PROJECT_SUBMISSION',
  'NEED_MORE_INFO_ADDITION_OF_PROJECT_SUBMISSION',
];

export const NOTICE_OF_ERROR_TASKS = [
  'NOTICE_OF_ERROR_PENDING_SUBMISSION',
  'START_REVIEW_NOTICE_OF_ERROR',
  'NOTICE_OF_ERROR_DISCARDED',
  'REVIEW_NOTICE_OF_ERROR',
  'SEND_NOTICE_OF_ERROR_INSUFFICIENT_CREDIT_TO_CANCEL',
  'REVIEW_NOTICE_OF_ERROR_INSUFFICIENT_CREDIT_TO_CANCEL',
  'NOTICE_OF_ERROR_PENDING_REVIEW_CANCELLATION',
  'CANCEL_EXCESS_COMPLIANCE_CREDIT',
  'ADDITIONAL_INFO_REQUESTED_FOR_NOTICE_OF_ERROR',
  'RESPONSE_RECIEVED_FROM_PARTICIPANT_FOR_NOTICE_OF_ERROR',
];

export const COMPLIANCE_REPORT_TASKS = [
  'COMPLIANCE_REPORT_PENDING_SUBMISSION',
  'COMPLIANCE_REPORT_PENDING_RESUBMISSION',
  'COMPLIANCE_REPORT_SUBMIT_PROPOSED_SUBMISSION'
];

export const COMPLIMENTARY_COM_REP_STATUS = {
  REFERRED_TO_ENFORCEMENT: 'REFERRED_TO_ENFORCEMENT',
  RESOLVED: 'RESOLVED',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO_REQUESTED'
};

export const COMPLIMENTARY_COM_REP_ACTIONS = {
  REFERRED_TO_ENFORCEMENT: 'REFERRED_TO_ENFORCEMENT',
  RESOLVED: 'RESOLVED',
  ADDITIONAL_INFO: 'NEED_MORE_INFO',
  SUBMIT_RESPONSE: 'SUBMIT_RESPONSE'
};

export const COMP_REV_REP_ACTIONS = {
  SUBMIT_RESPONSE: 'SUBMIT_RESPONSE',
  RESOLVED: 'RESOLVED',
  NEED_MORE_INFO: 'NEED_MORE_INFO',
  CREDIT_SUSPENDED: 'CREDIT_SUSPENDED'
};
